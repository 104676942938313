import { Edit, Loader2 } from "lucide-react";
import { NavLink } from "react-router-dom";
import { toast } from "sonner";

import { DocumentCollection } from "@/api/types";
import { InlineTextForm } from "@/components/forms/inline-text-form";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { getReportTitle } from "@/utils/report";

type Props = {
    report: DocumentCollection;
    onTitleChange?: (title: string) => Promise<void>;
};

export const ReportHeader = ({ report, onTitleChange }: Props) => {
    const [isEditing, { open: edit, close: cancel }] = useBoolean();
    const updateTitleAction = useAsyncState(
        async (title: string) => {
            cancel();
            await onTitleChange?.(title);
        },
        {
            onError: (e) =>
                toast.error("Error updating the title: " + e.message),
        },
    );

    if (isEditing) {
        return (
            <InlineTextForm
                text={report.title ?? ""}
                placeholder="Report name..."
                action={updateTitleAction}
                onCancel={cancel}
                className="text-background ring-offset-foreground focus-visible:ring-background dark:text-foreground h-9 w-full border-none bg-transparent"
            />
        );
    }
    return (
        <Breadcrumb className="grow text-sm">
            <BreadcrumbList className="flex-nowrap justify-center">
                <BreadcrumbItem className="shrink-0">
                    <BreadcrumbLink
                        className="hover:text-background dark:hover:text-foreground underline-offset-4 hover:underline"
                        asChild
                    >
                        <NavLink to="/">Home</NavLink>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="text-background flex items-center font-semibold dark:text-neutral-400">
                    <span className="line-clamp-1">
                        {getReportTitle(report)}
                    </span>
                    <Button
                        size="icon-sm"
                        variant="link"
                        className="text-muted-foreground hover:text-background dark:hover:text-foreground"
                        onClick={edit}
                        disabled={updateTitleAction.isSubmitting}
                    >
                        {updateTitleAction.isSubmitting ? (
                            <Loader2 className="size-4 animate-spin" />
                        ) : (
                            <Edit className="size-4" />
                        )}
                    </Button>
                </BreadcrumbItem>
            </BreadcrumbList>
        </Breadcrumb>
    );
};
