import { BookOpenText } from "lucide-react";

import { Button } from "@/components/ui/button";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useAnalyze } from "@/hooks/use-analyze";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";

export const ReportTrigger = () => {
    const enabled = useFeatureFlagBool(
        FeatureFlagBool.report_v0_enabled,
        false,
    );
    const openReport = useAnalyze((s) => s.openReport);

    return enabled ? (
        <Button variant="secondary" className="gap-2" onClick={openReport}>
            <BookOpenText className="mr-2 size-4" />
            Report
        </Button>
    ) : null;
};
