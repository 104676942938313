import { HTMLAttributes, PropsWithChildren } from "react";

import {
    Tooltip,
    TooltipContent,
    TooltipPortal,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { useClipboard } from "@/hooks/use-clipboard";
import { cn } from "@/lib/utils";

interface Props extends HTMLAttributes<HTMLButtonElement> {
    value: string;
    asChild?: boolean;
}

export const CopyToClipboard = ({
    value,
    asChild = false,
    children,
    className,
    ...props
}: PropsWithChildren<Props>) => {
    const clipboard = useClipboard({ timeout: 1000 });
    return (
        <TooltipProvider>
            <Tooltip open={clipboard.copied}>
                <TooltipTrigger
                    {...props}
                    className={cn(
                        !asChild && "peer underline-offset-4 hover:underline",
                        className,
                    )}
                    onClick={() => clipboard.copy(value)}
                    asChild={asChild && children != undefined}
                >
                    {children ?? value}
                </TooltipTrigger>
                <TooltipPortal>
                    <TooltipContent className="bg-foreground text-background border-none text-xs shadow-none">
                        Copied!
                    </TooltipContent>
                </TooltipPortal>
            </Tooltip>
        </TooltipProvider>
    );
};
