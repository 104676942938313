import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { AsyncButton } from "@/components/async-button";
import { PageTitle } from "@/components/page-title";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";

const schema = z.object({
    title: z.string().min(3).max(100),
});

type FormSchema = z.infer<typeof schema>;

export const V3NewProjectRoute = () => {
    const api = useApi();
    const navigate = useNavigate();
    const form = useForm<FormSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            title: "",
        },
    });

    const action = useAsyncState(
        async (data: FormSchema) => await api.create_report(data.title, []),
        { onSuccess: ({ id }) => navigate(`/v3/project/${id}`) },
    );

    return (
        <div className="container space-y-4 py-10">
            <PageTitle level={3} highlighted />
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(action.submit)}
                    className="space-y-10"
                >
                    <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Title</FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder="Report Title"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <AsyncButton
                        variant="primary"
                        loading={action.isSubmitting}
                    >
                        Create Project
                    </AsyncButton>
                </form>
            </Form>
        </div>
    );
};
