import {
    ArrowUpRight,
    EllipsisVertical,
    FileText,
    Globe,
    RefreshCw,
    Sheet,
} from "lucide-react";
import { HTMLAttributes, SyntheticEvent } from "react";

import { DocumentType, DocumentInfo as TDocumentInfo } from "@/api/types";
import { CompanyLogo } from "@/components/company-logo";
import { DocumentLink } from "@/components/document/document-link";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAsyncState } from "@/hooks/use-async-state";
import { useDevMode } from "@/hooks/use-dev-mode";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";
import { urlHostName } from "@/utils/string-helpers";
import { formatDate } from "@/utils/time";

const DevOptions = ({ info }: { info: TDocumentInfo }) => {
    const [devMode] = useDevMode();
    const invalidateDocument = useGridView((s) => s.invalidateDocument);
    const refreshAction = useAsyncState(async (e: SyntheticEvent) => {
        e.stopPropagation();
        return await invalidateDocument(info.id);
    });
    if (!devMode) return null;
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon-sm" className="shrink-0">
                    <EllipsisVertical className="size-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent collisionPadding={5}>
                <DropdownMenuItem
                    className="gap-2"
                    onClick={refreshAction.submit}
                    disabled={refreshAction.isSubmitting}
                >
                    <RefreshCw className="size-4" />
                    Refresh Row
                </DropdownMenuItem>
                {"url" in info && (
                    <DropdownMenuItem
                        className="gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(info.url);
                        }}
                    >
                        <ArrowUpRight className="size-4" />
                        Filing Source
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

interface Props extends HTMLAttributes<HTMLDivElement> {
    info: TDocumentInfo;
}

export const DocumentInfo = ({ info, className, ...props }: Props) => {
    switch (info.doc_type) {
        case DocumentType.website:
            return (
                <div
                    className={cn("flex items-center gap-2.5", className)}
                    {...props}
                >
                    <div className="bg-muted flex size-9 shrink-0 items-center justify-center rounded p-1.5">
                        <Globe className="text-muted-foreground size-4" />
                    </div>
                    <div>
                        <DocumentLink
                            documentID={info.id}
                            className="flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                            role="button"
                        >
                            <span
                                className={cn(
                                    info.external_ref
                                        ? "line-clamp-1"
                                        : "line-clamp-2",
                                )}
                            >
                                {info.title}
                            </span>
                            <ArrowUpRight className="size-4 shrink-0 opacity-0 transition-all group-hover/row:opacity-100" />
                        </DocumentLink>
                        {info.external_ref && (
                            <p className="text-muted-foreground text-xs">
                                {urlHostName(info.external_ref)}
                            </p>
                        )}
                    </div>
                    <DevOptions info={info} />
                </div>
            );
        case DocumentType.pdf:
        case DocumentType.text:
            return (
                <div
                    className={cn("flex items-center gap-2.5", className)}
                    {...props}
                >
                    <div className="bg-muted flex size-9 shrink-0 items-center justify-center rounded p-1.5">
                        <FileText className="text-muted-foreground size-4" />
                    </div>
                    <DocumentLink
                        documentID={info.id}
                        className="flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                        role="button"
                    >
                        <span className="line-clamp-2">{info.title}</span>
                        <ArrowUpRight className="size-4 shrink-0 opacity-0 transition-all group-hover/row:opacity-100" />
                    </DocumentLink>
                    <DevOptions info={info} />
                </div>
            );
        case DocumentType.spreadsheet:
            return (
                <div
                    className={cn("flex items-center gap-2.5", className)}
                    {...props}
                >
                    <div className="bg-muted flex size-9 items-center justify-center rounded p-1.5">
                        <Sheet className="text-muted-foreground size-4" />
                    </div>
                    <DocumentLink
                        documentID={info.id}
                        className="flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                        role="button"
                    >
                        <span className="line-clamp-2">{info.title}</span>
                        <ArrowUpRight className="size-4 shrink-0 opacity-0 transition-all group-hover/row:opacity-100" />
                    </DocumentLink>
                    <DevOptions info={info} />
                </div>
            );
        case DocumentType.sec_filing:
            return (
                <div
                    className={cn("flex items-center gap-2.5", className)}
                    {...props}
                >
                    <CompanyLogo
                        ticker={info.ticker}
                        size="xl"
                        className="bg-muted shrink-0"
                    />
                    <div className="grow space-y-1">
                        <DocumentLink
                            documentID={info.id}
                            className="line-clamp-1 flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                            role="button"
                        >
                            <span>{info.form_type}</span>
                            <ArrowUpRight className="size-4 shrink-0 opacity-0 transition-all group-hover/row:opacity-100" />
                        </DocumentLink>
                        <p className="text-muted-foreground flex justify-between text-xs">
                            <span>{info.ticker}</span>
                            <span>{formatDate(info.filed_at)}</span>
                        </p>
                    </div>
                    <DevOptions info={info} />
                </div>
            );
        case DocumentType.earnings_transcript:
            return (
                <div
                    className={cn("flex items-center gap-2.5", className)}
                    {...props}
                >
                    <CompanyLogo
                        ticker={info.ticker}
                        size="xl"
                        className="bg-muted shrink-0"
                    />
                    <div className="grow">
                        <DocumentLink
                            documentID={info.id}
                            className="line-clamp-1 flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                            role="button"
                        >
                            <span>
                                {info.year} Q{info.quarter} Earnings Call
                            </span>
                            <ArrowUpRight className="size-4 shrink-0 opacity-0 transition-all group-hover/row:opacity-100" />
                        </DocumentLink>
                        <p className="text-muted-foreground flex justify-between text-xs">
                            <span>{info.ticker}</span>
                            <span>{formatDate(info.filed_at)}</span>
                        </p>
                    </div>
                    <DevOptions info={info} />
                </div>
            );
        case DocumentType.quartr_transcript:
            return (
                <div
                    className={cn("flex items-center gap-2.5", className)}
                    {...props}
                >
                    <CompanyLogo
                        ticker={info.ticker}
                        size="xl"
                        className="bg-muted shrink-0"
                    />
                    <div className="grow">
                        <DocumentLink
                            documentID={info.id}
                            className="line-clamp-1 flex items-center gap-1 font-medium hover:underline hover:underline-offset-2"
                            role="button"
                        >
                            <span>{info.title}</span>
                            <ArrowUpRight className="size-4 shrink-0 opacity-0 transition-all group-hover/row:opacity-100" />
                        </DocumentLink>
                        <p className="text-muted-foreground flex justify-between text-xs">
                            <span>{info.ticker}</span>
                            <span>{formatDate(info.filed_at)}</span>
                        </p>
                    </div>
                    <DevOptions info={info} />
                </div>
            );
        default:
            return info satisfies never;
    }
};
