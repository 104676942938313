import { Quote } from "lucide-react";
import { MouseEvent, PropsWithChildren, useEffect, useRef } from "react";
import { ExtraProps } from "react-markdown";

import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { CitationNumber } from "@/components/citation-number";
import { DebugID } from "@/components/debug/debug-id";
import {
    canUseCitationContext,
    useCitationContext,
} from "@/hooks/use-citation-context";
import { useDevMode } from "@/hooks/use-dev-mode";
import { cn } from "@/lib/utils";
import { CitationData, CitationType } from "@/stores/citations";
import { first } from "@/utils/collection";
import { findParentWithOverflowHidden } from "@/utils/dom";
import { fromDOMRect, translateY } from "@/utils/geometry";

type Props = ExtraProps & CitationData & { id: string };

const extractCitationData = (data: Props): CitationData => {
    switch (data.type) {
        case CitationType.citation:
            return { type: data.type, citation_id: data.citation_id };
        case CitationType.finding:
            return { type: data.type, finding_id: data.finding_id };
        case CitationType.invalid:
            return { type: data.type };
        default:
            return data satisfies never;
    }
};

export const CitationDirective = (props: PropsWithChildren<Props>) => {
    const citationData = extractCitationData(props);
    const [devMode] = useDevMode();
    if (!canUseCitationContext()) {
        if (devMode) {
            return (
                <CopyToClipboard
                    value={JSON.stringify(
                        { error: "missing citation context", ...citationData },
                        null,
                        2,
                    )}
                    className="text-destructive underline underline-offset-2"
                >
                    {props.children}
                </CopyToClipboard>
            );
        }
        return props.children;
    }

    switch (citationData.type) {
        case CitationType.invalid:
            return props.children;
        case CitationType.citation:
        case CitationType.finding:
            return (
                <CitationDirectiveImpl id={props.id} data={citationData}>
                    {props.children}
                </CitationDirectiveImpl>
            );
        default:
            return citationData satisfies never;
    }
};

const CitationDirectiveImpl = ({
    id,
    children,
    data,
}: PropsWithChildren<{ id: string; data: CitationData }>) => {
    const ref = useRef<HTMLSpanElement>(null);
    const isSelected = useCitationContext((s) => s.citation?.unique_id === id);
    const setCitation = useCitationContext((s) => s.setCitation);
    const clearCitation = useCitationContext((s) => s.clearCitation);
    const setAnchor = useCitationContext((s) => s.setAnchor);
    const display_index = useCitationContext((s) => {
        switch (data.type) {
            case CitationType.citation:
                return s.citations.get(data.citation_id)?.display_index;
            case CitationType.invalid:
            case CitationType.finding:
                return null;
        }
    });

    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
        const entry = first(entries);
        if (entry && !entry.isIntersecting) {
            clearCitation();
        }
    };

    useEffect(() => {
        const el = ref.current;
        if (el && isSelected) {
            const updateAnchor = () => {
                const rect = fromDOMRect(el.getBoundingClientRect());
                setAnchor(translateY(rect, window.scrollY));
            };
            updateAnchor();
            const parent = findParentWithOverflowHidden(el);
            if (parent) {
                const intersectionObserver = new IntersectionObserver(
                    intersectionCallback,
                    {
                        root: parent,
                        rootMargin: "10%",
                    },
                );
                intersectionObserver.observe(el);

                const resizeObserver = new ResizeObserver(updateAnchor);
                resizeObserver.observe(parent);

                parent.addEventListener("scroll", updateAnchor);

                return () => {
                    parent.removeEventListener("scroll", updateAnchor);
                    resizeObserver.disconnect();
                    intersectionObserver.disconnect();
                };
            }
        }
    }, [isSelected, ref, setAnchor]);

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (isSelected) {
            clearCitation();
        } else {
            setCitation(id, data);
        }
    };

    const getDebugID = () => {
        switch (data.type) {
            case CitationType.citation:
                return (
                    <DebugID
                        label="Citation ID"
                        id={data.citation_id}
                        className="mx-1"
                    />
                );
            case CitationType.invalid:
                return null;
            case CitationType.finding:
                return (
                    <DebugID
                        label="Finding ID"
                        id={data.finding_id}
                        className="mx-1"
                    />
                );
        }
    };

    return (
        <span className="group">
            <span
                className={cn(
                    "to-brightwave dark:to-brightwave-800 from-transparent from-60% to-60% font-medium group-hover:bg-linear-to-b",
                    isSelected && "bg-linear-to-b",
                )}
            >
                {children}
            </span>
            <CitationNumber
                ref={ref}
                data-number={display_index}
                className={cn(
                    "bg-brightwave-300 hover:bg-brightwave dark:bg-brightwave-800 dark:text-background cursor-pointer text-sm select-none data-number:after:content-[attr(data-number)]",
                    isSelected && "bg-brightwave",
                )}
                onClick={handleClick}
            >
                {display_index === null && (
                    <Quote className="fill-foreground inline-block size-3 -translate-y-[2px] -scale-100 stroke-none" />
                )}
            </CitationNumber>
            {getDebugID()}
        </span>
    );
};
