import { ChevronDown, Files, FileText, Table2 } from "lucide-react";
import { PropsWithChildren } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Button } from "./ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

export const V3QuickNav = (props: PropsWithChildren) => {
    const { pathname } = useLocation();
    const base = pathname.substring(0, pathname.lastIndexOf("/"));
    return (
        <Popover>
            <PopoverTrigger className="flex items-center gap-2">
                {props.children}
                <ChevronDown className="size-4" />
            </PopoverTrigger>
            <PopoverContent
                align="start"
                className="flex flex-col items-stretch gap-1 p-1"
            >
                <Button
                    variant="ghost"
                    size="sm"
                    className="flex items-center justify-start gap-2"
                    asChild
                >
                    <NavLink to={`${base}/dataroom`}>
                        <Files className="size-4" />
                        Data Room
                    </NavLink>
                </Button>
                <Button
                    variant="ghost"
                    size="sm"
                    className="flex items-center justify-start gap-2"
                    asChild
                >
                    <NavLink to={`${base}/grid`}>
                        <Table2 className="size-4" />
                        Grid View
                    </NavLink>
                </Button>
                <Button
                    variant="ghost"
                    size="sm"
                    className="flex items-center justify-start gap-2"
                    asChild
                >
                    <NavLink to={`${base}/report`}>
                        <FileText className="size-4" />
                        Report
                    </NavLink>
                </Button>
            </PopoverContent>
        </Popover>
    );
};
