import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import { FindingGroupLaunchStatus, Probe } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { useUser } from "@/hooks/use-user";
import { cn } from "@/lib/utils";
import { getColumnId } from "@/utils/columns";
import { hasBetaAccess } from "@/utils/user";

export const SystemColumns = () => {
    const isBetaUser = hasBetaAccess(useUser());
    const availableCols = useGridView(
        useShallow(
            (s) =>
                new Map(
                    Array.from(s.availableColumns.values())
                        .filter(
                            (c) =>
                                isBetaUser ||
                                c.launch_status ===
                                    FindingGroupLaunchStatus.launched,
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((col) => [col.id, col]),
                ),
        ),
    );

    const addedColumnIds = useGridView(
        useShallow(
            (s) => new Set(Array.from(s.columns.values()).map(getColumnId)),
        ),
    );

    return (
        <div className="flex grow flex-col gap-1 overflow-y-scroll p-4">
            {Array.from(availableCols.values()).map((probe) => (
                <SystemColumn
                    key={probe.id}
                    probe={probe}
                    checked={addedColumnIds.has(probe.id)}
                />
            ))}
        </div>
    );
};

const SystemColumn = (props: { probe: Probe; checked: boolean }) => {
    const addColumn = useGridView((s) => s.addColumn);
    const deleteColumn = useGridView((s) => s.deleteColumn);

    const action = useAsyncState(
        async () => {
            if (props.checked) {
                await deleteColumn(props.probe.id);
                return false;
            } else {
                await addColumn(props.probe.id);
                return true;
            }
        },
        {
            onSuccess: (isChecked) =>
                toast.success(
                    `Analysis "${props.probe.name}" ${isChecked ? "added" : "removed"}`,
                ),
            onError: () => toast.error("Something went wrong."),
        },
    );

    return (
        <label
            className={cn(
                "group hover:bg-muted/50 flex items-center gap-4 rounded-lg border px-4 py-3 pr-2",
                action.isSubmitting && "opacity-50",
            )}
            onClick={action.getEventHandler()}
            role="button"
        >
            <Checkbox checked={props.checked} />
            <div className="flex grow items-center gap-2">
                <ProbeTypeIcon type={props.probe.type} className="shrink-0" />
                <p className="font-headline font-medium">{props.probe.name}</p>
            </div>
            <LaunchStatusBadge status={props.probe.launch_status} />
        </label>
    );
};

const LaunchStatusBadge = (props: { status: FindingGroupLaunchStatus }) => {
    switch (props.status) {
        case FindingGroupLaunchStatus.deprecated:
        case FindingGroupLaunchStatus.launched:
            return null;
        case FindingGroupLaunchStatus.beta:
            return (
                <Badge variant="beta" className="text-green-500">
                    BETA
                </Badge>
            );
        case FindingGroupLaunchStatus.internal:
            return (
                <Badge
                    variant="outline"
                    className="border-destructive text-destructive"
                >
                    INTERNAL
                </Badge>
            );
        default:
            return props.status satisfies never;
    }
};
