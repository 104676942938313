import { compareDesc, getYear } from "date-fns";
import { ArrowDown, Check, Plus } from "lucide-react";
import useSWRInfinite from "swr/infinite";

import { APIContextItem, Company, ContextType, FilingType } from "@/api/types";
import { ContextItemContent } from "@/components/analyze/context-item-content";
import { CompanyLogo } from "@/components/company-logo";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Skeleton } from "@/components/ui/skeleton";
import { useApi } from "@/hooks/use-api";
import { useSet } from "@/hooks/use-set";
import { cn } from "@/lib/utils";
import { firstX, last } from "@/utils/collection";
import { isUsable } from "@/utils/context-items";
import { asyncEmptyFunction, emptyFunction } from "@/utils/empty-function";
import { mapUpdate } from "@/utils/es6-map";
import { clamp } from "@/utils/math";
import { getCursorKeyFn } from "@/utils/pagination";
import { formatDate } from "@/utils/time";

enum DocumentFilterType {
    sec_s1,
    sec_8k,
    sec_10k,
    sec_10q,
    earnings_call,
    investor_meeting,
}

export const PAGE_SIZE = 100;

const ALL_FILTER_TYPES = [
    DocumentFilterType.investor_meeting,
    DocumentFilterType.earnings_call,
    DocumentFilterType.sec_10k,
    DocumentFilterType.sec_10q,
    DocumentFilterType.sec_8k,
];

const DEFAULT_FILTER_TYPES = [
    DocumentFilterType.investor_meeting,
    DocumentFilterType.earnings_call,
    DocumentFilterType.sec_10k,
    DocumentFilterType.sec_10q,
];

const getFilterLabel = (type: DocumentFilterType): string => {
    switch (type) {
        case DocumentFilterType.sec_8k:
            return "8K";
        case DocumentFilterType.sec_10k:
            return "10K";
        case DocumentFilterType.sec_10q:
            return "10Q";
        case DocumentFilterType.sec_s1:
            return "S1";
        case DocumentFilterType.earnings_call:
            return "Earnings Transcript";
        case DocumentFilterType.investor_meeting:
            return "Investor Meeting";
    }
};

const sortByYear = (a: APIContextItem, b: APIContextItem): number =>
    compareDesc(a.data.filed_at, b.data.filed_at);

const getFilterFn =
    (filter: Set<DocumentFilterType>) =>
    (item: APIContextItem): boolean => {
        switch (item.data.type) {
            case ContextType.EXTERNAL_SEC_FILING:
                switch (item.data.form_type) {
                    case FilingType.SEC_S1:
                        return filter.has(DocumentFilterType.sec_s1);
                    case FilingType.SEC_10K:
                        return filter.has(DocumentFilterType.sec_10k);
                    case FilingType.SEC_10Q:
                        return filter.has(DocumentFilterType.sec_10q);
                    case FilingType.SEC_8K:
                        return filter.has(DocumentFilterType.sec_8k);
                }
            case ContextType.EXTERNAL_EARNINGS_TRANSCRIPT:
                return filter.has(DocumentFilterType.earnings_call);
            case ContextType.QUARTR_TRANSCRIPT:
                return filter.has(DocumentFilterType.investor_meeting);
        }
    };

type Props = {
    companies: Company[];
    addedDocumentIDs?: Set<string>;
    addItems?: (item: APIContextItem[]) => Promise<void>;
    removeItem?: (item: APIContextItem) => void;
};

export const SearchResults = ({
    companies,
    addedDocumentIDs = new Set(),
    addItems = asyncEmptyFunction,
    removeItem = emptyFunction,
}: Props) => {
    const api = useApi();
    const [filter, filterActions] = useSet(DEFAULT_FILTER_TYPES);
    const key = `search_events:${companies
        .map((c) => c.symbol)
        .sort()
        .join(".")}`;
    const search = useSWRInfinite(
        getCursorKeyFn<APIContextItem>(PAGE_SIZE, key),
        async ({ pagination }) =>
            await api.fetch_multi_company_events(
                companies.map((c) => c.symbol),
                pagination,
            ),
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        },
    );

    const itemsToLoad =
        search.data !== undefined && search.data.length > 0
            ? clamp(
                  firstX(search.data).count -
                      (search.data ?? []).flatMap((p) => p.items).length,
                  0,
                  PAGE_SIZE,
              )
            : PAGE_SIZE;

    const grouped_items = (search.data ?? [])
        .flatMap((p) => p.items)
        .filter(getFilterFn(filter))
        .sort(sortByYear)
        .reduce(
            (acc, item) =>
                mapUpdate(acc, getYear(item.data.filed_at), (items) => [
                    ...(items ?? []),
                    item,
                ]),
            new Map<number, APIContextItem[]>(),
        );

    const getItemHandler = (item: APIContextItem) => () => {
        if (!isUsable(item)) return;
        if (!addedDocumentIDs.has(item.data.unique_id)) {
            addItems([item]);
        } else {
            removeItem(item);
        }
    };

    const getFilterHandler =
        (filter: DocumentFilterType) => (isChecked: boolean) =>
            filterActions.toggle(filter, isChecked);

    const allChecked =
        filter.size === 0
            ? false
            : ALL_FILTER_TYPES.every((t) => filter.has(t))
              ? true
              : "indeterminate";

    const allCheckedHandler = (isChecked: boolean) => {
        if (isChecked) {
            filterActions.addAll(ALL_FILTER_TYPES);
        } else {
            filterActions.clear();
        }
    };

    const getYearHandler = (year: number) => () => {
        const new_items = (grouped_items.get(year) ?? [])
            .filter(isUsable)
            .filter((item) => !addedDocumentIDs.has(item.data.unique_id));
        if (new_items.length) {
            addItems(new_items);
        }
    };

    return (
        <>
            <div className="flex min-h-10 items-center gap-4 border-b px-4 text-sm">
                <label className="flex items-center gap-2">
                    <Checkbox
                        checked={allChecked}
                        onCheckedChange={allCheckedHandler}
                    />
                    <span>All</span>
                </label>
                {ALL_FILTER_TYPES.map((type) => (
                    <label
                        key={type}
                        className="flex items-center gap-1.5 whitespace-nowrap"
                    >
                        <Checkbox
                            checked={filterActions.has(type)}
                            onCheckedChange={getFilterHandler(type)}
                        />
                        <span>{getFilterLabel(type)}</span>
                    </label>
                ))}
            </div>
            <InfiniteScroll
                className="bg-muted relative flex shrink-0 grow basis-7/12 flex-col gap-1.5 overflow-scroll p-4 pt-0"
                hasNextPage={
                    search.data && last(search.data)?.page_info.has_next_page
                }
                isLoading={search.isValidating || search.isLoading}
                onNextPage={() => search.setSize((s) => s + 1)}
                lookahead="20%"
                loader={Array.from(Array(itemsToLoad)).map((_, i) => (
                    <div
                        key={i}
                        className={cn(
                            "bg-background flex items-center gap-3 rounded border py-2 pr-2 pl-10",
                            i === 0 && "mt-10",
                        )}
                    >
                        <Skeleton className="size-9 rounded-md" />
                        <div className="grow space-y-1.5">
                            <Skeleton className="h-3 w-32" />
                            <Skeleton className="h-2.5 w-12" />
                        </div>
                        <Skeleton className="h-2.5 w-20" />
                    </div>
                ))}
            >
                {Array.from(grouped_items.entries()).map(([year, items], i) => [
                    <div
                        key={year}
                        className="bg-muted sticky top-0 flex items-center justify-between gap-4 py-2.5 pr-1.5 text-gray-500"
                        style={{
                            zIndex: 20 + i,
                            width: i === 0 ? "100%" : "50%",
                        }}
                    >
                        <Button
                            variant="outline"
                            className="font-headline gap-2 text-base font-bold"
                            onClick={getYearHandler(year)}
                        >
                            <Plus className="size-4" />
                            {year}
                        </Button>
                        <span className="grow" />
                        {i === 0 && (
                            <span className="flex items-center gap-1 text-xs">
                                Sorted by Filing Date{" "}
                                <ArrowDown className="size-3" />
                            </span>
                        )}
                    </div>,
                    ...items.map((item) => (
                        <label
                            key={item.data.unique_id}
                            className={cn(
                                "group bg-background flex items-center gap-3 rounded border px-3 py-1.5",
                                addedDocumentIDs.has(item.data.unique_id) &&
                                    "opacity-50",
                                !isUsable(item) && "cursor-not-allowed",
                            )}
                            role="button"
                            aria-disabled={!isUsable(item)}
                            onClick={getItemHandler(item)}
                        >
                            {isUsable(item) ? (
                                addedDocumentIDs.has(item.data.unique_id) ? (
                                    <Check className="size-4 shrink-0" />
                                ) : (
                                    <Plus className="size-4 shrink-0 opacity-0 transition-opacity group-hover:opacity-100" />
                                )
                            ) : (
                                <span className="size-4 shrink-0" />
                            )}
                            <CompanyLogo
                                ticker={item.data.ticker}
                                size="xl"
                                className="bg-muted"
                            />
                            <ContextItemContent item={item} className="grow" />
                            {!isUsable(item) && (
                                <span className="text-muted-foreground grow text-sm">
                                    Document type not supported
                                </span>
                            )}
                            <p className="text-muted-foreground text-xs whitespace-nowrap">
                                {formatDate(item.data.filed_at)}
                            </p>
                        </label>
                    )),
                ])}
                {!search.isLoading && grouped_items.size === 0 ? (
                    <p className="text-muted-foreground my-20 text-center">
                        No documents found
                    </p>
                ) : undefined}
            </InfiniteScroll>
        </>
    );
};
