import { create } from "zustand";

import { FindingGroupInfo, UUID } from "@/api/types";

export type SynthesisInfo = Omit<FindingGroupInfo, "document_id">;
type Synthesis = { info: SynthesisInfo; finding_ids: UUID[] };

export type State = {
    previousState: State | undefined;
    document_id: UUID | undefined;
    synthesis: Synthesis | undefined;
    report: true | undefined;
};

type Actions = {
    clear: () => void;
    close: () => void;

    openReport: () => void;
    openDocument: (document_id: UUID) => void;
    openSynthesis: (synthesis: Synthesis) => void;
    updateSynthesisInfo: (info: SynthesisInfo) => void;
    setState: (state: State) => void;
};

type Store = State & Actions;

const initialState: State = {
    previousState: undefined,
    document_id: undefined,
    synthesis: undefined,
    report: undefined,
};

export const useAnalyze = create<Store>((set) => ({
    ...initialState,

    openDocument(document_id) {
        set((s) => getUpdatedState(s, { document_id }));
    },

    clear() {
        set(initialState);
    },

    close() {
        set((s) => s.previousState ?? initialState);
    },

    openReport() {
        set((s) => getUpdatedState(s, { report: true }));
    },

    openSynthesis(synthesis, options?: { replace: boolean }) {
        set((s) => getUpdatedState(s, { synthesis }, options?.replace));
    },

    updateSynthesisInfo(info) {
        set((s) =>
            s.synthesis ? { synthesis: { ...s.synthesis, info } } : {},
        );
    },

    setState(state) {
        set(state);
    },
}));

export const getState = (s: Store): State => ({
    previousState: s.previousState,
    document_id: s.document_id,
    synthesis: s.synthesis,
    report: s.report,
});

const getUpdatedState = (
    store: Store,
    partial: Partial<State>,
    replace: boolean = false,
): Partial<State> => {
    if (replace) return partial;
    return { ...initialState, ...partial, previousState: getState(store) };
};
