import { Loader2, Quote, RefreshCw } from "lucide-react";
import useSWRImmutable from "swr/immutable";

import { SynthesisMetadata } from "@/api/rest";
import {
    DocumentFindingGroup,
    FindingGroupInfo,
    FindingGroupStatus,
} from "@/api/types";
import { IncomingWebsocketEventType } from "@/api/ws/websocket-types";
import { AsyncButton } from "@/components/async-button";
import { Citation } from "@/components/citation";
import { DebugID } from "@/components/debug/debug-id";
import { DetailedFinding } from "@/components/document-table/condensed/detailed-finding";
import { CitationContextProvider } from "@/context/citation-context-provider";
import { useAnalyze } from "@/hooks/use-analyze";
import { useApi } from "@/hooks/use-api";
import { UseAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { useWebsocket } from "@/hooks/use-websocket";
import { getMaybeValue } from "@/utils/async-value";
import { nonNull } from "@/utils/fn";
import { notEmptyOrNull } from "@/utils/string-helpers";

export const SynthesisContent = (props: {
    findingGroup: DocumentFindingGroup & {
        metadata?: SynthesisMetadata | null;
    };
    regenerateAction?: UseAsyncState<[], unknown>;
}) => {
    const finding_groups = useGridView((s) => s.finding_groups);
    const documents = useGridView((s) => s.documents);
    switch (props.findingGroup.status) {
        case FindingGroupStatus.processing:
            return (
                <div className="flex flex-col items-center justify-center gap-3 p-20">
                    <Loader2 className="size-4 animate-spin" />
                    <p className="font-headline text-xl font-medium">
                        Thinking...
                    </p>
                    <p className="text-muted-foreground text-sm">
                        This may take up to 2 minutes
                    </p>
                </div>
            );
        case FindingGroupStatus.completed:
            return (
                <CitationContextProvider
                    findings={Array.from(finding_groups.values())
                        .flatMap((fg) => getMaybeValue(fg.findings))
                        .filter(nonNull)}
                    documents={Array.from(documents.values())}
                >
                    <div className="space-y-4 p-4">
                        <DebugID
                            label="Finding Group"
                            id={props.findingGroup.id}
                            className="block"
                        />
                        {notEmptyOrNull(
                            props.findingGroup.metadata?.custom_input,
                        ) && (
                            <p className="bg-muted flex items-center gap-2 rounded p-2 font-medium">
                                <Quote className="size-4 -scale-100" />
                                {props.findingGroup.metadata.custom_input}
                            </p>
                        )}
                        {props.findingGroup.findings.flatMap((finding) => [
                            <DebugID
                                key={`debug_${finding.id}`}
                                label="Finding"
                                id={finding.id}
                                className="block"
                            />,
                            <DetailedFinding
                                key={finding.id}
                                finding={finding}
                            />,
                            ,
                        ])}
                    </div>
                    <Citation />
                </CitationContextProvider>
            );
        case FindingGroupStatus.failed:
        case FindingGroupStatus.cancelled:
            return (
                <div className="space-y-4 p-4">
                    <p className="text-destructive italic">
                        Failed to synthesize findings. Please regenerate the
                        synthesis.
                    </p>
                    {props.regenerateAction && (
                        <AsyncButton
                            variant="outline"
                            action={props.regenerateAction}
                        >
                            <RefreshCw className="mr-2 size-4" />
                            Regenerate Synthesis
                        </AsyncButton>
                    )}
                </div>
            );
    }
};

export const SynthesisDetails = (props: {
    findingGroupInfo: Omit<FindingGroupInfo, "document_id">;
    regenerateAction?: UseAsyncState<[], unknown>;
}) => {
    const api = useApi();
    const reportID = useGridView((s) => s.report.id);
    const updateSynthesisInfo = useAnalyze((s) => s.updateSynthesisInfo);

    const { data } = useSWRImmutable(
        [
            reportID,
            props.findingGroupInfo.id,
            props.findingGroupInfo.status,
            "synthesis",
        ],
        async ([message_id, finding_group_id]) =>
            await api.fetch_synthesis(message_id, finding_group_id),
        { suspense: true },
    );

    useWebsocket(
        (ws) => {
            ws.onIf(
                IncomingWebsocketEventType.finding_group_updated,
                (fg) => fg.id === props.findingGroupInfo.id,
                updateSynthesisInfo,
            );
        },
        [props.findingGroupInfo.id, updateSynthesisInfo],
    );

    return (
        <SynthesisContent
            findingGroup={data}
            regenerateAction={props.regenerateAction}
        />
    );
};
