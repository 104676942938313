import { LogOut, Settings, Shield, User } from "lucide-react";
import { use } from "react";
import { Link, Outlet } from "react-router-dom";

import { AuthLevel } from "@/api/types";
import { ColorSchemeToggle } from "@/components/color-scheme-toggle";
import { OfflineIndicator } from "@/components/offline-indicator";
import { PageBreadcrumb } from "@/components/page-breadcrumb";
import { Button } from "@/components/ui/button";
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarTrigger,
} from "@/components/ui/menubar";
import { AppContext } from "@/context/app-context";
import { AuthGate } from "@/gates/auth-gate";
import { getFirstLastName } from "@/utils/user";

export const V3AppLayout = () => {
    const { user, logout } = use(AppContext);
    return (
        <div className="pt-14">
            <OfflineIndicator />
            <header className="bg-background fixed top-0 right-0 left-0 z-10 flex h-14 items-center justify-between border-b pr-2 pl-4">
                <PageBreadcrumb />
                {user && (
                    <Menubar className="border-none bg-transparent p-0">
                        <MenubarMenu>
                            <MenubarTrigger asChild>
                                <Button variant="ghost" size="icon">
                                    <User className="size-4" />
                                </Button>
                            </MenubarTrigger>
                            <MenubarContent
                                className="min-w-56"
                                side="bottom"
                                align="end"
                                collisionPadding={5}
                                sideOffset={5}
                            >
                                <div className="flex items-center justify-between gap-4">
                                    <div className="p-2">
                                        <p className="font-headline font-bold empty:hidden">
                                            {getFirstLastName(user)}
                                        </p>
                                        <p className="text-muted-foreground text-xs">
                                            {user.username}
                                        </p>
                                    </div>
                                    <ColorSchemeToggle />
                                </div>
                                <MenubarSeparator />
                                <MenubarItem asChild>
                                    <Link to="/settings">
                                        <Settings className="mr-2 size-4" />
                                        Settings
                                    </Link>
                                </MenubarItem>
                                <AuthGate min={AuthLevel.superadmin}>
                                    <MenubarSeparator />
                                    <MenubarItem
                                        asChild
                                        className="text-orange-400 focus:bg-orange-100 focus:text-orange-800"
                                    >
                                        <Link to="/admin">
                                            <Shield className="mr-2 size-4" />
                                            Admin
                                        </Link>
                                    </MenubarItem>
                                </AuthGate>
                                <MenubarSeparator />
                                <MenubarItem
                                    className="text-red-700 focus:bg-red-100 focus:text-red-800"
                                    onClick={logout}
                                >
                                    <LogOut className="mr-2 size-4" />
                                    Logout
                                </MenubarItem>
                            </MenubarContent>
                        </MenubarMenu>
                    </Menubar>
                )}
            </header>
            <main>
                <Outlet />
            </main>
        </div>
    );
};
