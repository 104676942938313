import { FeatureFlagBool, FeatureFlagNumber } from "@/conf/feature-flags.ts";
import { MAX_DOCUMENT_COUNT } from "@/conf/report.ts";

export type FeatureFlags = Readonly<
    {
        [key in FeatureFlagBool]: boolean;
    } & {
        [key in FeatureFlagNumber]: number;
    }
>;

export type ConfigurationMeta = Readonly<{
    apiDomain: string;
    auth0: boolean;
    env: string;
    featureFlags: FeatureFlags;
    fullstoryOrgId: string;
    googleAnalyticsId?: string;
    googleMapsApiKey: string;
    isDev: boolean;
    launchDarklyClientId: string;
    sentryDsn: string;
    sentryEnv: string;
    sha: string;
    stripePublicKey: string;
}>;

// First, look into the global scope to see if the config was bootstrapped server-side
// Note that this would have been injected by the server-side rendering process BEFORE the app's entrypoint
// @ts-expect-error __BW_CONFIG__ is a global variable and eslint doesn't like it
const bootstrap: ConfigurationMeta = window["__BW_CONFIG__"] || {};
const bootstrapFeatureFlags = bootstrap.featureFlags || {};

// If the config was not bootstrapped server-side, use the environment settings baked into the app by Vite
const defaultConfig: ConfigurationMeta = {
    apiDomain: import.meta.env.VITE_API_DOMAIN,
    auth0: true,
    env: import.meta.env.MODE,
    featureFlags: {
        actionsWithoutInquiry: true,
        chatEnabled: true,
        clearChat: true,
        customSynthesisEnabled: false,
        enableDocAndTxtUpload: true,
        enableSpreadsheetUpload: false,
        forceBooleanFindingToFalse: false,
        fullstoryEnabled: false,
        regenerateSynthesis: true,
        reportMaxDocuments: MAX_DOCUMENT_COUNT,
        reportV0Enabled: false,
        synthesisEnabled: false,
    },
    fullstoryOrgId: "notset", // This will be provided by the server-side rendering process
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    isDev: import.meta.env.DEV,
    launchDarklyClientId: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
    sentryDsn: import.meta.env.VITE_SENTRY_DSN,
    sentryEnv: import.meta.env.VITE_SENTRY_ENV,
    sha: "unknown", // This will be provided by the server-side rendering process
    stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
};
const defaultFeatureFlags = defaultConfig.featureFlags;

// Merge the two configurations, giving priority to the server-side config
export const config: ConfigurationMeta = {
    ...defaultConfig,
    ...bootstrap,
    featureFlags: { ...defaultFeatureFlags, ...bootstrapFeatureFlags },
};
