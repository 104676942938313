import { v4 as uuidv4 } from "uuid";

import { base_websocket_url } from "@/api/utils";
import { HandlerMap, HandlersByEvent } from "@/api/ws/handler-map";
import {
    OutboundEvent,
    serialize,
    threadSubscribe,
    threadUnsubscribe,
} from "@/api/ws/websocket-outbound-events";
import {
    InboundWebsocketEvents,
    IncomingWebsocketEventType,
    WebsocketInboundMessage,
} from "@/api/ws/websocket-types";
import { sleep } from "@/utils/async";
import { log } from "@/utils/log";

enum WebsocketCloseCode {
    NORMAL_CLOSURE = 1000,
    GOING_AWAY = 1001,
    PROTOCOL_ERROR = 1002,
    UNSUPPORTED_DATA = 1003,
    RESERVED = 1004,
    NO_STATUS_RECEIVED = 1005,
    ABNORMAL_CLOSURE = 1006,
    INVALID_FRAME_PAYLOAD_DATA = 1007,
    POLICY_VIOLATION = 1008,
    MESSAGE_TOO_BIG = 1009,
    MANDATORY_EXTENSION = 1010,
    INTERNAL_SERVER_ERROR = 1011,
    SERVICE_RESTART = 1012,
    TLS_HANDSHAKE = 1015,

    TOKEN_EXPIRED = 3000,
}

enum WebsocketState {
    error = 0,
    initial,
    connecting,
    reconnecting,
    connected,
}

const createWebsocket = (url: string): WebSocket => {
    const connection = new WebSocket(url);
    connection.binaryType = "arraybuffer";
    return connection;
};

const parseEvent = <Tk extends keyof InboundWebsocketEvents>(
    event_type: IncomingWebsocketEventType,
    body: unknown,
): WebsocketInboundMessage<Tk> | null => {
    if (event_type in IncomingWebsocketEventType) {
        return { event_type, body } as WebsocketInboundMessage<Tk>;
    }
    return null;
};

function invokeEventHandler<Tk extends keyof InboundWebsocketEvents>(
    event: WebsocketInboundMessage<Tk>,
    handlers: HandlersByEvent,
) {
    if (event.event_type in handlers) {
        Object.values(handlers[event.event_type]!).forEach((fn) =>
            fn(event.body),
        );
    }
}

export type EventHandler<Tv> = (event: Tv) => void;

export enum WebsocketClientEvent {
    AUTH_REVOKED = "auth_revoked",
    SOCKET_DISCONNECTED = "socket_disconnected",
    SOCKET_CONNECTED = "socket_connected",
    SOCKET_RECONNECTED = "socket_reconnected",
}

export class WebsocketClient extends EventTarget {
    private static retryInterval = 1000;
    private maxState: WebsocketState = WebsocketState.initial;
    private connection: WebSocket | null = null;
    private handlers: HandlerMap = new HandlerMap();
    private threadSubscriptions = new Map<string, Set<string>>();

    constructor() {
        super();
        this.connectWithRetry();
    }

    public async send(event: OutboundEvent) {
        while (
            !this.connection ||
            this.connection.readyState !== WebSocket.OPEN
        ) {
            await sleep(50);
        }
        log("[ws] send %c" + event.type, "font-weight: bold", event.payload);
        this.connection.send(serialize(event));
    }

    public subscribeToThread(threadID: string): string {
        const subscription_id = uuidv4();
        const subscriptions = this.threadSubscriptions.get(threadID);

        // already subscribed to thread. add handler id
        if (subscriptions != undefined) {
            subscriptions.add(subscription_id);
        } else {
            this.threadSubscriptions.set(threadID, new Set([subscription_id]));
            // send sync
            this.send(threadSubscribe(threadID));
        }

        return subscription_id;
    }

    public unsubscribeFromThread(threadID: string, subscriptionID: string) {
        const subscriptions = this.threadSubscriptions.get(threadID);
        if (subscriptions && subscriptions.has(subscriptionID)) {
            subscriptions.delete(subscriptionID);

            if (subscriptions.size === 0) {
                this.threadSubscriptions.delete(threadID);
                this.send(threadUnsubscribe(threadID));
            }
        }
    }

    public registerEventHandler<Tk extends keyof InboundWebsocketEvents>(
        event: Tk,
        handler: EventHandler<InboundWebsocketEvents[Tk]>,
        id?: string,
    ): string {
        return this.handlers.add_handler(event, handler, id);
    }

    public removeHandler(handler_id: string) {
        this.handlers.remove_handler(handler_id);
    }

    public disconnect() {
        this.setState(WebsocketState.initial);

        if (!this.connection) {
            log("[ws] connection already closed");
            // already disconnected ?
            return;
        }
        log("[ws] remove listener");

        this.connection.removeEventListener("open", this.handleOpen);
        this.connection.removeEventListener("message", this.handleMessage);
        this.connection.removeEventListener("error", this.handleError);
        this.connection.removeEventListener("close", this.handleClose);

        if (this.connection.readyState === WebSocket.OPEN) {
            log("[ws] close");
            this.connection.close(WebsocketCloseCode.NORMAL_CLOSURE);
        } else {
            this.connection = null;
        }
    }

    private setState(state: WebsocketState) {
        this.maxState = Math.max(this.maxState, state);
    }

    private connectWithRetry() {
        try {
            this.setupConnection();
        } catch (err) {
            log("[ws] failed to connect", err);
            setTimeout(
                this.connectWithRetry.bind(this),
                WebsocketClient.retryInterval,
            );
        }
    }

    private setupConnection() {
        this.connection = createWebsocket(`${base_websocket_url}/ws`);
        log("[ws] connecting");
        this.setState(
            this.maxState >= WebsocketState.connected
                ? WebsocketState.reconnecting
                : WebsocketState.connecting,
        );
        this.connection.addEventListener("open", this.handleOpen);
        this.connection.addEventListener("message", this.handleMessage);
        this.connection.addEventListener("error", this.handleError);
        this.connection.addEventListener("close", this.handleClose);
    }

    private handleOpen = () => {
        log("[ws] connected");
        const hasReconnected = this.maxState >= WebsocketState.connected;
        this.setState(WebsocketState.connected);
        this.dispatchEvent(
            new Event(
                hasReconnected
                    ? WebsocketClientEvent.SOCKET_RECONNECTED
                    : WebsocketClientEvent.SOCKET_CONNECTED,
            ),
        );
    };

    private handleMessage = (raw_event: MessageEvent<ArrayBuffer>) => {
        try {
            const data = new DataView(raw_event.data);
            const data_str = new TextDecoder().decode(data);
            const json = JSON.parse(data_str);
            const event_type =
                IncomingWebsocketEventType[
                    json.event_type as keyof typeof IncomingWebsocketEventType
                ];
            const body = json.body;
            const event = parseEvent(event_type, body);
            if (event) {
                log(
                    "[ws] recv %c" + event.event_type,
                    "font-weight: bold",
                    event.body,
                );
                invokeEventHandler(event, this.handlers.by_event());
            } else {
                log("[ws] unhandled event", json);
            }
        } catch (err) {
            console.error(err);
        }
    };

    private handleError = (err: Event) => {
        // todo handle better
        console.error("[ws] error", err);
    };

    private handleClose = (event: CloseEvent) => {
        log("[ws] closed", event);
        this.dispatchEvent(new Event(WebsocketClientEvent.SOCKET_DISCONNECTED));
        switch (event.code) {
            case WebsocketCloseCode.SERVICE_RESTART:
            case WebsocketCloseCode.NORMAL_CLOSURE:
            case WebsocketCloseCode.ABNORMAL_CLOSURE:
                // todo should this be in error state (?)
                this.setState(WebsocketState.error);
                // delayed reconnect
                setTimeout(
                    this.connectWithRetry.bind(this),
                    WebsocketClient.retryInterval,
                );
                return;
            case WebsocketCloseCode.TOKEN_EXPIRED:
                this.dispatchEvent(
                    new Event(WebsocketClientEvent.AUTH_REVOKED),
                );
                return;
            default:
                // unhandled error
                log("[ws] unhandled close event", event);
        }
    };
}
