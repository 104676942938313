import {
    WebsocketClient,
    WebsocketClientEvent,
} from "@/api/ws/websocket-client";

let cache: Promise<WebsocketClient> | null = null;

export const getCachedWebsocket = (): Promise<WebsocketClient> => {
    if (!cache) {
        cache = new Promise((resolve) => {
            const ws_client = new WebsocketClient();
            ws_client.addEventListener(
                WebsocketClientEvent.SOCKET_CONNECTED,
                () => resolve(ws_client),
            );
        });
    }
    return cache;
};
