import { useFlags } from "launchdarkly-react-client-sdk";

import { FeatureFlagBool, FeatureFlagNumber } from "@/conf/feature-flags";
import { config } from "@/utils/configuration";

export const useFeatureFlagBool = (
    name: FeatureFlagBool,
    _: boolean,
): boolean => {
    const flags = useFlags();
    return name in flags ? flags[name] : config.featureFlags[name];
};

export const useFeatureFlagNumber = (
    name: FeatureFlagNumber,
    _: number,
): number => {
    const flags = useFlags();
    return name in flags ? flags[name] : config.featureFlags[name];
};
