import { HTMLAttributes, ReactNode } from "react";

import { APActionBase } from "@/api/types";
import { ActionIcon } from "@/components/action-message/action-icon";
import { ErrorBoundary } from "@/components/error-boundary";
import { RelativeTime } from "@/components/relative-time";
import { cn } from "@/lib/utils";
import { getActionLabel } from "@/utils/actions";

interface Props extends HTMLAttributes<HTMLDivElement> {
    action: APActionBase;
    icon?: ReactNode;
}

export const ActionHeader = ({
    action,
    className,
    icon,
    children,
    ...props
}: Props) => (
    <div
        className={cn("flex items-center justify-between gap-2", className)}
        {...props}
    >
        <span className="flex items-center gap-2">
            <ActionIcon type={action.type} className="shrink-0" />
            <span className="font-headline text-sm font-bold whitespace-nowrap">
                {getActionLabel(action.type)}
            </span>
        </span>
        {children ?? <span className="grow" />}
        <ErrorBoundary>
            <RelativeTime
                ts={action.created_at}
                className="text-xs whitespace-nowrap"
            />
        </ErrorBoundary>
        {icon}
    </div>
);
