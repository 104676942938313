import { Table } from "@tanstack/react-table";
import { Check } from "lucide-react";
import { ReactNode } from "react";

import { DocumentType } from "@/api/types";
import { CompanyLogo } from "@/components/company-logo";
import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import { StaticColumn } from "@/conf/grid-view";
import { cn } from "@/lib/utils";
import { setToggle } from "@/utils/es6-set";
import { getDocumentTypeLabel } from "@/utils/report";
import { notEmptyOrNull } from "@/utils/string-helpers";

type Props = {
    columnId: string;
    title: string;
    icon?: ReactNode;
    table: Table<DocumentWithAsyncFindings>;
};

export const CategoricalColumnFilter = ({ table, ...props }: Props) => {
    const column = table.getColumn(props.columnId);
    if (!column) return null;
    const filterValue = new Set(
        column.getFilterValue() as string[] | undefined,
    );

    const handleSelect = (value: string) => {
        column.setFilterValue(
            Array.from(setToggle(filterValue, value).values()),
        );
    };
    const clearFilter = () => column.setFilterValue(undefined);
    const handleClose = () => {
        if (!filterValue || filterValue.size === 0) {
            clearFilter();
        }
    };

    return (
        <FilterPopover
            {...props}
            onClear={clearFilter}
            filterValue={getBadge(props.columnId, filterValue)}
            className="overflow-hidden"
            onClose={handleClose}
        >
            <Command className="rounded-none border-t">
                <CommandInput autoFocus />
                <CommandList>
                    <CommandEmpty>No filter values found</CommandEmpty>
                    {Array.from(column.getFacetedUniqueValues().keys())
                        .filter(notEmptyOrNull)
                        .map((value) => (
                            <CommandItem
                                key={value}
                                value={value}
                                className="gap-2 font-medium"
                                onSelect={handleSelect}
                            >
                                <Check
                                    className={cn(
                                        "size-4",
                                        !filterValue.has(value) && "invisible",
                                    )}
                                />
                                <CategoryFilterItem
                                    columnId={props.columnId}
                                    value={value}
                                />
                            </CommandItem>
                        ))}
                </CommandList>
            </Command>
        </FilterPopover>
    );
};

type CategoryFilterItemProps = {
    columnId: string;
    value: string;
};

const CategoryFilterItem = (props: CategoryFilterItemProps) => {
    switch (props.columnId) {
        case StaticColumn.ticker:
            return (
                <>
                    <CompanyLogo ticker={props.value} className="bg-muted" />
                    {props.value}
                </>
            );
        case StaticColumn.document_type:
            return <>{getDocumentTypeLabel(props.value as DocumentType)}</>;
        default:
            return <>{props.value}</>;
    }
};

const getBadge = (columnId: string, values: Set<string>) => {
    const first = values.values().next().value;
    if (first === undefined) return undefined;
    switch (columnId) {
        case StaticColumn.ticker:
            return (
                <div className="flex items-center gap-1">
                    <CompanyLogo
                        ticker={first}
                        className="bg-muted group-hover:bg-background size-8 rounded-full p-1.5 transition-colors empty:hidden"
                    />
                    <span className="bg-muted group-hover:bg-background rounded-full p-2 text-xs transition-colors empty:hidden">
                        {values.size > 1 ? `+ ${values.size - 1}` : null}
                    </span>
                </div>
            );
        case StaticColumn.document_type:
            return (
                <div className="flex items-center gap-1">
                    <span className="bg-muted group-hover:bg-background rounded-full p-2 text-xs transition-colors empty:hidden">
                        {getDocumentTypeLabel(first as DocumentType)}
                    </span>
                    <span className="bg-muted group-hover:bg-background flex gap-1 rounded-full px-2.5 py-1.5 text-xs transition-colors empty:hidden">
                        {values.size > 1 ? `+ ${values.size - 1}` : null}
                    </span>
                </div>
            );
        default:
            return undefined;
    }
};
