import {
    format,
    formatDistance,
    formatRelative,
    isToday,
    isYesterday,
    parseISO,
} from "date-fns";

import { DocumentCollection, DocumentType } from "@/api/types";
import { lastX, unique } from "@/utils/collection";
import { mapUpdate } from "@/utils/es6-map";
import {
    capitalizeAll,
    notEmptyOrNull,
    plural,
    pluralize,
} from "@/utils/string-helpers";
import { formatDateTime } from "@/utils/time";

export const formatReportTime = (
    dateStr: string,
    unseen: boolean | undefined,
): string => {
    const date = parseISO(dateStr);
    if (isToday(date)) {
        return formatDistance(date, new Date(), { addSuffix: true });
    }
    if (unseen === true) {
        if (isYesterday(date)) {
            return formatRelative(date, new Date());
        }
        return formatDateTime(date);
    }
    return format(date, "p");
};

export const formatStatistic = (key: string, value: number): string => {
    switch (key) {
        case "risks":
            return plural(value, "Risk");
        case "insights":
            return plural(value, "Insight");
        case "statistics":
            return plural(value, "Statistic");
        case "followup_instructions":
            return plural(value, "Follow-Up Instruction");
        default:
            return plural(value, capitalizeAll(key.replace(/_/g, " ")));
    }
};

export const getDocumentTypeLabel = (type: DocumentType) => {
    switch (type) {
        case DocumentType.pdf:
            return "PDF";
        case DocumentType.website:
            return "Website";
        case DocumentType.text:
            return "Text Document";
        case DocumentType.spreadsheet:
            return "Spreadsheet";
        case DocumentType.sec_filing:
            return "SEC Filing";
        case DocumentType.earnings_transcript:
            return "Earnings Call Transcript";
        case DocumentType.quartr_transcript:
            return "Investor Meetings";
        default:
            return type satisfies never;
    }
};

export const getReportTitle = (report: DocumentCollection): string => {
    if (notEmptyOrNull(report.title)) {
        return report.title;
    }
    const infos = report.documents.map((d) => d.info);
    const ticker = unique(
        infos
            .filter(
                (d) =>
                    d.doc_type === DocumentType.sec_filing ||
                    d.doc_type === DocumentType.earnings_transcript,
            )
            .map((d) => d.ticker),
    ).sort();
    if (ticker.length > 0) {
        return `Analysis of ${str_enumerate(ticker)}`;
    }
    const doc_types = infos
        .map((d) => d.doc_type)
        .sort()
        .reduce(
            (map, type) => mapUpdate(map, type, (v = 0) => v + 1),
            new Map<DocumentType, number>(),
        );
    if (doc_types.size > 0) {
        const docs = Array.from(doc_types.entries()).map(
            ([type, count]) =>
                `${count} ${pluralize(count, getDocumentTypeLabel(type))}`,
        );
        return `Analysis of ${str_enumerate(docs)}`;
    }
    return "Unnamed Analysis";
};

const str_enumerate = (items: string[]) => {
    switch (items.length) {
        case 0:
        case 1:
        case 2:
            return items.join(" and ");
        default:
            return `${items.slice(0, items.length - 1).join(", ")}, and ${lastX(items)}`;
    }
};
