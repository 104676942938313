import { OnChangeFn, SortingState } from "@tanstack/react-table";
import {
    ALargeSmall,
    ArrowDownWideNarrow,
    ArrowUpWideNarrow,
    Building,
    CalendarDays,
    Circle,
    CircleDot,
    Folder,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { SORTABLE_COLUMNS, StaticColumn } from "@/conf/grid-view";
import { first, uniqueBy } from "@/utils/collection";
import { getStaticColumnLabel } from "@/utils/grid-view";

type Props = {
    sorting: SortingState;
    onSortingChange: OnChangeFn<SortingState>;
};

export const Sorting = ({ sorting, onSortingChange }: Props) => {
    const colId = first(sorting)?.id;
    const desc = first(sorting)?.desc ?? false;
    const sortedBy =
        colId !== undefined
            ? StaticColumn[colId as keyof typeof StaticColumn]
            : undefined;
    const getTypeHandler = (type: StaticColumn) => () => {
        onSortingChange((s) =>
            uniqueBy(
                [
                    s.find((col) => col.id === type) ?? {
                        id: type,
                        desc: true,
                    },
                    ...s,
                ],
                (col) => col.id,
            ),
        );
    };
    const getDirectionHandler = (desc: boolean) => () => {
        onSortingChange(([first, ...rest]) =>
            uniqueBy([{ ...first, desc }, ...rest], (col) => col.id),
        );
    };
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="outline" className="gap-2 rounded-full">
                    {first(sorting)?.desc ? (
                        <ArrowDownWideNarrow className="size-4" />
                    ) : (
                        <ArrowUpWideNarrow className="size-4" />
                    )}
                    <span>Sorted:</span>
                    <span className="font-normal">
                        {sortedBy ? getStaticColumnLabel(sortedBy) : "n/a"}
                    </span>
                </Button>
            </PopoverTrigger>
            <PopoverContent align="start" className="flex flex-col gap-1 p-1">
                {[...SORTABLE_COLUMNS].map((type) => (
                    <Button
                        key={type}
                        variant="ghost"
                        size="sm"
                        className="justify-start gap-2.5"
                        onClick={getTypeHandler(type)}
                    >
                        {sortedBy === type ? (
                            <CircleDot className="size-4" />
                        ) : (
                            <Circle className="text-muted-foreground size-4" />
                        )}
                        <StaticColumnIcon type={type} />
                        {getStaticColumnLabel(type)}
                    </Button>
                ))}
                <Separator />
                <Button
                    variant="ghost"
                    size="sm"
                    className="justify-start gap-2.5"
                    onClick={getDirectionHandler(false)}
                >
                    {desc === false ? (
                        <CircleDot className="size-4" />
                    ) : (
                        <Circle className="text-muted-foreground size-4" />
                    )}
                    <ArrowUpWideNarrow className="size-4" />
                    Ascending
                </Button>
                <Button
                    variant="ghost"
                    size="sm"
                    className="justify-start gap-2.5"
                    onClick={getDirectionHandler(true)}
                >
                    {desc === true ? (
                        <CircleDot className="size-4" />
                    ) : (
                        <Circle className="text-muted-foreground size-4" />
                    )}
                    <ArrowDownWideNarrow className="size-4" />
                    Descending
                </Button>
            </PopoverContent>
        </Popover>
    );
};

export const StaticColumnIcon = ({ type }: { type: StaticColumn }) => {
    switch (type) {
        case StaticColumn.select:
        case StaticColumn.document:
        case StaticColumn.document_status:
            return <div className="size-4" />;

        case StaticColumn.ticker:
            return <Building className="size-4" />;
        case StaticColumn.document_type:
            return <Folder className="size-4" />;
        case StaticColumn.content_date:
            return <CalendarDays className="size-4" />;
        case StaticColumn.document_title:
            return <ALargeSmall className="size-4" />;
        case StaticColumn.add_column:
            return null;
        default:
            return type satisfies never;
    }
};
