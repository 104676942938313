import { LogOut, Settings, Shield, User } from "lucide-react";
import { Link } from "react-router-dom";

import { AuthLevel, User as TUser } from "@/api/types";
import { ColorSchemeToggle } from "@/components/color-scheme-toggle";
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarTrigger,
} from "@/components/ui/menubar";
import { AuthGate } from "@/gates/auth-gate";
import { getFirstLastName } from "@/utils/user";

type Props = {
    user: TUser;
    onLogout: () => void;
};

export const UserMenu = (props: Props) => (
    <Menubar className="border-none bg-transparent p-0">
        <MenubarMenu>
            <MenubarTrigger className="bg-muted-foreground text-background dark:focus:bg-foreground/40 dark:focus:text-foreground dark:data-[state=open]:bg-foreground dark:data-[state=open]:text-background flex size-10 items-center justify-center rounded-full p-0 transition-colors">
                <User className="size-5" />
            </MenubarTrigger>
            <MenubarContent className="min-w-56" side="bottom" align="end">
                <div className="flex items-center justify-between gap-4">
                    <div className="p-2">
                        <p className="font-headline font-bold empty:hidden">
                            {getFirstLastName(props.user)}
                        </p>
                        <p className="text-muted-foreground text-xs">
                            {props.user.username}
                        </p>
                    </div>
                    <ColorSchemeToggle />
                </div>
                <MenubarSeparator />
                <MenubarItem asChild>
                    <Link to="/settings">
                        <Settings className="mr-2 size-4" />
                        Settings
                    </Link>
                </MenubarItem>
                <AuthGate min={AuthLevel.superadmin}>
                    <MenubarSeparator />
                    <MenubarItem
                        asChild
                        className="text-orange-400 focus:bg-orange-100 focus:text-orange-800"
                    >
                        <Link to="/admin">
                            <Shield className="mr-2 size-4" />
                            Admin
                        </Link>
                    </MenubarItem>
                </AuthGate>
                <MenubarSeparator />
                <MenubarItem
                    className="text-red-700 focus:bg-red-100 focus:text-red-800"
                    onClick={props.onLogout}
                >
                    <LogOut className="mr-2 size-4" />
                    Logout
                </MenubarItem>
            </MenubarContent>
        </MenubarMenu>
    </Menubar>
);
