import { useParams } from "react-router-dom";
import { validate as validateUUID } from "uuid";

import { ReportContent } from "@/components/document-table/report/content";
import { Headline } from "@/components/headline";
import { GridViewContextProvider } from "@/context/grid-view-context-provider";

import { NotFoundRoute } from "./not-found";

export const V3ReportRoute = () => {
    const { id } = useParams<{ id: string }>();

    if (id === undefined || !validateUUID(id)) {
        return <NotFoundRoute />;
    }
    return (
        <div className="container max-w-3xl space-y-4 py-10">
            <Headline level={3} highlighted>
                Report
            </Headline>
            <GridViewContextProvider messageID={id}>
                <ReportContent />
            </GridViewContextProvider>
        </div>
    );
};
