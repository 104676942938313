import {
    FileUp,
    GalleryVerticalEnd,
    Loader2,
    MessageSquareQuote,
    X,
} from "lucide-react";
import { PropsWithChildren, Suspense } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { validate as validateUUID } from "uuid";
import { useShallow } from "zustand/react/shallow";

import { ContextType, DocumentType, UploadFile } from "@/api/types";
import { ActionPanel } from "@/components/action-panel/action-panel";
import { ActionPanelHeader } from "@/components/action-panel/header";
import { DocumentSearch } from "@/components/analyze/document-search";
import { Dropzone } from "@/components/analyze/dropzone";
import { OverlayContainer, OverlayContent } from "@/components/analyze/overlay";
import { GridViewSkeleton } from "@/components/analyze/skeleton";
import { UploadToast } from "@/components/analyze/upload-toast";
import { DocumentLinkContext } from "@/components/document/document-link-context";
import { ColumnSheet } from "@/components/document-table/columns/sheet";
import { LeftPanelHeader } from "@/components/document-table/left-panel-header";
import { ReportContent } from "@/components/document-table/report/content";
import { ReportTrigger } from "@/components/document-table/report/trigger";
import { RegenerateSynthesisButton } from "@/components/document-table/synthesis/regenerate-button";
import { SynthesisDetails } from "@/components/document-table/synthesis/synthesis-details";
import { ReportHeader } from "@/components/report/header";
import { RestoreScrollPosition } from "@/components/restore-scroll-position";
import { Button } from "@/components/ui/button";
import { FeatureFlagBool, FeatureFlagNumber } from "@/conf/feature-flags";
import { MAX_DOCUMENT_COUNT } from "@/conf/report";
import { ContextMenuContainer } from "@/container/context-menu";
import { DocumentContainer } from "@/container/grid-view-document-container";
import { GridView } from "@/container/gridview";
import { ActionsContextProvider } from "@/context/actions-context-provider";
import { ContextContextProvider } from "@/context/context-context-provider";
import { FileUploadContextProvider } from "@/context/file-upload-context-provider";
import { GridViewContextProvider } from "@/context/grid-view-context-provider";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useAnalyze } from "@/hooks/use-analyze";
import { useContextMenu } from "@/hooks/use-context-menu";
import {
    useFeatureFlagBool,
    useFeatureFlagNumber,
} from "@/hooks/use-feature-flag";
import { useFileUploadContext } from "@/hooks/use-file-upload-context";
import { useGridView } from "@/hooks/use-grid-view-context";
import { usePageTitle } from "@/hooks/use-page-title";
import { AppLayout } from "@/layouts/app-layout";
import { NotFoundRoute } from "@/routes/not-found";
import { isFileUploaded } from "@/utils/file-upload";
import { getReportTitle } from "@/utils/report";

export const AnalyzeContainer = () => {
    const chatEnabled = useFeatureFlagBool(FeatureFlagBool.chat_enabled, true);
    const actionsVsChat = useFeatureFlagBool(
        FeatureFlagBool.actions_without_inquiry,
        true,
    );
    const maxDocumentCount = useFeatureFlagNumber(
        FeatureFlagNumber.report_max_documents,
        MAX_DOCUMENT_COUNT,
    );
    const contextMenu = useContextMenu();

    const report = useGridView((s) => s.report);
    const documents = useGridView((s) => s.documents);
    const addItems = useGridView((s) => s.addItems);

    const selectedDocumentID = useAnalyze((s) => s.document_id);
    const selectedSynthesis = useAnalyze((s) => s.synthesis);
    const isReportOpen = useAnalyze((s) => s.report ?? false);
    const openDocument = useAnalyze((s) => s.openDocument);
    const clearLeftPanel = useAnalyze((s) => s.clear);

    const isActionPanelOpen = useActionsContext((s) => s.isActionPanelOpen);
    const openActionPanel = useActionsContext((s) => s.actionPanelOpen);
    const closeActionPanel = useActionsContext((s) => s.actionPanelClose);

    const files = useFileUploadContext((s) => s.files);
    const resetFiles = useFileUploadContext((s) => s.reset);

    usePageTitle(getReportTitle(report));

    const maxFiles = useFileUploadContext((s) => s.maxFiles);
    const { open: fileDialogOpen } = useFileUploadContext((s) => s.dropzone);
    const handleFileOpen = () => {
        if (maxFiles <= 0) {
            return toast.error("File limit reached");
        }
        fileDialogOpen();
    };

    const addedDocumentIDs = useGridView(
        useShallow(
            (s) =>
                new Set(
                    Array.from(s.documents.values())
                        .filter(
                            (d) =>
                                d.doc_type === DocumentType.sec_filing ||
                                d.doc_type === DocumentType.earnings_transcript,
                        )
                        .map((d) => d.unique_id),
                ),
        ),
    );

    const toggleActionPanel = () =>
        isActionPanelOpen ? closeActionPanel() : openActionPanel();

    const dismissOverlay = () => {
        closeActionPanel();
        clearLeftPanel();
    };

    useHotkeys("mod+j", toggleActionPanel, { enableOnFormTags: true });
    useHotkeys("esc", dismissOverlay, { enableOnFormTags: true });

    const updateReportTitle = useGridView((s) => s.updateReportTitle);

    return (
        <DocumentLinkContext value={{ onNavigate: openDocument }}>
            <AppLayout
                header={
                    <div className="flex flex-1 justify-center">
                        <ReportHeader
                            report={report}
                            onTitleChange={updateReportTitle}
                        />
                    </div>
                }
                className="bg-page-background relative flex h-screen grow flex-col"
            >
                <div className="border-foreground/5 bg-foreground flex min-h-14 items-center justify-between gap-10 border-b px-3 dark:bg-neutral-900">
                    <div className="flex flex-1 items-center gap-2">
                        {chatEnabled &&
                            (actionsVsChat ? (
                                <Button
                                    variant="secondary"
                                    className="gap-2"
                                    onClick={toggleActionPanel}
                                >
                                    <GalleryVerticalEnd className="size-4" />
                                    Action History
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    className="gap-2"
                                    onClick={toggleActionPanel}
                                >
                                    <MessageSquareQuote className="size-4 translate-y-px" />
                                    Chat
                                </Button>
                            ))}
                        <ReportTrigger />
                    </div>
                    <div className="dark flex flex-2 items-center gap-4">
                        <Button
                            variant="secondary"
                            className="text-muted-foreground gap-2"
                            onClick={handleFileOpen}
                        >
                            <FileUp className="size-4" />
                            Upload Documents
                        </Button>
                        <DocumentSearch
                            addedDocumentIDs={addedDocumentIDs}
                            addItems={addItems}
                            placeholder="Search for additional companies and documents"
                            maxDocumentCount={maxDocumentCount - documents.size}
                        />
                    </div>
                    <div className="flex flex-1 justify-end" />
                </div>
                <GridView />
                <OverlayContainer onDismiss={dismissOverlay}>
                    {chatEnabled && (
                        <OverlayContent side="left" open={isActionPanelOpen}>
                            <ActionPanel
                                header={
                                    <ActionPanelHeader
                                        title={
                                            actionsVsChat
                                                ? "Action History"
                                                : undefined
                                        }
                                        onClose={closeActionPanel}
                                    />
                                }
                                className="flex w-full flex-col"
                                disableInquiry={actionsVsChat}
                            />
                        </OverlayContent>
                    )}
                    <OverlayContent
                        side="right"
                        open={
                            selectedDocumentID != undefined ||
                            selectedSynthesis !== undefined ||
                            isReportOpen
                        }
                    >
                        <div className="bg-background flex min-h-12 items-center justify-between gap-2 border-b p-1">
                            <LeftPanelHeader className="grow px-3" />
                            {selectedSynthesis != undefined && (
                                <RegenerateSynthesisButton />
                            )}
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={clearLeftPanel}
                            >
                                <X className="size-4" />
                            </Button>
                        </div>
                        <Suspense
                            fallback={
                                <div className="flex justify-center p-20">
                                    <Loader2 className="size-4 animate-spin" />
                                </div>
                            }
                        >
                            {selectedDocumentID != undefined && (
                                <DocumentContainer
                                    id={selectedDocumentID}
                                    fallback={
                                        <div className="flex grow items-center justify-center">
                                            Document was deleted from this
                                            report
                                        </div>
                                    }
                                />
                            )}
                            {selectedSynthesis != undefined && (
                                <RestoreScrollPosition
                                    storageKey={selectedSynthesis.info.id}
                                >
                                    <SynthesisDetails
                                        findingGroupInfo={
                                            selectedSynthesis.info
                                        }
                                    />
                                </RestoreScrollPosition>
                            )}
                            {isReportOpen && <ReportContent />}
                        </Suspense>
                    </OverlayContent>
                </OverlayContainer>
                <Dropzone />
                <UploadToast
                    files={Array.from(files.values())}
                    onDismiss={resetFiles}
                />
                {chatEnabled && (
                    <ContextMenuContainer contextMenu={contextMenu} />
                )}
                <ColumnSheet />
            </AppLayout>
        </DocumentLinkContext>
    );
};

const FileUploadWrapper = (props: PropsWithChildren) => {
    const maxDocumentCount = useFeatureFlagNumber(
        FeatureFlagNumber.report_max_documents,
        MAX_DOCUMENT_COUNT,
    );
    const remainingCount = useGridView(
        (s) => maxDocumentCount - s.documents.size,
    );
    const addItems = useGridView((s) => s.addItems);
    const onUploadComplete = (files: UploadFile[]) =>
        addItems(
            files.filter(isFileUploaded).map((f) => ({
                type: ContextType.EXISTING_DOCUMENT,
                document_id: f.document_info.id,
            })),
        );
    return (
        <FileUploadContextProvider
            maxFiles={remainingCount}
            onUploadComplete={onUploadComplete}
        >
            {props.children}
        </FileUploadContextProvider>
    );
};

export const AnalyzeRoute = () => {
    const { id } = useParams<{ id: string }>();

    if (id === undefined || !validateUUID(id)) {
        return <NotFoundRoute />;
    }
    return (
        <Suspense fallback={<GridViewSkeleton />}>
            <ContextContextProvider messageID={id}>
                <GridViewContextProvider key={id} messageID={id}>
                    <ActionsContextProvider messageID={id}>
                        <FileUploadWrapper>
                            <AnalyzeContainer />
                        </FileUploadWrapper>
                    </ActionsContextProvider>
                </GridViewContextProvider>
            </ContextContextProvider>
        </Suspense>
    );
};
