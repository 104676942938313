import { PropsWithChildren, useEffect, useRef } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { toast } from "sonner";
import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";

import { FileStatus, UploadFile } from "@/api/types";
import { IncomingWebsocketEventType } from "@/api/ws/websocket-types";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { MAX_FILE_SIZE_MB } from "@/conf/report";
import { FileUploadContext } from "@/context/file-upload-context";
import { useApi } from "@/hooks/use-api";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { useWebsocket } from "@/hooks/use-websocket";
import { createFileUploadStore, FileUploadStore } from "@/stores/file-upload";
import { first } from "@/utils/collection";
import { getErrorMessage, isFileUploaded } from "@/utils/file-upload";
import { plural } from "@/utils/string-helpers";

type Props = {
    maxFiles: number;
    onUploadComplete?: (files: UploadFile[]) => void;
};

export const FileUploadContextProvider = ({
    maxFiles,
    onUploadComplete,
    ...props
}: PropsWithChildren<Props>) => {
    const enableDocAndTxtUpload = useFeatureFlagBool(
        FeatureFlagBool.enable_doc_and_txt_upload,
        true,
    );
    const enableSpreadsheetUpload = useFeatureFlagBool(
        FeatureFlagBool.enable_spreadsheet_upload,
        false,
    );
    const api = useApi();
    const dropzone = useDropzone({
        accept: {
            "application/pdf": [],
            ...(enableDocAndTxtUpload
                ? {
                      "text/plain": [],
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                          [],
                  }
                : undefined),
            ...(enableSpreadsheetUpload
                ? {
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                          [],
                      "application/vnd.ms-excel": [],
                  }
                : undefined),
        },
        maxSize: MAX_FILE_SIZE_MB * 1024 ** 2, // MB to bytes
        disabled: maxFiles <= 0,
        onDrop: (accepted: File[], rejected: FileRejection[]) => {
            if (accepted.length > maxFiles) {
                toast.error(<ToastLimitReached count={maxFiles} />);
                return;
            }
            if (rejected.length) {
                toast.error(<ToeastRejected rejected={rejected} />);
            }
            if (accepted.length) {
                uploadFiles(accepted);
            }
        },
    });
    // eslint-disable-next-line react-compiler/react-compiler
    const store = useRef<FileUploadStore>(
        createFileUploadStore({ api, dropzone }),
    ).current;

    const setDropzone = useStore(store, (s) => s.setDropzone);
    useEffect(() => {
        setDropzone(dropzone);
    }, [dropzone]);

    const setIsFocused = useStore(store, (s) => s.setIsFocused);
    useEffect(() => {
        setIsFocused(dropzone.isFocused);
    }, [dropzone.isFocused]);

    const setIsDragActive = useStore(store, (s) => s.setIsDragActive);
    useEffect(() => {
        setIsDragActive(dropzone.isDragActive);
    }, [dropzone.isDragActive]);

    const setIsDragAccept = useStore(store, (s) => s.setIsDragAccept);
    useEffect(() => {
        setIsDragAccept(dropzone.isDragAccept);
    }, [dropzone.isDragAccept]);

    const setIsDragReject = useStore(store, (s) => s.setIsDragReject);
    useEffect(() => {
        setIsDragReject(dropzone.isDragReject);
    }, [dropzone.isDragReject]);

    const setIsFileDialogActive = useStore(
        store,
        (s) => s.setIsFileDialogActive,
    );
    useEffect(() => {
        setIsFileDialogActive(dropzone.isFileDialogActive);
    }, [dropzone.isFileDialogActive]);

    const files = useStore(store, (s) => s.files);
    const uploadFiles = useStore(store, (s) => s.uploadFiles);
    const removeFiles = useStore(store, (s) => s.removeFiles);
    const updateFile = useStore(store, (s) => s.updateFile);
    useWebsocket((ws) => {
        ws.onIf(
            IncomingWebsocketEventType.upload_status_changed,
            ({ upload_id }) => files.has(upload_id),
            (evt) => {
                const status =
                    FileStatus[evt.status as keyof typeof FileStatus];
                updateFile(evt.upload_id, { status });
            },
        );
    });

    const setMaxFiles = useStore(store, (s) => s.setMaxFiles);
    useEffect(() => {
        setMaxFiles(maxFiles);
    }, [maxFiles]);

    const uploadedFiles = useStore(
        store,
        useShallow((s) => Array.from(s.files.values()).filter(isFileUploaded)),
    );
    useEffect(() => {
        if (onUploadComplete !== undefined && uploadedFiles.length > 0) {
            onUploadComplete(uploadedFiles);
            removeFiles(uploadedFiles.map((f) => f.upload_id));
        }
    }, [onUploadComplete, uploadedFiles, removeFiles]);

    return <FileUploadContext value={store} {...props} />;
};

const ToastLimitReached = (props: { count: number }) => (
    <>
        <p className="font-bold">File limit reached.</p>
        <p>You can upload {plural(props.count, "additional file")}</p>
    </>
);

const ToeastRejected = (props: { rejected: FileRejection[] }) => (
    <div>
        <p className="font-bold">Failed to upload the following files:</p>
        <ul className="list-outside list-disc space-y-1 p-4">
            {props.rejected.map((f) => (
                <li
                    key={
                        f.file.name +
                        String(f.file.lastModified) +
                        String(f.file.size)
                    }
                >
                    <p>{f.file.name}</p>
                    <p className="italic">
                        {getErrorMessage(first(f.errors)?.code)}
                    </p>
                </li>
            ))}
        </ul>
    </div>
);
