import { FindingContentType, GenericFindingContent, UUID } from "@/api/types";
import { CollapsibleMarkdown } from "@/components/document-table/condensed/collapsible-markdown";
import { DetailedFinding } from "@/components/document-table/condensed/detailed-finding";
import { FindingActions } from "@/components/document-table/condensed/finding-actions";
import { BooleanFinding } from "@/components/document-table/condensed/finding-content/boolean";
import { NumericalFinding } from "@/components/document-table/condensed/finding-content/numerical";
import { Badge } from "@/components/ui/badge";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useDevMode } from "@/hooks/use-dev-mode";
import { preventDefault } from "@/utils/dom";
import { capitalize } from "@/utils/string-helpers";

type Props = {
    finding: GenericFindingContent;
    documentID?: UUID;
};

export const CompactFinding = ({ finding, documentID }: Props) => {
    const [devMode] = useDevMode();
    switch (finding.content_type) {
        case FindingContentType.boolean:
            return (
                <BooleanFinding value={finding.value} text={finding.text}>
                    <FindingActions finding={finding} documentID={documentID} />
                </BooleanFinding>
            );
        case FindingContentType.long_text:
            return (
                <CollapsibleMarkdown className="text-sm">
                    {finding.text}
                </CollapsibleMarkdown>
            );
        case FindingContentType.short_text:
            return (
                <p className="inline-block whitespace-nowrap [mask-image:linear-gradient(90deg,#000_95%,transparent)]">
                    {finding.text}
                </p>
            );
        case FindingContentType.full_text:
        case FindingContentType.titled_long_text:
            return (
                <Popover>
                    <PopoverTrigger asChild>
                        <p
                            className="overflow-hidden rounded border border-transparent px-1 py-px whitespace-nowrap transition-colors [mask-image:linear-gradient(90deg,#000_95%,transparent)] hover:border-slate-300 hover:bg-slate-200 hover:[mask-image:none] dark:hover:border-slate-600 dark:hover:bg-slate-700"
                            role="button"
                        >
                            <span className="font-headline mr-2 font-semibold">
                                {finding.tag ?? finding.title}
                            </span>
                            <span className="text-foreground/50 text-xs">
                                {finding.text}
                            </span>
                        </p>
                    </PopoverTrigger>
                    <PopoverContent
                        className="w-[35vw] space-y-2 overflow-y-scroll p-2"
                        style={{
                            maxHeight: "var(--radix-popper-available-height)",
                        }}
                        collisionPadding={5}
                        onOpenAutoFocus={preventDefault}
                        data-bw-actions
                    >
                        <DetailedFinding finding={finding} />
                        <FindingActions
                            finding={finding}
                            documentID={documentID}
                        />
                    </PopoverContent>
                </Popover>
            );
        case FindingContentType.titled_list:
            return (
                <Popover>
                    <PopoverTrigger asChild>
                        <p
                            className="overflow-hidden rounded border border-transparent px-1 py-px whitespace-nowrap transition-colors [mask-image:linear-gradient(90deg,#000_95%,transparent)] hover:border-slate-300 hover:bg-slate-200 hover:[mask-image:none] dark:hover:border-slate-600 dark:hover:bg-slate-700"
                            role="button"
                        >
                            <span className="font-headline mr-1 font-semibold">
                                {capitalize(finding.title)}{" "}
                            </span>
                            <span className="text-foreground/50 text-xs">
                                {finding.key_points.join("\n\n")}
                            </span>
                        </p>
                    </PopoverTrigger>
                    <PopoverContent
                        className="w-[35vw] space-y-2 overflow-y-scroll p-2"
                        style={{
                            maxHeight: "var(--radix-popper-available-height)",
                        }}
                        collisionPadding={5}
                        onOpenAutoFocus={preventDefault}
                        data-bw-actions
                    >
                        <DetailedFinding finding={finding} />
                        <FindingActions
                            finding={finding}
                            documentID={documentID}
                        />
                    </PopoverContent>
                </Popover>
            );
        case FindingContentType.list_item:
            return (
                <li className="flex grow items-baseline gap-1.5">
                    <div className="bg-foreground/35 h-[2px] w-2 shrink-0 -translate-y-1 rounded-full" />
                    <span>{finding.text}</span>
                </li>
            );
        case FindingContentType.numerical:
            return (
                <div>
                    <NumericalFinding
                        finding={finding}
                        documentID={documentID}
                    />
                </div>
            );
        case FindingContentType.numerical_full_text:
        case FindingContentType.unstructured:
            return devMode ? (
                <Badge variant="destructive">
                    unsupported type: {finding.content_type}
                </Badge>
            ) : null;
        default:
            if (devMode) {
                return (
                    <pre className="bg-muted rounded p-2 text-[9px]">
                        {JSON.stringify(finding, null, 2)}
                    </pre>
                );
            }
            return finding satisfies never;
    }
};
