import { Table } from "@tanstack/react-table";
import { ReactNode } from "react";

import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Slider } from "@/components/ui/slider";
import { getNumericalFilterRange, Range } from "@/utils/filter";
import { numberToString } from "@/utils/math";

type Props = {
    columnId: string;
    title: string;
    icon?: ReactNode;
    table: Table<DocumentWithAsyncFindings>;
};

export const NumericalColumnFilter = ({ table, ...props }: Props) => {
    const column = table.getColumn(props.columnId);
    const range = getNumericalFilterRange(props.columnId, column);
    const clearFilter = () => column?.setFilterValue(undefined);
    const handleChange = ([from, to]: number[]) =>
        column?.setFilterValue({ from, to });
    const filterValue = column?.getFilterValue() as Range<number> | undefined;

    const filterBadge =
        filterValue !== undefined ? (
            <span className="bg-muted group-hover:bg-background rounded-full px-2.5 py-1.5 text-xs transition-colors empty:hidden">
                {numberToString(filterValue.from)}
                {" – "}
                {numberToString(filterValue.to)}
            </span>
        ) : undefined;

    // no fitler values or only one value found -> can't filter by this
    if (range === undefined) {
        return (
            <FilterPopover {...props} onClear={clearFilter}>
                <p className="p-2 text-center text-sm italic">
                    Column does not contain values to filter
                </p>
            </FilterPopover>
        );
    }

    const value = [
        filterValue?.from ?? range.from,
        filterValue?.to ?? range.to,
    ];

    return (
        <FilterPopover
            {...props}
            onClear={clearFilter}
            filterValue={filterBadge}
        >
            <div className="p-4">
                <Slider
                    value={value}
                    defaultValue={[range.from, range.to]}
                    min={range.from}
                    max={range.to}
                    onValueChange={handleChange}
                />
            </div>
            <div className="flex items-center justify-between gap-4 p-2 text-sm">
                <p>{numberToString(value[0])}</p>
                <p>{numberToString(value[1])}</p>
            </div>
        </FilterPopover>
    );
};
