import { Table } from "@tanstack/react-table";
import { ListFilter } from "lucide-react";

import { AddFilter } from "@/components/document-table/filter/add-filter";
import { useFilter } from "@/components/document-table/filter/use-filter";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";

type Props = {
    table: Table<DocumentWithAsyncFindings>;
};

export const FilterTrigger = (props: Props) => {
    const isOpen = useFilter((s) => s.isOpen);
    const toggle = useFilter((s) => s.toggle);
    const filter = useGridView((s) => s.columnFilters);

    if (!isOpen && filter.length === 0) {
        return (
            <AddFilter {...props} onFilterAdd={() => toggle(true)}>
                <Button variant="outline" className="gap-2 rounded-full">
                    <ListFilter className="size-4" />
                    Filter
                </Button>
            </AddFilter>
        );
    }

    return (
        <Button
            variant="outline"
            className={cn(
                "gap-2 rounded-full",
                isOpen && "bg-muted/50",
                filter.length > 0 && "pr-2",
            )}
            onClick={() => toggle()}
        >
            <ListFilter className="size-4" />
            Filter
            {filter.length > 0 && (
                <Badge variant="primary">{filter.length}</Badge>
            )}
        </Button>
    );
};
