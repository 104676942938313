import { ChevronRight } from "lucide-react";
import { HTMLAttributes } from "react";

import { APActionBase } from "@/api/types";
import { ActionHeader } from "@/components/action-message/action-header";
import { MessageCard } from "@/components/action-message/message-card";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { useActionsContext } from "@/hooks/use-actions-context";

interface Props extends HTMLAttributes<HTMLDivElement> {
    action: APActionBase;
}

export const CollapsedActionMessage = ({ action, ...props }: Props) => {
    const expand = useActionsContext((s) => s.expandAction);
    return (
        <MessageCard
            {...props}
            className="group hover:bg-background/70 cursor-pointer p-2"
            onClick={() => expand(action.id)}
        >
            <ActionHeader
                action={action}
                icon={
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon-sm"
                        className="shrink-0"
                        tooltip="Expand"
                    >
                        <ChevronRight className="size-4 transition-transform group-hover:rotate-90" />
                    </ButtonWithTooltip>
                }
            >
                <p className="font-headline text-muted-foreground line-clamp-1 grow px-2 text-sm font-bold">
                    {action.content}
                </p>
            </ActionHeader>
        </MessageCard>
    );
};
