import { CheckedState } from "@radix-ui/react-checkbox";
import { FileText, FileUp, Globe, LucideProps, Trash } from "lucide-react";
import { MouseEvent, PropsWithChildren, useRef } from "react";
import { useParams } from "react-router-dom";
import { validate as validUUID } from "uuid";

import {
    ContextItem,
    ContextType,
    DocumentInfo,
    DocumentType as TDocumentType,
    UploadFile,
    UUID,
} from "@/api/types";
import { DocumentSearch } from "@/components/analyze/document-search";
import { Dropzone } from "@/components/analyze/dropzone";
import { UploadToast } from "@/components/analyze/upload-toast";
import { AsyncButton } from "@/components/async-button";
import { CompanyLogo } from "@/components/company-logo";
import { Headline } from "@/components/headline";
import { NotFound } from "@/components/not-found";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { FeatureFlagNumber } from "@/conf/feature-flags";
import { MAX_DOCUMENT_COUNT } from "@/conf/report";
import { ProjectContainer } from "@/container/project";
import { FileUploadContextProvider } from "@/context/file-upload-context-provider";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useFeatureFlagNumber } from "@/hooks/use-feature-flag";
import { useFileUploadContext } from "@/hooks/use-file-upload-context";
import { useProject } from "@/hooks/use-project";
import { useSet } from "@/hooks/use-set";
import { cn } from "@/lib/utils";
import { getInbetween } from "@/utils/collection";
import { encodeContextItem } from "@/utils/context-items";
import { wrapStopPropagation } from "@/utils/dom";
import { isFileUploaded } from "@/utils/file-upload";
import { getReportTitle } from "@/utils/report";
import { plural } from "@/utils/string-helpers";

export const DataRoomBreadcrumb = (props: { id: UUID }) => {
    return <ProjectContainer id={props.id}>{getReportTitle}</ProjectContainer>;
};

const DocumentType = ({
    info,
    ...props
}: { info: DocumentInfo } & LucideProps) => {
    switch (info.doc_type) {
        case TDocumentType.website:
            return <Globe {...props} />;
        case TDocumentType.pdf:
        case TDocumentType.text:
            return <FileText {...props} />;
        case TDocumentType.sec_filing:
        case TDocumentType.earnings_transcript:
        case TDocumentType.quartr_transcript:
            return (
                <CompanyLogo ticker={info.ticker} className={props.className} />
            );
    }
};

const DataRoom = () => {
    const { project, mutate } = useProject();
    const maxFiles = useFileUploadContext((s) => s.maxFiles);
    const { open: fileDialogOpen } = useFileUploadContext((s) => s.dropzone);

    const documents = project.documents.sort((a, b) =>
        a.info.title.localeCompare(b.info.title),
    );

    const api = useApi();
    const deleteFileAction = useAsyncState(
        async (id: UUID) => await api.report_remove_document(project.id, id),
        { onSuccess: () => mutate() },
    );
    const addFiles = useAsyncState(
        async (items: ContextItem[]) =>
            await api.report_add_documents(
                project.id,
                items.map(encodeContextItem),
            ),
        { onSuccess: () => mutate() },
    );

    const [selected, selectedActions] = useSet<string>();

    const checkedState: CheckedState =
        selected.size === 0
            ? false
            : selected.size === project.documents.length
              ? true
              : "indeterminate";

    const handleAllCheckedChange = () => {
        if (checkedState === true) {
            selectedActions.clear();
        } else {
            selectedActions.addAll(documents.map((d) => d.info.id));
        }
    };
    const lastSelected = useRef<UUID | null>(null);

    const getClickHandler = (id: UUID) => (e: MouseEvent) => {
        e.preventDefault();
        const isSelected = selected.has(id);
        if (!isSelected) {
            if (e.shiftKey) {
                if (lastSelected.current !== null) {
                    selectedActions.addAll(
                        getInbetween(
                            documents.map((d) => d.info.id),
                            lastSelected.current,
                            id,
                        ),
                    );
                }
            }

            lastSelected.current = id;
        }

        selectedActions.toggle(id, !isSelected);
    };

    const batchDeleteAction = useAsyncState(
        async () =>
            await Promise.allSettled(
                Array.from(selected).map((id) =>
                    api.report_remove_document(project.id, id),
                ),
            ),
        {
            onSuccess: () => {
                selectedActions.clear();
                mutate();
            },
        },
    );

    const maxDocumentCount = useFileUploadContext((s) => s.maxFiles);
    const files = useFileUploadContext((s) => s.files);
    const resetFiles = useFileUploadContext((s) => s.reset);

    return (
        <div className="relative -mt-14 flex h-screen grow flex-col pt-14">
            <div className="container flex items-end justify-between gap-2 py-10">
                <Headline level={3} highlighted>
                    Data Room
                </Headline>
                <div className="flex items-center gap-2">
                    <Button
                        className="gap-2"
                        onClick={fileDialogOpen}
                        disabled={maxFiles === 0}
                    >
                        <FileUp className="size-4" />
                        Upload Documents
                    </Button>
                    <DocumentSearch
                        addedDocumentIDs={
                            new Set(
                                documents
                                    .map((d) => d.info)
                                    .filter(
                                        (d) =>
                                            d.doc_type ===
                                                TDocumentType.sec_filing ||
                                            d.doc_type ===
                                                TDocumentType.earnings_transcript,
                                    )
                                    .map((d) => d.unique_id),
                            )
                        }
                        addItems={addFiles.submit}
                        placeholder="Search for additional companies and documents"
                        maxDocumentCount={maxDocumentCount - documents.length}
                    />
                </div>
            </div>
            <Table containerClassName="container grow border rounded p-0 mb-4 select-none bg-muted [&_tr]:bg-background">
                <TableHeader className="bg-background sticky top-0 [&_tr]:border-b-0">
                    <TableRow
                        style={{
                            boxShadow: "0 -1px 1px 0 hsl(var(--border)) inset",
                        }}
                    >
                        <TableHead className="w-5">
                            <Checkbox
                                className="block"
                                checked={checkedState}
                                onCheckedChange={handleAllCheckedChange}
                            />
                        </TableHead>
                        <TableHead>
                            {documents.length > 0
                                ? plural(documents.length, "Document")
                                : "Documents"}
                        </TableHead>
                        <TableHead className="flex items-center justify-end gap-2 px-2">
                            {selected.size > 0 && (
                                <>
                                    <AsyncButton
                                        variant="destructive"
                                        size="sm"
                                        action={batchDeleteAction}
                                    >
                                        <Trash className="mr-2 size-4" />
                                        Delete{" "}
                                        {plural(selected.size, "Document")}
                                    </AsyncButton>
                                    <Button
                                        onClick={selectedActions.clear}
                                        size="sm"
                                        variant="link"
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )}
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {documents.map((doc) => (
                        <TableRow
                            key={doc.info.id}
                            className={cn(
                                "group",
                                selected.has(doc.info.id) &&
                                    "bg-slate-50 dark:bg-slate-900",
                            )}
                            onClick={getClickHandler(doc.info.id)}
                        >
                            <TableCell className="py-2">
                                <Checkbox
                                    className="block"
                                    checked={selected.has(doc.info.id)}
                                />
                            </TableCell>
                            <TableCell className="flex items-center gap-2 py-2">
                                <div className="bg-muted inline-block rounded p-1">
                                    <DocumentType
                                        info={doc.info}
                                        className="size-4"
                                    />
                                </div>
                                <span className="font-medium">
                                    {doc.info.title}
                                </span>
                            </TableCell>
                            <TableCell className="py-2 text-right">
                                <AsyncButton
                                    variant="ghost-destructive"
                                    size="icon-xs"
                                    className="opacity-0 transition-opacity group-hover:opacity-100 group-hover:delay-75"
                                    onClick={wrapStopPropagation(
                                        deleteFileAction.getEventHandler(
                                            doc.info.id,
                                        ),
                                    )}
                                    loading={deleteFileAction.isSubmitting}
                                >
                                    <Trash className="size-3.5" />
                                </AsyncButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    {documents.length === 0 && (
                        <TableRow>
                            <TableCell
                                colSpan={3}
                                className="bg-muted text-center italic"
                            >
                                No documents yet
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Dropzone />
            <UploadToast
                files={Array.from(files.values())}
                onDismiss={resetFiles}
            />
        </div>
    );
};

export const V3DataRoomRoute = () => {
    const { id } = useParams<{ id: UUID }>();
    if (!id || !validUUID(id)) {
        return <NotFound />;
    }

    return (
        <ProjectContainer id={id}>
            <FileUploadWrapper>
                <DataRoom />
            </FileUploadWrapper>
        </ProjectContainer>
    );
};

const FileUploadWrapper = (props: PropsWithChildren) => {
    const api = useApi();
    const maxDocumentCount = useFeatureFlagNumber(
        FeatureFlagNumber.report_max_documents,
        MAX_DOCUMENT_COUNT,
    );
    const { project, mutate } = useProject();
    const remainingCount = maxDocumentCount - project.documents.length;
    const onUploadComplete = async (files: UploadFile[]) => {
        await api.report_add_documents(
            project.id,
            files.filter(isFileUploaded).map((f) => ({
                type: ContextType.EXISTING_DOCUMENT,
                document_id: f.document_info.id,
            })),
        );
        await mutate();
    };
    return (
        <FileUploadContextProvider
            maxFiles={remainingCount}
            onUploadComplete={onUploadComplete}
        >
            {props.children}
        </FileUploadContextProvider>
    );
};
