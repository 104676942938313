/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    DndContext,
    MouseSensor,
    TouchSensor,
    closestCenter,
    type DragEndEvent,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    createColumnHelper,
    flexRender,
    RowSelectionState,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    ColumnDef,
    VisibilityState,
    ColumnSizingState,
    ColumnOrderState,
    OnChangeFn,
    getSortedRowModel,
    Row,
    SortingState,
    ColumnPinningState,
    ColumnFiltersState,
} from "@tanstack/react-table";
import { RowData } from "@tanstack/react-table";
import { Header } from "@tanstack/react-table";
import { Cell } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { X } from "lucide-react";
import {
    CSSProperties,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import hash from "stable-hash";
import stableHash from "stable-hash";

import { DocumentInfo, UUID, AsyncFindingGroup } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { ColumnResizeHandle } from "@/components/document-table/column-resize-handle";
import { FilterContent } from "@/components/document-table/filter/content";
import { FilterTrigger } from "@/components/document-table/filter/trigger";
import { Sorting } from "@/components/document-table/sorting";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { StaticColumn } from "@/conf/grid-view";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { useDebouncedValue } from "@/hooks/use-debounced-value";
import { useDevMode } from "@/hooks/use-dev-mode";
import { cn } from "@/lib/utils";
import { stopPropagation } from "@/utils/dom";
import { asyncEmptyFunction } from "@/utils/empty-function";
import { plural } from "@/utils/string-helpers";
import { getColumnStyles } from "@/utils/table";

declare module "@tanstack/react-table" {
    interface ColumnMeta<TData extends RowData, TValue> {
        removePadding?: boolean;
        disableOrdering?: boolean;
    }
    interface TableMeta<TData extends RowData> {
        devMode: boolean;
    }
}

export type DocumentWithAsyncFindings = {
    info: DocumentInfo;
    finding_groups: Map<string, AsyncFindingGroup>;
};

const columnHelper = createColumnHelper<DocumentWithAsyncFindings>();

const getStaticColumns = (enableRowSelection: boolean) =>
    enableRowSelection
        ? [
              columnHelper.display({
                  id: StaticColumn.select,
                  header: ({ table }) => (
                      <Checkbox
                          className="block"
                          checked={
                              table.getIsAllPageRowsSelected() ||
                              (table.getIsSomePageRowsSelected() &&
                                  "indeterminate")
                          }
                          onCheckedChange={(v) =>
                              table.toggleAllRowsSelected(v === true)
                          }
                      />
                  ),
                  cell: ({ row }) => (
                      <label className="flex h-full items-center justify-start pl-2">
                          <Checkbox
                              className="block"
                              checked={row.getIsSelected()}
                              disabled={!row.getCanSelect()}
                              onCheckedChange={(v) =>
                                  row.toggleSelected(v === true)
                              }
                          />
                      </label>
                  ),
                  enableResizing: false,
                  maxSize: 32,
              }),
          ]
        : [];

type Props = {
    data?: DocumentWithAsyncFindings[];
    columns?: ColumnDef<DocumentWithAsyncFindings>[];
    defaultColumnOrder?: string[];
    onDeleteRow?: (ids: UUID[]) => Promise<void>;

    columnPinning?: ColumnPinningState;

    initialColumnSizing?: ColumnSizingState;
    onColumnSizingChange?: (columnSizing: ColumnSizingState) => void;

    columnOrder: ColumnOrderState;
    setColumnOrder: OnChangeFn<ColumnOrderState>;

    columnVisibility?: VisibilityState;

    sorting: SortingState;
    setSorting: OnChangeFn<SortingState>;

    columnFilters: ColumnFiltersState;
    setColumnFilters: OnChangeFn<ColumnFiltersState>;

    disableRowSelection?: boolean;
};

export const PowerTable = ({
    data = [],
    columns = [],
    onDeleteRow = asyncEmptyFunction,
    columnPinning,
    initialColumnSizing = {},
    onColumnSizingChange,
    columnOrder,
    setColumnOrder,
    columnVisibility,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    disableRowSelection = false,
    children,
}: PropsWithChildren<Props>) => {
    // eslint-disable-next-line react-compiler/react-compiler
    "use no memo";
    const [devMode] = useDevMode();
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [columnSizing, setColumnSizing] =
        useState<ColumnSizingState>(initialColumnSizing);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const tableColumns = useMemo(
        () => [...getStaticColumns(!disableRowSelection), ...columns],
        [columns, disableRowSelection],
    );
    const debouncedColumnSizing = useDebouncedValue(columnSizing);
    useEffect(() => {
        if (
            onColumnSizingChange !== undefined &&
            hash(debouncedColumnSizing) !== hash(initialColumnSizing)
        ) {
            onColumnSizingChange(debouncedColumnSizing);
        }
    }, [onColumnSizingChange, debouncedColumnSizing]);

    useEffect(() => {
        if (stableHash(initialColumnSizing) !== stableHash(columnSizing)) {
            setColumnSizing(initialColumnSizing);
        }
    }, [initialColumnSizing]);

    const table = useReactTable<DocumentWithAsyncFindings>({
        data,
        columns: tableColumns,
        getRowId: (row) => row.info.id,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        enableRowSelection: !disableRowSelection,
        rowCount: data.length,
        state: {
            rowSelection,
            columnVisibility,
            columnSizing,
            columnOrder,
            sorting,
            columnFilters,
        },
        onRowSelectionChange: setRowSelection,
        defaultColumn: {
            size: 400,
            minSize: 80,
            maxSize: Number.MAX_SAFE_INTEGER,
        },
        initialState: {
            columnPinning: {
                left: [StaticColumn.select, ...(columnPinning?.left ?? [])],
                right: columnPinning?.right,
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        onColumnSizingChange: setColumnSizing,
        onColumnOrderChange: setColumnOrder,
        columnResizeMode: "onChange",
        meta: {
            devMode,
        },
    });

    const onCancelRowSelection = useCallback(
        () => setRowSelection({}),
        [setRowSelection],
    );
    const deleteAction = useAsyncState(
        async () => await onDeleteRow(Array.from(Object.keys(rowSelection))),
        { onSuccess: onCancelRowSelection },
    );

    const [isDragActive, dragActiveActions] = useBoolean();
    // reorder columns after drag & drop
    const handleDragEnd = (event: DragEndEvent) => {
        dragActiveActions.close();
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id as string);
                const newIndex = columnOrder.indexOf(over.id as string);
                return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
            });
        }
    };

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
    );

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 150,
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== "undefined" &&
            navigator.userAgent.indexOf("Firefox") === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 7,
    });

    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
            onDragStart={dragActiveActions.open}
        >
            <div className="bg-background space-y-2 border-b p-2">
                <div className="flex gap-3 text-sm">
                    <FilterTrigger table={table} />
                    <Sorting sorting={sorting} onSortingChange={setSorting} />
                </div>
                <FilterContent table={table} />
            </div>
            <Table
                containerRef={tableContainerRef}
                containerClassName={
                    isDragActive ? "overflow-hidden" : undefined
                }
                style={{ minWidth: table.getTotalSize() }}
                className="bg-page-background [&_td]:border-page-background [&_tr]:border-page-background grid"
            >
                <TableHeader className="bg-background sticky top-0 z-20 grid">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id} className="flex w-full">
                            <SortableContext
                                items={columnOrder}
                                strategy={horizontalListSortingStrategy}
                            >
                                {headerGroup.headers.map((header) =>
                                    header.column.columnDef.meta
                                        ?.disableOrdering ||
                                    header.column.getIsPinned() ? (
                                        <NonDraggableTableHeader
                                            key={header.id}
                                            header={header}
                                        />
                                    ) : (
                                        <DraggableTableHeader
                                            key={header.id}
                                            header={header}
                                        />
                                    ),
                                )}
                            </SortableContext>
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody
                    className="relative grid"
                    style={{
                        height:
                            rows.length > 0
                                ? `${rowVirtualizer.getTotalSize()}px`
                                : undefined,
                    }}
                >
                    {rows.length > 0 ? (
                        rowVirtualizer.getVirtualItems().map((virtualRow) => {
                            const row = rows[
                                virtualRow.index
                            ] as Row<DocumentWithAsyncFindings>;
                            return (
                                <tr
                                    data-index={virtualRow.index}
                                    ref={(node) =>
                                        rowVirtualizer.measureElement(node)
                                    }
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && "selected"
                                    }
                                    className={cn(
                                        "group/row [&_td]:bg-background absolute flex w-full border-b bg-transparent transition-colors hover:[&_td]:bg-neutral-50 dark:hover:[&_td]:bg-neutral-900",
                                        row.getIsSelected() &&
                                            "[&_td]:bg-slate-50 hover:[&_td]:bg-slate-100 dark:[&_td]:bg-slate-950 dark:hover:[&_td]:bg-slate-900",
                                    )}
                                    data-action-context={`document:${row.id}`}
                                    style={{
                                        transform: `translateY(${virtualRow.start}px)`,
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <RenderCell key={cell.id} cell={cell} />
                                    ))}
                                </tr>
                            );
                        })
                    ) : (
                        <TableRow className="w-full">
                            <TableCell
                                colSpan={tableColumns.length}
                                className="flex py-10 text-base"
                            >
                                {children}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {Object.keys(rowSelection).length > 0 && (
                <div className="bg-background/60 absolute bottom-4 left-1/2 z-10 flex -translate-x-1/2 items-center gap-2 rounded-lg border py-1.5 pr-1.5 pl-4 text-sm shadow-lg backdrop-blur-sm">
                    <span className="mr-4">
                        {plural(Object.keys(rowSelection).length, "Document")}{" "}
                        selected
                    </span>
                    <AsyncButton
                        variant="destructive"
                        size="sm"
                        action={deleteAction}
                    >
                        Delete
                    </AsyncButton>
                    <ButtonWithTooltip
                        tooltip="Cancel"
                        variant="outline"
                        size="icon-sm"
                        onClick={onCancelRowSelection}
                    >
                        <X className="size-4" />
                    </ButtonWithTooltip>
                </div>
            )}
        </DndContext>
    );
};

const DraggableTableHeader = ({
    header,
}: {
    header: Header<DocumentWithAsyncFindings, unknown>;
}) => {
    // eslint-disable-next-line react-compiler/react-compiler
    "use no memo";
    const { attributes, isDragging, listeners, setNodeRef, transform } =
        useSortable({
            id: header.column.id,
        });

    const style: CSSProperties = {
        ...getColumnStyles(header.column),
        position: "relative",
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: "width transform 0.2s ease-in-out",
        whiteSpace: "nowrap",
        width: header.column.getSize(),
        zIndex: isDragging ? 1 : 0,
        background: isDragging ? "hsl(var(--accent))" : undefined,
    };

    return (
        <TableHead
            ref={setNodeRef}
            className={cn(
                "group bg-background flex pl-2 last:overflow-hidden",
                header.column.getCanResize() && "pr-0",
                header.column.getIsResizing() && "select-none",
            )}
            style={style}
        >
            <div
                className={cn(
                    "relative flex h-full w-full items-center justify-between",
                    header.column.getCanResize() &&
                        "[mask-image:linear-gradient(90deg,#000_90%,transparent)]",
                )}
                {...attributes}
                {...listeners}
            >
                <div
                    onMouseDown={stopPropagation}
                    onTouchStart={stopPropagation}
                >
                    {header.isPlaceholder
                        ? null
                        : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                          )}
                </div>
            </div>
            {header.column.getCanResize() && (
                <ColumnResizeHandle header={header} />
            )}
        </TableHead>
    );
};

const NonDraggableTableHeader = ({
    header,
}: {
    header: Header<DocumentWithAsyncFindings, unknown>;
}) => {
    // eslint-disable-next-line react-compiler/react-compiler
    "use no memo";
    return (
        <TableHead
            className={cn(
                "group bg-background relative flex last:overflow-hidden",
                header.column.getCanResize() && "pr-0",
                header.column.getIsResizing() && "select-none",
            )}
            style={getColumnStyles(header.column)}
        >
            <div className="flex h-full w-full items-center justify-between">
                <div
                    className={cn(
                        "grow",
                        header.column.getCanResize() &&
                            "[mask-image:linear-gradient(90deg,#000_95%,transparent)]",
                    )}
                >
                    {header.isPlaceholder
                        ? null
                        : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                          )}
                </div>
                {header.column.getCanResize() && (
                    <ColumnResizeHandle header={header} />
                )}
            </div>
        </TableHead>
    );
};

const RenderCell = ({
    cell,
}: {
    cell: Cell<DocumentWithAsyncFindings, unknown>;
}) => (
    <TableCell
        className={cn("bg-background relative overflow-hidden p-2", {
            "p-0": cell.column.columnDef.meta?.removePadding,
        })}
        style={getColumnStyles(cell.column)}
    >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </TableCell>
);
