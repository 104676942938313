import { h } from "hastscript";
import type { Root, Parent, Node, PhrasingContent } from "mdast";
import { visit } from "unist-util-visit";

import { CitationData, CitationType } from "@/stores/citations";
import { nonNull } from "@/utils/fn";
import { simpleHash } from "@/utils/string-helpers";

interface UnknownTextDirective extends Node {
    type: "textDirective";
    name: string;
}

interface CitationTextDirective extends Parent {
    type: "textDirective";
    name: "cit";
    children: PhrasingContent[];
    attributes: {
        citation_id?: string;
        finding_id?: string;
    };
}

type CustomTextDirectives = CitationTextDirective | UnknownTextDirective;

const isCitation = (
    node: CustomTextDirectives,
): node is CitationTextDirective =>
    node.type === "textDirective" && node.name == "cit";

const parseString = (s: string | undefined | null): string | undefined =>
    nonNull(s) ? JSON.parse(`"${s}"`) : undefined;

const getCitationData = (node: CitationTextDirective): CitationData => {
    const { finding_id, citation_id } = node.attributes;
    if (citation_id !== undefined) {
        return {
            type: CitationType.citation,
            citation_id,
        };
    }
    if (finding_id !== undefined) {
        return {
            type: CitationType.finding,
            finding_id,
        };
    }
    return { type: CitationType.invalid };
};

export const customDirectives = () => (tree: Root) =>
    visit(tree, (node): void => {
        if (node.type === "leafDirective" && node.name === "action") {
            const data = node.data || (node.data = {});
            const hast = h(node.name, {
                type: node.attributes?.type,
                message: parseString(node.attributes?.message),
                selected_text: parseString(node.attributes?.selected_text),
                response_to: node.attributes?.response_to,
            });

            data.hName = hast.tagName;
            data.hProperties = hast.properties;
        } else if (node.type === "textDirective") {
            if (isCitation(node)) {
                const data = node.data || (node.data = {});
                const hast = h(node.name, {
                    id: simpleHash(
                        [
                            node.position?.start.offset,
                            node.position?.end.offset,
                            node.attributes?.citation_id,
                        ].join("_"),
                    ),
                    ...getCitationData(node),
                });

                data.hName = hast.tagName;
                data.hProperties = hast.properties;
            } else {
                // render unreconized directives as regular text
                const data = node.data || (node.data = {});
                const hast = h("span", `:${node.name}`);
                data.hName = hast.tagName;
                data.hProperties = hast.properties;
                data.hChildren = hast.children;
            }
        }
    });
