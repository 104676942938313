import { Entity } from "@/api/types";

// Helper fns for immutable ES6 map handling

export const mapDelete = <Tk, Tv>(map: Map<Tk, Tv>, key: Tk): Map<Tk, Tv> => {
    if (!map.has(key)) return map;
    const result = new Map(map);
    result.delete(key);
    return result;
};

export const mapDeleteAll = <Tk, Tv>(
    map: Map<Tk, Tv>,
    keys: Iterable<Tk> | undefined,
): Map<Tk, Tv> => {
    if (keys == undefined) return map;
    const result = new Map(map);
    for (const key of keys) {
        result.delete(key);
    }
    return result;
};

export const mapSet = <Tk, Tv>(
    map: Map<Tk, Tv>,
    key: Tk,
    value: Tv,
): Map<Tk, Tv> => new Map(map).set(key, value);

export const mapSetAll = <Tk, Tv>(
    map: Map<Tk, Tv>,
    key_value_pairs: [Tk, Tv][],
): Map<Tk, Tv> => new Map([...map.entries(), ...key_value_pairs]);

export const mapAddEntity = <Tv extends Entity>(
    map: Map<Tv["id"], Tv>,
    value: Tv,
): Map<Tv["id"], Tv> => new Map(map).set(value.id, value);

export const mapApplyChangeset = <Tk, Tv extends object, Tc = Partial<Tv>>(
    map: Map<Tk, Tv>,
    key: Tk,
    changeset: Tc,
    mergeFn: (a: Tv, changeset: Tc) => Tv = (a: Tv, c: Tc): Tv => ({
        ...a,
        ...c,
    }),
): Map<Tk, Tv> => {
    if (!map.has(key)) return map;
    const result = new Map(map);
    result.set(key, mergeFn(map.get(key)!, changeset));
    return result;
};

export const mapApplyChangesets = <Tk, Tv extends object>(
    map: Map<Tk, Tv>,
    changesets: [Tk, Partial<Tv>][],
): Map<Tk, Tv> => {
    if (changesets.length === 0) return map;
    const result = new Map(map);
    for (const changeset of changesets) {
        result.set(changeset[0], {
            ...result.get(changeset[0])!,
            ...changeset[1],
        });
    }
    return result;
};

export const mapFilter = <Tk, Tv>(
    map: Map<Tk, Tv>,
    predicate: (item: Tv) => boolean,
): Map<Tk, Tv> =>
    new Map(Array.from(map.entries()).filter(([, item]) => predicate(item)));

export const mapUpdate = <Tk, Tv>(
    map: Map<Tk, Tv>,
    key: Tk,
    updateFn: (oldValue: Tv | undefined) => Tv,
): Map<Tk, Tv> => new Map(map).set(key, updateFn(map.get(key)));

export const mapUpdateIfExists = <Tk, Tv>(
    map: Map<Tk, Tv>,
    key: Tk,
    updateFn: (oldValue: Tv) => Tv,
): Map<Tk, Tv> =>
    map.has(key) ? new Map(map).set(key, updateFn(map.get(key)!)) : map;

export const mapUpdateManyIfExists = <Tk, Tv>(
    map: Map<Tk, Tv>,
    keys: Tk[],
    updateFn: (oldValue: Tv) => Tv,
): Map<Tk, Tv> => {
    if (keys.length === 0) return map;
    const res = new Map(map);
    for (const key of keys) {
        if (res.has(key)) {
            res.set(key, updateFn(res.get(key)!));
        }
    }
    return res;
};

export const mapMerge = <Tk, Tv>(
    a: Map<Tk, Tv>,
    b: Map<Tk, Tv>,
    mergeFn: (a: Tv, b: Tv) => Tv,
): Map<Tk, Tv> => {
    const res = new Map(a);
    for (const [key, val] of b.entries()) {
        res.set(key, a.has(key) ? mergeFn(a.get(key)!, val) : val);
    }
    return res;
};

export const mapMaybeGet = <Tk, Tv>(
    map: Map<Tk, Tv>,
    key: Tk | undefined | null,
): Tv | undefined => {
    if (!key) return undefined;
    return map.get(key);
};

export const mapMap = <Tk, Tin, Tout>(
    map: Map<Tk, Tin>,
    functor: (item: Tin, key: Tk) => Tout,
): Map<Tk, Tout> =>
    new Map(
        Array.from(map.entries()).map(([key, val]) => [key, functor(val, key)]),
    );

export const mapPull = <Tk, Tin, Tout>(
    arr: Tin[],
    keyFn: (item: Tin, index: number) => Tk,
    valueFn: (item: Tin, index: number) => Tout,
): Map<Tk, Tout[]> =>
    arr.reduce((acc: Map<Tk, Tout[]>, item: Tin, index: number) => {
        const key = keyFn(item, index);
        const value = valueFn(item, index);
        return acc.set(key, [...(acc.get(key) ?? []), value]);
    }, new Map<Tk, Tout[]>());

export const mapPullKey = <Tk, Tv>(
    arr: Tv[],
    keyFn: (item: Tv, index: number) => Tk,
): Map<Tk, Tv> =>
    arr.reduce(
        (acc: Map<Tk, Tv>, item: Tv, index: number) =>
            acc.set(keyFn(item, index), item),
        new Map(),
    );
