import { zodResolver } from "@hookform/resolvers/zod";
import { formatISO, parseISO } from "date-fns";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
    Company,
    DocumentType,
    Exchange,
    TimeframeType,
    UUID,
} from "@/api/types";
import { FiscalQuarterSelect } from "@/components/action-message/fiscal-quarter-select";
import { CompanyMultiSelect } from "@/components/company-multiselect";
import { DatePicker } from "@/components/datepicker";
import { MultiSelectCheckbox } from "@/components/multiselect-checkbox";
import { Button } from "@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";

const parseDate = (val: string | undefined): Date | undefined =>
    val != undefined ? parseISO(val) : undefined;

const fiscalQuarterToFloat = (val: string): number =>
    val
        .split("-Q")
        .map((v, i) => parseInt(v, 10) / Math.max(1, i * 10))
        .reduce((a, b) => a + b, 0);

const formSchema = z
    .object({
        companies: z
            .array(
                z.object({
                    symbol: z.string(),
                    name: z.string(),
                    exchange: z.nativeEnum(Exchange),
                }),
            )
            .nonempty("Select at least one company"),
        documentTypes: z
            .array(z.nativeEnum(DocumentType))
            .nonempty("Select at least one document type"),
        timeframeType: z.nativeEnum(TimeframeType),
        timeframeRanges: z.map(
            z.nativeEnum(TimeframeType),
            z.object({
                start: z.string().optional(),
                end: z.string().optional(),
            }),
        ),
    })
    .superRefine(({ timeframeType, timeframeRanges }, ctx) => {
        const range = timeframeRanges.get(timeframeType);
        if (range?.start == undefined) {
            ctx.addIssue({
                path: ["timeframeRanges"],
                code: z.ZodIssueCode.custom,
                message: "Please select a start date",
            });
        }
        if (range?.end == undefined) {
            ctx.addIssue({
                path: ["timeframeRanges"],
                code: z.ZodIssueCode.custom,
                message: "Please select an end date",
            });
        }
        if (range?.start !== undefined && range?.end !== undefined) {
            // check that start is before end
            if (
                timeframeType === TimeframeType.fiscal &&
                fiscalQuarterToFloat(range!.start) >
                    fiscalQuarterToFloat(range!.end)
            ) {
                ctx.addIssue({
                    path: ["timeframeRanges"],
                    code: z.ZodIssueCode.custom,
                    message: "Start date needs to be before end date",
                });
            } else if (parseISO(range!.start) >= parseISO(range!.end)) {
                ctx.addIssue({
                    path: ["timeframeRanges"],
                    code: z.ZodIssueCode.custom,
                    message: "Start date needs to be before end date",
                });
            }
        }
    });

type FormSchema = z.infer<typeof formSchema>;

const DOCUMENT_TYPE_OPTIONS = [
    {
        label: "SEC Filings",
        value: DocumentType.sec_filing,
    },
    {
        label: "Earnings Call Transcripts",
        value: DocumentType.earnings_transcript,
    },
];

type Props = {
    action_id: UUID;
    defaultCompanies?: Company[];
    timeframe?: {
        type: TimeframeType;
        start: string;
        end: string;
    };
};

export const IntentConfirmationForm = (props: Props) => {
    const form = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            companies: props.defaultCompanies,
            documentTypes: [
                DocumentType.sec_filing,
                DocumentType.earnings_transcript,
            ],
            timeframeType: props.timeframe?.type ?? TimeframeType.calendar,
            timeframeRanges:
                props.timeframe != undefined
                    ? new Map([
                          [
                              props.timeframe!.type,
                              {
                                  start: props.timeframe!.start,
                                  end: props.timeframe!.end,
                              },
                          ],
                      ])
                    : undefined,
        },
    });

    const api = useApi();
    const action = useAsyncState(async (data: FormSchema) => {
        const timeframe = data.timeframeRanges.get(data.timeframeType);
        await api.confirm_search_action({
            action_id: props.action_id,
            tickers: data.companies.map((c) => c.symbol),
            document_types: data.documentTypes.flatMap((s) => {
                switch (s) {
                    case DocumentType.website:
                    case DocumentType.pdf:
                    case DocumentType.text:
                    case DocumentType.spreadsheet:
                        return [];
                    case DocumentType.sec_filing:
                        return ["sec_10k", "sec_10q", "sec_8k"];
                    case DocumentType.earnings_transcript:
                        return ["earnings_transcript"];
                    case DocumentType.quartr_transcript:
                        return ["quartr_transcript"];
                }
            }),
            timeframe_type: data.timeframeType,
            start_date: timeframe!.start!,
            end_date: timeframe!.end!,
        });
    });
    const submitHandler = form.handleSubmit(action.submit);

    const timeframeType = form.watch("timeframeType");

    return (
        <Form {...form}>
            <form onSubmit={submitHandler} className="space-y-4">
                <FormField
                    control={form.control}
                    name="companies"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Companies</FormLabel>
                            <FormControl>
                                <CompanyMultiSelect
                                    placeholder="Add companies..."
                                    value={field.value}
                                    onValueChange={field.onChange}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="documentTypes"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Document Types</FormLabel>
                            <FormControl>
                                <MultiSelectCheckbox
                                    value={field.value}
                                    onValueChange={field.onChange}
                                    options={DOCUMENT_TYPE_OPTIONS}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="timeframeType"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Timeframe</FormLabel>
                            <FormControl>
                                <Tabs
                                    value={field.value}
                                    onValueChange={field.onChange}
                                >
                                    <TabsList>
                                        <TabsTrigger
                                            value={TimeframeType.calendar}
                                        >
                                            Calendar
                                        </TabsTrigger>
                                        <TabsTrigger
                                            value={TimeframeType.fiscal}
                                        >
                                            Fiscal
                                        </TabsTrigger>
                                    </TabsList>
                                </Tabs>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="timeframeRanges"
                    render={({ field }) => {
                        const range = field.value.get(timeframeType);
                        const getHandler =
                            (key: string) => (val: string | Date) =>
                                field.onChange(
                                    new Map(field.value).set(timeframeType, {
                                        ...range,
                                        [key]:
                                            val instanceof Date
                                                ? formatISO(val, {
                                                      representation: "date",
                                                  })
                                                : val,
                                    }),
                                );
                        return (
                            <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                                <FormItem>
                                    <FormLabel>From</FormLabel>
                                    <FormControl>
                                        {timeframeType ===
                                        TimeframeType.fiscal ? (
                                            <FiscalQuarterSelect
                                                value={range?.start}
                                                onValueChange={getHandler(
                                                    "start",
                                                )}
                                                minYear={2000}
                                            />
                                        ) : (
                                            <DatePicker
                                                value={parseDate(range?.start)}
                                                onValueChange={getHandler(
                                                    "start",
                                                )}
                                                maxDate={new Date()}
                                            />
                                        )}
                                    </FormControl>
                                </FormItem>
                                <FormItem>
                                    <FormLabel>To</FormLabel>
                                    <FormControl>
                                        {timeframeType ===
                                        TimeframeType.fiscal ? (
                                            <FiscalQuarterSelect
                                                value={range?.end}
                                                onValueChange={getHandler(
                                                    "end",
                                                )}
                                                minYear={2000}
                                            />
                                        ) : (
                                            <DatePicker
                                                value={parseDate(range?.end)}
                                                onValueChange={getHandler(
                                                    "end",
                                                )}
                                                maxDate={new Date()}
                                            />
                                        )}
                                    </FormControl>
                                </FormItem>
                                <FormMessage className="col-span-full" />
                            </div>
                        );
                    }}
                />

                <Button variant="primary" className="w-full rounded-full">
                    Comfirm &amp; Answer
                </Button>
            </form>
        </Form>
    );
};
