import {
    useState,
    useLayoutEffect,
    HTMLAttributes,
    ComponentPropsWithRef,
} from "react";

import { useForwardRef } from "@/hooks/use-forward-ref";
import { cn } from "@/lib/utils";
import { findParentWithOverflowHidden } from "@/utils/dom";
import {
    Rect,
    getAdjustedBoundingClientRect,
    fromDOMRect,
    translateY,
    translate,
    rectBottom,
    rectHCenter,
    getInsersection,
    translateX,
    Position,
    rectRight,
} from "@/utils/geometry";

const PADDING = 10;
const ANCHOR_GAP = 4;

const getCSSTransform = (pos: Position): string =>
    `translate3d(${Math.round(pos.left)}px,${Math.round(pos.top)}px,0)`;

const getTransform = (
    anchor: Rect,
    box: Rect | undefined,
    boundingBox: Rect | undefined,
): string => {
    if (!box) return getCSSTransform(translateY(anchor, 4));
    let cBox = translate(box, {
        top: rectBottom(anchor) + ANCHOR_GAP,
        left: rectHCenter(anchor) - box.width / 2,
    });
    if (boundingBox) {
        const intersection = getInsersection(cBox, boundingBox);

        // move box above anchor if intersects at bottom
        if (intersection & 0b00001000 && cBox.top > anchor.height) {
            cBox = translateY(
                cBox,
                -(cBox.height + anchor.height + ANCHOR_GAP * 2),
            );
        }

        // intersects right
        if (intersection & 0b00100000) {
            return getCSSTransform(
                translateX(
                    cBox,
                    rectRight(boundingBox) - rectRight(cBox) - PADDING,
                ),
            );
        }

        // intersects left
        if (intersection & 0b00000010) {
            return getCSSTransform(
                translateX(cBox, boundingBox.left + PADDING - cBox.left),
            );
        }
    }

    return getCSSTransform(cBox);
};

interface Props
    extends HTMLAttributes<HTMLDivElement>,
        ComponentPropsWithRef<"div"> {
    anchor: Rect;
}

export const PopoverPosition = ({
    ref: forwardRef,
    anchor,
    children,
    className,
    style,
    ...props
}: Props) => {
    const ref = useForwardRef(forwardRef);
    const [box, setBox] = useState<Rect | undefined>();
    const [boundingBox, setBoundingBox] = useState<Rect | undefined>();

    const updateBoxes = () => {
        const el = ref.current;
        if (el) {
            setBox(getAdjustedBoundingClientRect(el));
            const parentEl = findParentWithOverflowHidden(ref.current);
            if (parentEl) {
                setBoundingBox(fromDOMRect(parentEl.getBoundingClientRect()));
            }
        }
    };

    useLayoutEffect(() => {
        updateBoxes();
        if (ref.current) {
            const mutationObserver = new MutationObserver(updateBoxes);
            mutationObserver.observe(ref.current, {
                childList: true,
                subtree: true,
            });
            return () => mutationObserver.disconnect();
        }
    }, [updateBoxes]);

    return (
        <div
            ref={ref}
            className={cn("absolute top-0 left-0", className)}
            style={{
                ...style,
                transform: getTransform(anchor, box, boundingBox),
                zIndex: 9999,
            }}
            {...props}
        >
            {children}
        </div>
    );
};
