import { ArrowRight, MessageCircleQuestion, Table2, Trash } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import useSWRInfinite from "swr/infinite";

import {
    DocumentCollection,
    DocumentInfo,
    DocumentType,
    EarningsTranscriptDocumentInfo,
    SECFilingDocumentInfo,
    UUID,
} from "@/api/types";
import { ButtonWithConfirmation } from "@/components/admin/button-with-confirmation";
import { CompanyLogo } from "@/components/company-logo";
import { DebugID } from "@/components/debug/debug-id";
import { ErrorAlert } from "@/components/error-alert";
import { Headline } from "@/components/headline";
import { HoverTooltip } from "@/components/hover-tooltip";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { ArcadeOnboarding } from "@/components/nux/arcade-onboarding";
import { BrightwaveOnboarding } from "@/components/nux/brightwave-onboarding";
import { DocumentTypePill } from "@/components/report/document-type-pill";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";
import { useUser } from "@/hooks/use-user";
import { cn } from "@/lib/utils";
import { arr_partition, first, last, unique } from "@/utils/collection";
import { mapUpdate } from "@/utils/es6-map";
import { bind } from "@/utils/fn";
import { getCursorKeyFn } from "@/utils/pagination";
import { getReportTitle } from "@/utils/report";
import { formatRelativeDateTime } from "@/utils/time";
import { getGreeting, isUserSuperAdmin } from "@/utils/user";

const PAGE_SIZE = 25;

export const HistoryContainer = () => {
    usePageTitle("Dashboard");
    const user = useUser();
    const api = useApi();
    const navigate = useNavigate();

    const { data, error, isLoading, isValidating, setSize, mutate } =
        useSWRInfinite(
            getCursorKeyFn(PAGE_SIZE, "analyze_history"),
            async ({ pagination }) => await api.fetch_report_list(pagination),
            { revalidateOnMount: true },
        );
    const showNullState = isLoading === false && first(data ?? [])?.count === 0;

    const onDelete = (report_id: UUID) => {
        mutate((data) => {
            if (!data) return data;
            return data.map((page) => ({
                ...page,
                items: page.items.filter((report) => report.id !== report_id),
            }));
        });
    };

    return (
        <>
            <BrightwaveOnboarding />
            <div className="bg-foreground dark:bg-neutral-700">
                <div className="container flex gap-4">
                    <div className="text-background dark:text-foreground flex-1 space-y-10 pt-24 pb-32">
                        <p>{getGreeting(user)}</p>
                        <Headline>
                            Insight at
                            <br />
                            your fingertips
                        </Headline>
                    </div>
                    <div className="bg-logo-white-adaptive bg-100 flex flex-1 flex-col items-end justify-end gap-10 bg-left-bottom bg-no-repeat pt-24 pb-32">
                        {isUserSuperAdmin(user) && (
                            <Button
                                onClick={() => navigate("/explore")}
                                variant="secondary"
                                className="gap-2 rounded-full pr-3 pl-5"
                            >
                                <MessageCircleQuestion className="mr-2 size-4" />
                                Start by asking a question
                                <Badge variant="destructive">INTERNAL</Badge>
                            </Button>
                        )}
                        <Button
                            variant="primary"
                            size="lg"
                            className="rounded-full"
                            asChild
                        >
                            <Link to="/analyze">
                                <Table2 className="mr-2 size-4" />
                                New Project
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    "space-y-10 p-10",
                    showNullState
                        ? "container"
                        : "mx-auto max-w-(--breakpoint-lg)",
                )}
            >
                <div className="flex items-center justify-between gap-10">
                    <Headline level={2} highlighted>
                        {showNullState ? "Explore Brightwave" : "Projects"}
                    </Headline>
                    {!isLoading && !showNullState ? (
                        <Button variant="outline" asChild>
                            <Link
                                to="https://app.arcade.software/share/srl49u5UrFY3MHqNW1Ed?ref=share-link"
                                target="_blank"
                            >
                                Watch Interactive Demo
                                <ArrowRight className="ml-1.5 size-4" />
                            </Link>
                        </Button>
                    ) : null}
                </div>
                <InfiniteScroll
                    className="space-y-4"
                    lookahead="10%"
                    isLoading={isValidating && !isLoading}
                    hasNextPage={
                        data != null &&
                        last(data)?.page_info.has_next_page === true
                    }
                    onNextPage={() => setSize((size) => size + 1)}
                >
                    {data
                        ?.flatMap((p) => p.items)
                        .map((report) => (
                            <AnalyzeReport
                                key={report.id}
                                report={report}
                                onDelete={bind(onDelete, report.id)}
                            />
                        ))}
                    {showNullState && <ArcadeOnboarding />}
                    {error && (
                        <ErrorAlert error={error} message="Failed to load" />
                    )}
                </InfiniteScroll>
            </div>
        </>
    );
};

const AnalyzeReport = ({
    report,
    onDelete,
}: {
    report: DocumentCollection;
    onDelete?: () => void;
}) => {
    const api = useApi();
    const docTypes = report.documents
        .map((d) => d.info.doc_type)
        .reduce(
            (map, type) => mapUpdate(map, type, (v = 0) => v + 1),
            new Map<DocumentType, number>(),
        );
    const ticker = unique(
        report.documents
            .map((d) => d.info)
            .filter(
                (
                    d: DocumentInfo,
                ): d is
                    | SECFilingDocumentInfo
                    | EarningsTranscriptDocumentInfo =>
                    d.doc_type === DocumentType.sec_filing ||
                    d.doc_type === DocumentType.earnings_transcript,
            )
            .map((d) => d.ticker),
    ).sort();
    const deleteAction = useAsyncState(
        async () => await api.delete_report(report.id),
        {
            onSuccess: (result) => {
                if (result) {
                    toast.success("Report deleted");
                    onDelete?.();
                } else {
                    toast.error("Report deletion failed");
                }
            },
        },
    );
    return (
        <div
            className="group bg-background hover:bg-muted relative cursor-pointer rounded-md border p-4 transition-colors"
            role="button"
        >
            <div className="space-y-2.5">
                <DebugID label="ID" id={report.id} />
                <p className="text-muted-foreground text-xs">
                    {formatRelativeDateTime(report.created_at)}
                </p>
                <Headline level={3}>{getReportTitle(report)}</Headline>
                <div className="flex items-center justify-between">
                    <TickerList ticker={ticker} />
                    <div className="grow" />
                    <div className="flex items-center gap-2">
                        {docTypes.size > 0 &&
                            Array.from(docTypes.entries()).map(
                                ([type, count]) => (
                                    <DocumentTypePill
                                        key={type}
                                        type={type as DocumentType}
                                        count={count}
                                        className="text-xs transition-colors group-hover:bg-gray-200 dark:group-hover:bg-gray-700"
                                    />
                                ),
                            )}
                    </div>
                </div>
            </div>
            <Link to={`/analyze/${report.id}`} className="absolute inset-0" />
            <ButtonWithConfirmation
                message="Do you want to delete this analysis. This action can not be undone."
                confirmButtonVariant="destructive"
                variant="ghost-destructive"
                size="icon-sm"
                className="hover:border-destructive/30 absolute top-2 right-2 opacity-0 transition-all group-hover:opacity-100 hover:border"
                action={deleteAction}
            >
                <Trash className="size-4" />
            </ButtonWithConfirmation>
        </div>
    );
};

const TickerList = ({
    ticker,
    maxPills = 4,
}: {
    ticker: string[];
    maxPills?: number;
}) => {
    const [display, overflow] =
        ticker.length > maxPills
            ? arr_partition(ticker, (_, i) => i < maxPills - 1)
            : [ticker, []];
    return (
        <div className="flex items-center gap-2">
            {display.map((ticker) => (
                <p
                    key={ticker}
                    className="bg-muted flex items-center gap-1.5 rounded px-2 py-1 text-xs font-medium transition-colors group-hover:bg-gray-200 dark:group-hover:bg-gray-700"
                >
                    <CompanyLogo key={ticker} ticker={ticker} />
                    <span>{ticker}</span>
                </p>
            ))}
            {overflow.length > 0 && (
                <HoverTooltip
                    tooltip={
                        <ul className="space-y-1 py-1.5">
                            {overflow.map((t) => (
                                <li
                                    key={t}
                                    className="flex items-center gap-2 font-bold"
                                >
                                    <CompanyLogo ticker={t} />
                                    <span>{t}</span>
                                </li>
                            ))}
                        </ul>
                    }
                >
                    <p className="bg-muted flex items-center gap-1.5 rounded px-2 py-1 text-xs font-medium transition-colors group-hover:bg-gray-200 dark:group-hover:bg-gray-700">
                        +{overflow.length} more
                    </p>
                </HoverTooltip>
            )}
        </div>
    );
};
