import { Trash } from "lucide-react";
import { PropsWithChildren, ReactNode, useEffect } from "react";

import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { useFilter } from "@/components/document-table/filter/use-filter";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useBoolean } from "@/hooks/use-boolean";
import { cn } from "@/lib/utils";

type Props = PropsWithChildren<{
    columnId: string;
    title: string;
    icon?: ReactNode;
    filterValue?: ReactNode;
    onClear?: () => void;
    onClose?: () => void;
    className?: string;
}>;

export const FilterPopover = (props: Props) => {
    const forceOpen = useFilter((s) => s.openFilter === props.columnId);
    const clearOpenFilter = useFilter((s) => s.clearOpenFilter);
    const [isOpen, openActions] = useBoolean();

    const handleClose = () => {
        if (forceOpen) {
            clearOpenFilter();
            openActions.close();
        }
    };

    useEffect(() => {
        if (!forceOpen && !isOpen) {
            props.onClose?.();
        }
    }, [forceOpen, isOpen]);

    return (
        <Popover open={forceOpen || isOpen} onOpenChange={openActions.set}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    className={cn(
                        "group gap-2 rounded-full",
                        props.filterValue !== undefined && "pr-1.5",
                    )}
                >
                    {props.icon}
                    <span className="font-medium">{props.title}</span>
                    {props.filterValue}
                </Button>
            </PopoverTrigger>
            <PopoverContent
                align="start"
                className={cn("p-0", props.className)}
                onPointerDownOutside={handleClose}
            >
                <div className="flex items-center justify-between p-2">
                    <p className="px-2 text-sm font-semibold">Filter By</p>
                    {props.onClear && (
                        <ButtonWithTooltip
                            tooltip="Remove Filter"
                            variant="ghost-destructive"
                            size="icon-sm"
                            onClick={props.onClear}
                        >
                            <Trash className="size-4" />
                        </ButtonWithTooltip>
                    )}
                </div>
                {props.children}
            </PopoverContent>
        </Popover>
    );
};
