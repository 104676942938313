import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const MessageCard = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn("bg-background space-y-4 rounded p-4", className)}
        {...props}
    />
);
