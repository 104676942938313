import { createContext } from "react";
import { KeyedMutator } from "swr";

import { FullDocumentCollection } from "@/api/types";

export type TProjectContext = {
    project: FullDocumentCollection;
    mutate: KeyedMutator<FullDocumentCollection>;
};

export const ProjectContext = createContext<{
    project: FullDocumentCollection;
    mutate: KeyedMutator<FullDocumentCollection>;
} | null>(null);
