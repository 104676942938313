import { Check, Loader2, X } from "lucide-react";
import { HTMLAttributes, MouseEvent, ReactNode, useState } from "react";
import useSWRImmutable from "swr/immutable";

import { Company } from "@/api/types";
import { CompanyLogo } from "@/components/company-logo";
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandLoading,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useApi } from "@/hooks/use-api";
import { useDebouncedValue } from "@/hooks/use-debounced-value";
import { cn } from "@/lib/utils";
import { isEmptyOrNull } from "@/utils/string-helpers";

const getKey = (c: Company) => `${c.symbol}_${c.exchange}`;

interface Props extends HTMLAttributes<HTMLDivElement> {
    value?: Company[];
    onValueChange?: (value: Company[]) => void;
    placeholder?: string;
    leftContent?: ReactNode;
    rightContent?: ReactNode;
}

export const CompanyMultiSelect = ({
    value = [],
    onValueChange,
    placeholder,
    leftContent,
    rightContent,
    className,
    ...props
}: Props) => {
    const valueMap = new Map(value.map((c) => [getKey(c), c]));

    const [query, setQuery] = useState("");
    const debouncedQuery = useDebouncedValue(query, { delay: 150 });
    const api = useApi();
    const { data, isValidating } = useSWRImmutable(
        [debouncedQuery, "companies"],
        async ([query]) => {
            if (isEmptyOrNull(query)) return [];
            return await api.find_companies(query);
        },
        { keepPreviousData: true },
    );

    const getToggleHandler = (company: Company) => () => {
        if (!onValueChange) return;
        const key = getKey(company);
        if (valueMap.has(key)) {
            // remove
            const result = new Map(valueMap);
            result.delete(key);
            onValueChange([...result.values()]);
        } else {
            // add
            const result = new Map(valueMap).set(key, company);
            onValueChange([...result.values()]);
        }
    };

    const getRemoveHandler = (company: Company) => (e: MouseEvent) => {
        if (!onValueChange) return;
        e.stopPropagation();
        const result = new Map(valueMap);
        result.delete(getKey(company));
        onValueChange([...result.values()]);
    };

    const handleOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            setQuery("");
        }
    };

    const companies = (data ?? []).filter((c) => !valueMap.has(getKey(c)));

    return (
        <Popover onOpenChange={handleOpenChange}>
            <PopoverTrigger asChild>
                <div
                    className={cn(
                        "border-input bg-background ring-offset-background focus-within:ring-ring flex min-h-10 w-full flex-wrap items-center gap-2 rounded-md border px-1.5 py-1.5 text-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:outline-hidden",
                        className,
                    )}
                    {...props}
                >
                    {leftContent}
                    {value.map((c) => (
                        <div
                            key={c.symbol}
                            className="bg-muted flex items-center gap-1.5 rounded border pl-1"
                        >
                            <CompanyLogo ticker={c.symbol} />
                            <span className="font-bold">{c.symbol}</span>
                            <Button
                                variant="ghost"
                                size="icon-xs"
                                onClick={getRemoveHandler(c)}
                            >
                                <X className="size-4" />
                            </Button>
                        </div>
                    ))}
                    <span className="text-muted-foreground grow px-1">
                        {placeholder}
                    </span>
                    {rightContent}
                </div>
            </PopoverTrigger>
            <PopoverContent
                className="relative p-0"
                style={{ width: "var(--radix-popover-trigger-width)" }}
            >
                <Command>
                    <CommandInput
                        value={query}
                        onValueChange={setQuery}
                        placeholder="Find companies..."
                        autoFocus
                    />
                    <CommandList>
                        {isValidating && (
                            <CommandLoading className="absolute top-0 right-0 flex h-11 items-center px-2">
                                <Loader2 className="size-4 animate-spin text-gray-500" />
                            </CommandLoading>
                        )}
                        <CommandEmpty>
                            {isValidating ? "Searching..." : "No results."}
                        </CommandEmpty>
                        <CommandGroup heading="Selected">
                            {value.map((company) => (
                                <CommandItem
                                    key={getKey(company)}
                                    value={getKey(company)}
                                    className="flex items-center gap-2"
                                    onSelect={getToggleHandler(company)}
                                >
                                    <Check
                                        className={cn(
                                            "size-4 shrink-0",
                                            !valueMap.has(getKey(company)) &&
                                                "invisible",
                                        )}
                                    />
                                    <CompanyLogo
                                        ticker={company.symbol}
                                        size="xl"
                                    />
                                    <div className="flex w-full flex-wrap justify-between">
                                        <span className="shrink-0 font-bold">
                                            {company.symbol}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {company.exchange}
                                        </span>
                                        <span className="basis-full">
                                            {company.name}
                                        </span>
                                    </div>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                        {companies.length > 0 && (
                            <CommandGroup heading="Search Results">
                                {companies.map((company) => (
                                    <CommandItem
                                        key={getKey(company)}
                                        value={getKey(company)}
                                        className="flex items-center gap-2"
                                        onSelect={getToggleHandler(company)}
                                    >
                                        <Check
                                            className={cn(
                                                "size-4 shrink-0",
                                                !valueMap.has(
                                                    getKey(company),
                                                ) && "invisible",
                                            )}
                                        />
                                        <CompanyLogo
                                            ticker={company.symbol}
                                            size="xl"
                                        />
                                        <div className="flex w-full flex-wrap justify-between">
                                            <span className="shrink-0 font-bold">
                                                {company.symbol}
                                            </span>
                                            <span className="text-xs text-gray-500">
                                                {company.exchange}
                                            </span>
                                            <span className="basis-full">
                                                {company.name}
                                            </span>
                                        </div>
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};
