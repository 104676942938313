import { HTMLAttributes } from "react";

import { APIContextItem, ContextType } from "@/api/types";

export const ContextItemContent = ({
    item,
    ...props
}: { item: APIContextItem } & HTMLAttributes<HTMLDivElement>) => {
    switch (item.data.type) {
        case ContextType.QUARTR_TRANSCRIPT:
            return (
                <div {...props}>
                    <p className="line-clamp-1 font-bold">{item.data.title}</p>
                    <div className="flex items-center gap-3 text-xs">
                        <p className="text-muted-foreground">
                            {item.data.ticker}
                        </p>
                        <p>Investor Meeting</p>
                    </div>
                </div>
            );
        case ContextType.EXTERNAL_SEC_FILING:
            return (
                <div {...props}>
                    <p className="line-clamp-1 font-bold">
                        {item.data.sub_form_type ?? item.data.form_type}
                    </p>
                    <div className="flex items-center gap-3 text-xs">
                        <p className="text-muted-foreground">
                            {item.data.ticker}
                        </p>
                        <p>SEC Filing</p>
                    </div>
                </div>
            );
        case ContextType.EXTERNAL_EARNINGS_TRANSCRIPT:
            return (
                <div {...props}>
                    <p className="line-clamp-1 font-bold">
                        {item.data.year} Q{item.data.quarter}
                    </p>
                    <div className="flex items-center gap-3 text-xs">
                        <p className="text-muted-foreground">
                            {item.data.ticker}
                        </p>
                        <p>Earnings Call</p>
                    </div>
                </div>
            );
        default:
            return <div {...props} />;
    }
};
