import { ExternalLink } from "lucide-react";
import { HTMLAttributes, ReactNode } from "react";

import { DocumentInfo, DocumentType } from "@/api/types";
import { cn } from "@/lib/utils";
import { stopPropagation } from "@/utils/dom";
import { getDocumentTypeLabel } from "@/utils/report";
import { urlHostName } from "@/utils/string-helpers";

interface Props extends HTMLAttributes<HTMLElement> {
    info: DocumentInfo;
}

export const ExternalRef = ({ info, ...props }: Props) => {
    switch (info.doc_type) {
        case DocumentType.quartr_transcript:
            return (
                <MaybeLink
                    href={info.external_ref}
                    label={getDocumentTypeLabel(info.doc_type)}
                    {...props}
                />
            );
        case DocumentType.website:
            return (
                <MaybeLink
                    href={info.external_ref}
                    label={
                        info.external_ref
                            ? urlHostName(info.external_ref)
                            : getDocumentTypeLabel(info.doc_type)
                    }
                    {...props}
                />
            );
        case DocumentType.pdf:
        case DocumentType.text:
        case DocumentType.spreadsheet:
            return (
                <MaybeLink
                    href={info.external_ref}
                    label={getDocumentTypeLabel(info.doc_type)}
                    {...props}
                />
            );
        case DocumentType.sec_filing:
            return (
                <MaybeLink
                    href={info.url}
                    label={getDocumentTypeLabel(info.doc_type)}
                    {...props}
                />
            );
        case DocumentType.earnings_transcript:
            return (
                <MaybeLink
                    label={getDocumentTypeLabel(info.doc_type)}
                    {...props}
                />
            );
        default:
            return info satisfies never;
    }
};

interface MaybeLinkProps extends HTMLAttributes<HTMLElement> {
    label: ReactNode;
    href?: string | null;
}

const MaybeLink = ({ label, href, className, ...props }: MaybeLinkProps) => {
    if (href == null) {
        return (
            <p className={cn("text-xs", className)} {...props}>
                {label}
            </p>
        );
    }
    return (
        <a
            href={href}
            target="_blank"
            onClick={stopPropagation}
            className={cn(
                "group inline-flex items-center gap-1.5 underline-offset-2 hover:underline",
                className,
            )}
            {...props}
        >
            {label}
            <ExternalLink className="size-3 opacity-0 transition-opacity group-hover:opacity-100" />
        </a>
    );
};
