import {
    ComponentPropsWithRef,
    MouseEventHandler,
    useEffect,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";
import useSWRImmutable from "swr/immutable";

import { UUID } from "@/api/types";
import { DocumentReference } from "@/components/action-message/document-reference";
import { DocumentLink } from "@/components/document/document-link";
import { DetailedFinding } from "@/components/document-table/condensed/detailed-finding";
import { Markdown } from "@/components/markdown";
import { MaxHeightContainer } from "@/components/max-height-container";
import { PopoverPosition } from "@/components/popover-position";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { useApi } from "@/hooks/use-api";
import { useCitationContext } from "@/hooks/use-citation-context";
import { useGridView } from "@/hooks/use-grid-view-context";
import { CitationType } from "@/stores/citations";
import { getAsyncValue } from "@/utils/async-value";
import { Rect } from "@/utils/geometry";
import { isEmptyOrNull } from "@/utils/string-helpers";

const CitationSkeleton = () => (
    <div className="mt-1 mb-3 space-y-3">
        <Skeleton className="h-2 w-full" />
        <Skeleton className="h-2 w-full" />
        <Skeleton className="h-2 w-full" />
        <Skeleton className="h-2 w-1/2" />
    </div>
);

const CitationContent = (props: { citationID: UUID }) => {
    const api = useApi();
    const [retryCounter, setRetryCounter] = useState(0);
    const { data, isLoading, error, isValidating } = useSWRImmutable(
        [props.citationID, retryCounter, "citation"],
        async ([id, retryCounter]) =>
            await api.fetch_citation(id, retryCounter > 0),
    );

    const retry: MouseEventHandler = (e) => {
        e.stopPropagation();
        setRetryCounter((s) => s + 1);
    };

    if (error || (data !== undefined && isEmptyOrNull(data.citation))) {
        return (
            <p className="text-destructive px-2">
                Failed to load citation
                <Button
                    onClick={retry}
                    variant="link"
                    className="text-background"
                >
                    Retry
                </Button>
            </p>
        );
    }

    if (data === undefined || isLoading || isValidating) {
        return <CitationSkeleton />;
    }

    return (
        <Markdown className="prose-sm prose-strong:bg-brightwave-300 dark:prose-strong:bg-brightwave-800 p-2 pt-1">
            {data.citation}
        </Markdown>
    );
};

interface FindingPopoverProps extends ComponentPropsWithRef<"div"> {
    findingID: string;
    anchor: Rect;
}

const FindingPopover = ({ ref, findingID, anchor }: FindingPopoverProps) => {
    const finding = useCitationContext((s) => s.findings.get(findingID));
    const finding_groups = useGridView((s) => s.finding_groups);

    const finding_group = finding
        ? Array.from(finding_groups.values()).find((fg) =>
              getAsyncValue(fg.findings, []).some((f) => f.id === finding.id),
          )
        : undefined;

    const document = useCitationContext((s) =>
        finding_group ? s.documents.get(finding_group.document_id) : undefined,
    );

    if (!finding) return null;

    return (
        <PopoverPosition
            ref={ref}
            anchor={anchor}
            className="border-border bg-background w-1/3 rounded border shadow-md"
        >
            <MaxHeightContainer className="max-h-96 p-4">
                <DetailedFinding finding={finding} />
            </MaxHeightContainer>
            {document && (
                <>
                    <Separator />
                    <DocumentLink documentID={document.id} asChild>
                        <DocumentReference info={document} />
                    </DocumentLink>
                </>
            )}
        </PopoverPosition>
    );
};

interface CitationPopoverProps extends ComponentPropsWithRef<"div"> {
    citationID: string;
    anchor: Rect;
}

const CitationPopover = ({ ref, citationID, anchor }: CitationPopoverProps) => {
    const citation = useCitationContext((s) => s.citations.get(citationID));
    const document = useCitationContext((s) =>
        citation ? s.documents.get(citation.document_id) : undefined,
    );
    if (!citation) return null;

    return (
        <PopoverPosition
            ref={ref}
            anchor={anchor}
            className="border-border bg-background w-1/3 rounded border shadow-md"
        >
            <MaxHeightContainer className="max-h-96 p-4">
                <CitationContent citationID={citation.id} />
            </MaxHeightContainer>
            {document && (
                <>
                    <Separator />
                    <DocumentLink documentID={document.id} asChild>
                        <DocumentReference info={document} />
                    </DocumentLink>
                </>
            )}
        </PopoverPosition>
    );
};

export const Citation = () => {
    const ref = useRef<HTMLDivElement>(null);
    const anchor = useCitationContext((s) => s.anchor);
    const clearCitation = useCitationContext((s) => s.clearCitation);
    const citation = useCitationContext((s) => s.citation);

    // close citation overlay when clicked outside
    const listener = (e: MouseEvent) => {
        const target = e.target;
        if (
            citation &&
            target instanceof Element &&
            !ref.current?.contains(target)
        ) {
            clearCitation();
        }
    };
    useEffect(() => {
        if (!citation) return;
        document.documentElement.addEventListener("click", listener);
        return () =>
            document.documentElement.removeEventListener("click", listener);
    }, [citation]);

    if (!citation || !anchor) return null;

    switch (citation.data.type) {
        case CitationType.invalid:
            return null;
        case CitationType.citation:
            return createPortal(
                <CitationPopover
                    ref={ref}
                    citationID={citation.data.citation_id}
                    anchor={anchor}
                />,
                document.body,
            );
        case CitationType.finding:
            return createPortal(
                <FindingPopover
                    ref={ref}
                    findingID={citation.data.finding_id}
                    anchor={anchor}
                />,
                document.body,
            );
    }
};
