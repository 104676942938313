import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";

import { ButtonWithTooltip } from "@/components/button-with-tooltip";

export function ColorSchemeToggle() {
    const { setTheme, resolvedTheme, systemTheme } = useTheme();

    const toggleTheme = () => {
        if (resolvedTheme !== systemTheme) {
            setTheme("system");
        } else {
            setTheme(resolvedTheme === "light" ? "dark" : "light");
        }
    };

    return (
        <ButtonWithTooltip
            variant="ghost"
            size="icon"
            onClick={toggleTheme}
            tooltip={resolvedTheme === "light" ? "Dark Mode" : "Light Mode"}
        >
            <Sun className="h-[1.2rem] w-[1.2rem] scale-100 rotate-0 transition-all dark:scale-0 dark:-rotate-90" />
            <Moon className="absolute h-[1.2rem] w-[1.2rem] scale-0 rotate-90 transition-all dark:scale-100 dark:rotate-0" />
            <span className="sr-only">Toggle theme</span>
        </ButtonWithTooltip>
    );
}
