import { StaticColumn } from "@/conf/grid-view";

export const getStaticColumnLabel = (type: StaticColumn): string => {
    switch (type) {
        case StaticColumn.select:
        case StaticColumn.add_column:
            return "";
        case StaticColumn.document:
            return "Document";
        case StaticColumn.ticker:
            return "Ticker / Company";
        case StaticColumn.document_type:
            return "Document Type";
        case StaticColumn.document_status:
            return "Document Status";
        case StaticColumn.content_date:
            return "Date";
        case StaticColumn.document_title:
            return "Document Title";
        default:
            return type satisfies never;
    }
};
