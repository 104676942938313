import { Wifi, WifiOff } from "lucide-react";

import { useNetwork } from "@/hooks/use-network";
import { cn } from "@/lib/utils";

export const OfflineIndicator = () => {
    const isOnline = useNetwork();
    return (
        <div
            className={cn(
                "text-background fixed top-0 left-[50%] z-20 flex -translate-x-1/2 items-center gap-4 rounded-b px-4 py-2 text-sm transition-transform",
                isOnline
                    ? "-translate-y-full bg-green-500 delay-1000"
                    : "bg-destructive",
            )}
        >
            {isOnline ? (
                <>
                    <Wifi className="size-4" />
                    You're back online!
                </>
            ) : (
                <>
                    <WifiOff className="size-4" />
                    You're offline! Some features might not be available
                </>
            )}
        </div>
    );
};
