import { CommandInput, Command } from "cmdk";
import { ArrowRight, Check, Search } from "lucide-react";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { toast } from "sonner";
import useSWRImmutable from "swr/immutable";

import { APIContextItem, Company } from "@/api/types";
import { CompanyPill } from "@/components/analyze/company-pill";
import { SearchResults } from "@/components/analyze/search-results";
import { AsyncButton } from "@/components/async-button";
import { CompanyLogo } from "@/components/company-logo";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverClose,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { useDebouncedValue } from "@/hooks/use-debounced-value";
import { useMap } from "@/hooks/use-map";
import { cn } from "@/lib/utils";
import { asyncEmptyFunction } from "@/utils/empty-function";
import { isEmptyOrNull } from "@/utils/string-helpers";

const getKey = (c: Company) => `${c.symbol}_${c.exchange}`;

type Props = {
    confirmSelection?: boolean;
    maxDocumentCount?: number;
    placeholder?: string;
    disabled?: boolean;
    addedDocumentIDs?: Set<string>;
    addItems?: (item: APIContextItem[]) => Promise<void>;
    className?: string;
};

export const DocumentSearch = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const api = useApi();
    const [open, openActions] = useBoolean();
    const [companies, companiesActions] = useMap<Company>(getKey);
    const [query, setQuery] = useState("");
    const debouncedQuery = useDebouncedValue(query, { delay: 150 });
    const [items, itemsActions] = useMap<APIContextItem>(
        (item) => item.data.unique_id,
    );
    const addItems = async (ctx_items: APIContextItem[]) => {
        if (props.confirmSelection) {
            return itemsActions.addAll(ctx_items);
        }

        const totalCount = items.size + ctx_items.length;
        const maxReached =
            props.maxDocumentCount !== undefined &&
            props.maxDocumentCount < totalCount;
        if (maxReached) {
            toast.error("Maximum document count reached");
        } else {
            await props.addItems?.(ctx_items);
        }
    };
    const addedDocumentIDs = new Set([
        ...items.keys(),
        ...(props.addedDocumentIDs ?? []),
    ]);

    const { data: searchResult, isValidating } = useSWRImmutable(
        [debouncedQuery, "companies"],
        async ([query]) => {
            if (isEmptyOrNull(query)) return [];
            return await api.find_companies(query);
        },
        { keepPreviousData: true },
    );

    useHotkeys("mod+k", openActions.open, { enableOnFormTags: true });

    const handleCancel = () => {
        companiesActions.clear();
        setQuery("");
        itemsActions.clear();
    };

    useEffect(() => {
        if (!open && companies.size) {
            const id = setTimeout(handleCancel, 2000);
            return () => clearTimeout(id);
        }
    }, [companies, open, handleCancel]);

    const confirmAction = useAsyncState(
        async () => {
            if (items.size === 0) return;
            const addItems = props.addItems ?? asyncEmptyFunction;
            await addItems([...items.values()]);
        },
        { onSuccess: openActions.close },
    );

    const getSelectHandler = (company: Company) => () => {
        if (!companiesActions.has(company)) {
            setQuery("");
        }
        companiesActions.toggle(company);
    };
    const handleInputKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === "Backspace" && isEmptyOrNull(query)) {
            companiesActions.pop();
        }
    };

    return (
        <Popover open={!props.disabled && open} onOpenChange={openActions.set}>
            <PopoverTrigger asChild>
                <div
                    className={cn(
                        "bg-muted/50 focus-within:ring-ring flex min-h-10 flex-1 items-center justify-between gap-2 rounded-md border px-2 transition-colors focus-within:ring-2 focus-within:ring-offset-2 hover:border-zinc-300 dark:hover:border-zinc-600",
                        props.className,
                    )}
                    role="button"
                >
                    <Search className="text-muted-foreground size-4" />
                    <div className="text-muted-foreground/50 line-clamp-1 grow text-sm font-medium">
                        {props.placeholder}
                    </div>
                    <Badge
                        variant="cmd"
                        className="text-muted-foreground px-1.5 whitespace-nowrap"
                    >
                        ⌘ K
                    </Badge>
                </div>
            </PopoverTrigger>
            <PopoverContent
                className="relative flex min-h-[20vh] w-[63vw] flex-col rounded-xl p-0"
                style={{
                    marginTop: "calc(-1 * var(--radix-popover-trigger-height))",
                    minWidth: "var(--radix-popover-trigger-width)",
                    maxHeight:
                        "calc(var(--radix-popover-content-available-height) - 1em)",
                }}
                sideOffset={-2}
                asChild
            >
                <Command>
                    <div className="border-b p-3">
                        <div
                            className="focus-within:ring-ring flex min-h-9 flex-wrap items-center gap-2 rounded px-1 focus-within:ring-2 focus-within:outline-hidden"
                            onClick={() => {
                                inputRef.current?.focus();
                            }}
                        >
                            {Array.from(companies.values()).map((company) => (
                                <CompanyPill
                                    key={company.symbol}
                                    company={company}
                                    onRemove={() =>
                                        companiesActions.remove(company)
                                    }
                                />
                            ))}
                            <CommandInput
                                ref={inputRef}
                                value={query}
                                onValueChange={setQuery}
                                placeholder="Search ticker...."
                                className="placeholder:text-muted-foreground flex grow rounded-md bg-transparent py-2 outline-hidden"
                                autoFocus
                                onFocus={(e) => e.target.select()}
                                onKeyDown={handleInputKeyDown}
                            />
                        </div>
                    </div>
                    <div className="flex grow overflow-hidden">
                        <CommandList className="max-h-none shrink-0 grow-0 basis-4/12 border-r">
                            <CommandEmpty>
                                {query
                                    ? isValidating
                                        ? "Searching..."
                                        : "No results."
                                    : "Search for companies by ticker symbol"}
                            </CommandEmpty>
                            <CommandGroup>
                                {(searchResult ?? []).map((company) => (
                                    <CommandItem
                                        key={getKey(company)}
                                        value={getKey(company)}
                                        className="flex items-center gap-2"
                                        onSelect={getSelectHandler(company)}
                                        keywords={[
                                            company.symbol,
                                            company.name,
                                        ]}
                                    >
                                        <Check
                                            className={cn(
                                                "size-4 shrink-0",
                                                !companiesActions.has(
                                                    company,
                                                ) && "invisible",
                                            )}
                                        />
                                        <CompanyLogo
                                            ticker={company.symbol}
                                            size="xl"
                                            className="bg-muted"
                                        />
                                        <div className="flex w-full flex-wrap justify-between">
                                            <span className="shrink-0 font-bold">
                                                {company.symbol}
                                            </span>
                                            <span className="text-xs text-gray-500">
                                                {company.exchange}
                                            </span>
                                            <span className="line-clamp-1 basis-full text-xs">
                                                {company.name}
                                            </span>
                                        </div>
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                        <div className="flex shrink-0 grow-0 basis-8/12 flex-col">
                            {companies.size > 0 && (
                                <SearchResults
                                    companies={Array.from(companies.values())}
                                    addedDocumentIDs={addedDocumentIDs}
                                    addItems={addItems}
                                    removeItem={itemsActions.remove}
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-span-full flex items-center justify-between border-t p-3">
                        {props.confirmSelection ? (
                            <>
                                <PopoverClose asChild>
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </PopoverClose>
                                <AsyncButton
                                    variant="primary"
                                    size="sm"
                                    className="gap-2"
                                    action={confirmAction}
                                    disabled={
                                        items.size === 0 ||
                                        (props.maxDocumentCount !== undefined &&
                                            props.maxDocumentCount < items.size)
                                    }
                                >
                                    Add {items.size}
                                    {props.maxDocumentCount
                                        ? ` / ${props.maxDocumentCount}`
                                        : undefined}{" "}
                                    Documents
                                    <ArrowRight className="size-4" />
                                </AsyncButton>
                            </>
                        ) : (
                            <>
                                <div className="grow" />
                                <PopoverClose asChild>
                                    <Button size="sm" variant="ghost">
                                        Close
                                    </Button>
                                </PopoverClose>
                            </>
                        )}
                    </div>
                </Command>
            </PopoverContent>
        </Popover>
    );
};
