import { ArrowRight, Lightbulb, Sparkles, Table2, Zap } from "lucide-react";
import { ReactNode, useState } from "react";

import { Stepper, StepperPagination } from "@/components/stepper";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogTitle,
} from "@/components/ui/dialog";
import { NuxType } from "@/conf/nux";
import { useNux } from "@/hooks/use-nux";

const slideContent: StepContent[] = [
    {
        icon: <Sparkles className="size-5 stroke-1" />,
        title: "Welcome back.",
        headline: "The New Brightwave",
        content: `Today marks a shift in how you use Brightwave. We are changing the product to get you better insights faster.`,
    },
    {
        icon: <Zap className="size-5 stroke-1" />,
        title: "Fast",
        headline: "Insight In Seconds",
        content: `Brightwave provides detailed document analysis in seconds on SEC Filings, Earnings Calls, and your uploaded PDFs.`,
    },
    {
        icon: <Table2 className="size-5 stroke-1" />,
        title: "Simple",
        headline: "Grid View",
        content: `Quickly scan, understand, and compare up to 50 documents across parameters relevant to your research.`,
    },
    {
        icon: <Lightbulb className="size-5 stroke-1" />,
        title: "Insightful",
        headline: "Dive Deep With Chat",
        content: `Chat allows you to deepen your understanding by asking questions across documents. Extract financial information,
        compare & contrast ideas, or understand implications across themes. Deep insight is at your fingertips.`,
    },
];

type StepContent = {
    icon: ReactNode;
    title: string;
    headline: string;
    content: string;
};

const Step = (props: StepContent) => (
    <div className="space-y-14">
        <p className="font-headline flex items-center gap-2 text-2xl font-light">
            {props.icon}
            {props.title}
        </p>
        <div className="space-y-4">
            <h2 className="font-headline max-w-[70%] text-4xl font-bold text-balance">
                {props.headline}
            </h2>
            <p>{props.content}</p>
        </div>
    </div>
);

const preventDefault = (e: Event): void => {
    e.preventDefault();
};

export const BrightwaveOnboarding = () => {
    const [newUser] = useNux(NuxType.ONBOARDING_DIALOG);
    const [showNux, dismiss] = useNux(NuxType.V2_ONBOARDING);
    const [slide, setSlide] = useState(0);
    const slides = slideContent.slice(newUser ? 1 : 0);
    const isLastSlide = slide >= slides.length - 1;
    const nextAction = isLastSlide ? dismiss : () => setSlide((s) => s + 1);

    console.log({ showNux });

    const onOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            dismiss();
        }
    };

    return (
        <Dialog open={showNux} onOpenChange={onOpenChange}>
            <DialogContent
                withCloseButton={false}
                className="space-y-8 p-8"
                onPointerDownOutside={preventDefault}
                onInteractOutside={preventDefault}
            >
                <Stepper currentSlide={slide}>
                    {slides.map((content, index) => (
                        <Step key={index} {...content} />
                    ))}
                </Stepper>
                <div className="flex items-end justify-between">
                    <StepperPagination
                        steps={slides.length}
                        activeStep={slide}
                        className="mb-2"
                    />
                    <Button variant="primary" onClick={nextAction}>
                        {isLastSlide ? "Let's get started" : "Continue"}
                        <ArrowRight className="ml-2 size-4" />
                    </Button>
                </div>
                <DialogClose asChild>
                    <Button
                        variant="link"
                        className="absolute top-0 right-8 z-10 h-auto"
                    >
                        Skip
                    </Button>
                </DialogClose>
                <DialogTitle className="sr-only">
                    Welcome to Brightwave
                </DialogTitle>
            </DialogContent>
        </Dialog>
    );
};
