import { Navigate } from "react-router-dom";

import { PageLoader } from "@/components/page-loader";
import { config } from "@/utils/configuration";

export const LoginContainer = () => {
    if (config.auth0) {
        window.location.assign(
            `/api/auth/login?return_url=${encodeURIComponent(window.location.pathname)}`,
        );
        return <PageLoader />;
    }

    return <Navigate to="/login" replace />;
};
