import { Table } from "@tanstack/react-table";
import { ChangeEvent, KeyboardEvent, ReactNode, useState } from "react";

import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { useFilter } from "@/components/document-table/filter/use-filter";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Input } from "@/components/ui/input";
import { TextFilterValue } from "@/utils/filter";
import {
    isEmptyOrNull,
    notEmptyOrNull,
    truncate,
} from "@/utils/string-helpers";

type Props = {
    columnId: string;
    title: string;
    icon?: ReactNode;
    table: Table<DocumentWithAsyncFindings>;
};

export const TextColumnFilter = ({ table, ...props }: Props) => {
    const column = table.getColumn(props.columnId);
    const filterValue = column?.getFilterValue() as TextFilterValue | undefined;
    const [value, setValue] = useState(filterValue?.contains ?? "");
    const clearOpenFilter = useFilter((s) => s.clearOpenFilter);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        column?.setFilterValue({ contains: e.target.value });
    };
    const clearFilter = () => column?.setFilterValue(undefined);
    const handleClose = () => {
        if (isEmptyOrNull(value)) {
            clearFilter();
        }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
        console.log(e);
        if (e.key === "Enter" && notEmptyOrNull(value)) {
            clearOpenFilter();
        }
    };

    const filterBadge = notEmptyOrNull(filterValue?.contains) ? (
        <span className="bg-muted group-hover:bg-background rounded-full px-2.5 py-1.5 text-xs transition-colors empty:hidden">
            {truncate(filterValue.contains, 15)}
        </span>
    ) : undefined;

    return (
        <FilterPopover
            {...props}
            onClear={clearFilter}
            filterValue={filterBadge}
            onClose={handleClose}
        >
            <div className="p-2">
                <Input
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                />
            </div>
        </FilterPopover>
    );
};
