import { PropsWithChildren, ReactNode } from "react";

import {
    Tooltip,
    TooltipContent,
    TooltipPortal,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { useBoolean } from "@/hooks/use-boolean";

export const HoverTooltip = ({
    tooltip,
    open,
    children,
}: PropsWithChildren<{ tooltip: ReactNode; open?: boolean }>) => {
    const [isOpen, actions] = useBoolean();
    const isControlled = open !== undefined;
    return (
        <TooltipProvider>
            <Tooltip open={isControlled ? open : isOpen}>
                <TooltipTrigger
                    onMouseOver={isControlled ? undefined : actions.open}
                    onMouseOut={isControlled ? undefined : actions.close}
                    asChild
                >
                    {children}
                </TooltipTrigger>
                <TooltipPortal>
                    <TooltipContent
                        collisionPadding={5}
                        className="bg-foreground text-background border-none text-xs shadow-none"
                    >
                        {tooltip}
                    </TooltipContent>
                </TooltipPortal>
            </Tooltip>
        </TooltipProvider>
    );
};
