import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader2, Plus, Undo2, WandSparkles } from "lucide-react";
import { HTMLAttributes, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import { Probe, ProbeType } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import {
    Form,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { useApi } from "@/hooks/use-api";
import { useAsyncState, UseAsyncState } from "@/hooks/use-async-state";
import { useDebouncedValue } from "@/hooks/use-debounced-value";
import { cn } from "@/lib/utils";
import { getWordCount, isEmptyOrNull } from "@/utils/string-helpers";

const formSchema = z.object({
    name: z
        .string()
        .trim()
        .min(3, "Analysis title must contain at least 3 characters")
        .max(100, "Analysis title must not be longer than 100 characters"),
    prompt: z
        .string()
        .trim()
        .refine(
            (val) => getWordCount(val) >= 3,
            "Analysis definition must be at least 3 words long",
        )
        .refine(
            (val) => getWordCount(val) <= 300,
            "Analysis definition must not be longer than 300 words",
        ),
    type: z.nativeEnum(ProbeType),
});

type FormData = z.infer<typeof formSchema>;

const probeTypeLabel = (type: ProbeType): string => {
    switch (type) {
        case ProbeType.markdown:
        case ProbeType.text:
            return "Text";
        case ProbeType.short_text:
            return "Brief Text";
        case ProbeType.boolean:
        case ProbeType.strict_boolean:
            return "Boolean";
        case ProbeType.finding_list:
        case ProbeType.list:
            return "List";
        case ProbeType.number:
            return "Number";
        default:
            return type satisfies never;
    }
};

const probeTypeDescription = (type: ProbeType): string => {
    switch (type) {
        case ProbeType.markdown:
        case ProbeType.text:
            return "Medium to long form text";
        case ProbeType.short_text:
            return "Short text approx. 5 words long";
        case ProbeType.boolean:
        case ProbeType.strict_boolean:
            return "Yes / No including an explanation when conditions are met";
        case ProbeType.finding_list:
        case ProbeType.list:
            return "List of findings";
        case ProbeType.number:
            return "Extract numerical value including unit, for example currency";
        default:
            return type satisfies never;
    }
};

const VALID_PROBE_TYPES = new Set([
    ProbeType.markdown,
    ProbeType.short_text,
    ProbeType.boolean,
    ProbeType.finding_list,
    ProbeType.number,
]);

// coerce text to markdown and list to finding_list
const coerceProbeType = (type: ProbeType): ProbeType => {
    switch (type) {
        case ProbeType.text:
            return ProbeType.markdown;
        case ProbeType.list:
            return ProbeType.finding_list;
        default:
            return type;
    }
};

interface Props extends HTMLAttributes<HTMLFormElement> {
    action: UseAsyncState<[FormData], unknown>;
    probe?: Probe;
    isUpdate?: boolean;
}

export const ColumnForm = ({
    action,
    probe,
    isUpdate,
    className,
    ...props
}: Props) => {
    // eslint-disable-next-line react-compiler/react-compiler
    "use no memo";
    const api = useApi();
    const [suggestTitle, setSuggestTitle] = useState(
        isEmptyOrNull(probe?.name),
    );
    const [suggestDataType, setSuggestDataType] = useState(
        probe?.type === undefined,
    );
    const form = useForm<FormData>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: probe?.name ?? "",
            prompt: probe?.prompt ?? "",
            type:
                probe?.type !== undefined
                    ? coerceProbeType(probe.type)
                    : ProbeType.markdown,
        },
    });

    const [history, setHistory] = useState<{
        user: string;
        clean: string;
    } | null>(null);
    const validateAction = useAsyncState(
        async () => await api.validate_probe(form.getValues()),
        {
            onSuccess: (d) => {
                setHistory({ clean: d.clean_prompt, user: d.user_prompt });
                form.setValue("prompt", d.clean_prompt);
                if (suggestTitle) {
                    form.setValue("name", d.name);
                }
                if (suggestDataType) {
                    form.setValue("type", coerceProbeType(d.type));
                }
            },
            onError: () => toast.error("Failed to validate"),
        },
    );

    const undo = () => {
        if (history) {
            form.setValue("prompt", history.user);
        }
    };

    // clear history on edit
    const prompt = form.watch("prompt");
    useEffect(() => {
        if (history && history.clean !== prompt) {
            setHistory(null);
        }
    }, [history, prompt]);

    const deriveAction = useAsyncState(
        () => api.validate_probe(form.getValues()),
        {
            onSuccess: (d) => {
                if (suggestTitle) {
                    form.setValue("name", d.name);
                }
                if (suggestDataType) {
                    form.setValue("type", coerceProbeType(d.type));
                }
            },
        },
    );
    const debouncedPrompt = useDebouncedValue(prompt);
    useEffect(() => {
        if (
            (suggestTitle || suggestDataType) &&
            getWordCount(debouncedPrompt) >= 4
        ) {
            deriveAction.submit();
        }
    }, [suggestTitle, suggestDataType, debouncedPrompt]);

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(action.submit)}
                className={cn("bg-muted flex grow flex-col", className)}
                {...props}
            >
                <div className="grow overflow-y-scroll p-4">
                    <div className="bg-background space-y-4 rounded-xl p-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem className="space-y-0">
                                    <FormLabel className="sr-only">
                                        Analysis Name
                                    </FormLabel>
                                    <div className="flex items-center gap-2">
                                        {deriveAction.isSubmitting ? (
                                            <Loader2 className="mt-px size-4 animate-spin" />
                                        ) : (
                                            <ProbeTypeIcon
                                                type={form.watch("type")}
                                                className="mt-px"
                                            />
                                        )}
                                        <Input
                                            placeholder="Analysis Title"
                                            {...field}
                                            onChange={(e) => {
                                                setSuggestTitle(false);
                                                field.onChange(e);
                                            }}
                                            className="hover:ring-ring/10 border-none px-2 text-lg font-medium hover:ring-2 hover:ring-offset-2"
                                        />
                                    </div>
                                    <FormMessage className="pt-2" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="prompt"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Analysis Definition</FormLabel>
                                    <FormDescription>
                                        Describe what information you want to
                                        extract from each document
                                    </FormDescription>
                                    <Textarea {...field} className="min-h-56" />
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex gap-2">
                            {history !== null && (
                                <ButtonWithTooltip
                                    onClick={undo}
                                    variant="outline"
                                    type="button"
                                    size="icon"
                                    tooltip="Undo"
                                >
                                    <Undo2 className="size-4" />
                                </ButtonWithTooltip>
                            )}
                            <AsyncButton
                                action={validateAction}
                                variant="outline"
                                type="button"
                            >
                                <WandSparkles className="mr-2 size-4" />
                                Enhance
                            </AsyncButton>
                        </div>
                        <FormField
                            control={form.control}
                            name="type"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Data Type</FormLabel>
                                    <Select
                                        value={field.value}
                                        onValueChange={(value) => {
                                            setSuggestDataType(false);
                                            field.onChange(value);
                                        }}
                                    >
                                        <SelectTrigger className="is-value group">
                                            <SelectValue placeholder="Select a type" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {[...VALID_PROBE_TYPES].map(
                                                (type) => (
                                                    <SelectItem
                                                        key={type}
                                                        value={type}
                                                    >
                                                        <div className="flex items-center gap-3">
                                                            <ProbeTypeIcon
                                                                type={type}
                                                            />
                                                            <div>
                                                                <p>
                                                                    {probeTypeLabel(
                                                                        type,
                                                                    )}
                                                                </p>
                                                                <p className="text-muted-foreground text-xs group-[.is-value]:hidden">
                                                                    {probeTypeDescription(
                                                                        type,
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </SelectItem>
                                                ),
                                            )}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <div className="bg-background flex flex-col border-t p-2">
                    <AsyncButton
                        loading={action.isSubmitting}
                        variant="primary"
                    >
                        {isUpdate ? (
                            <>
                                <Check className="mr-2 size-4" />
                                Update Analysis
                            </>
                        ) : (
                            <>
                                <Plus className="mr-2 size-4" />
                                Add Analysis
                            </>
                        )}
                    </AsyncButton>
                </div>
            </form>
        </Form>
    );
};
