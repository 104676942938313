import {
    BookOpenCheck,
    EllipsisVertical,
    Search,
    Trash,
    X,
} from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import useSWRImmutable from "swr/immutable";
import { useShallow } from "zustand/react/shallow";

import { ColumnType, Probe, UUID } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuItem,
    DropdownMenuContent,
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";
import { notEmptyOrNull } from "@/utils/string-helpers";

export const ProbesLibrary = () => {
    const api = useApi();
    const [filter, setFilter] = useState("");
    const {
        data: allProbes,
        isLoading,
        mutate,
    } = useSWRImmutable(["probes"], async () => await api.fetch_probes(), {
        revalidateOnMount: true,
    });
    const addedProbeIDs = useGridView(
        useShallow(
            (s) =>
                new Set(
                    Array.from(s.columns.values())
                        .filter(
                            (c) => c.column_type === ColumnType.user_defined,
                        )
                        .map((c) => c.details.id),
                ),
        ),
    );

    if (!isLoading && (allProbes ?? []).length === 0) {
        return (
            <div className="m-4 flex grow flex-col items-center justify-center gap-8 rounded-lg border p-4">
                <span className="bg-muted rounded-full p-6">
                    <BookOpenCheck className="text-muted-foreground size-10" />
                </span>
                <div className="space-y-1 text-center">
                    <p className="font-semibold">Your library is empty</p>
                    <p className="text-sm">Start by creating a new column</p>
                </div>
            </div>
        );
    }

    const probes = notEmptyOrNull(filter)
        ? (allProbes ?? []).filter(
              (probe) =>
                  probe.name.toLowerCase().includes(filter.toLowerCase()) ||
                  probe.prompt?.toLowerCase().includes(filter.toLowerCase()),
          )
        : (allProbes ?? []);

    return (
        <>
            <label className="border-input bg-background ring-offset-background focus-within:ring-ring mx-4 mt-4 flex min-h-10 items-center gap-2 rounded-md border pl-3 text-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:outline-hidden">
                <Search className="size-4" />
                <input
                    placeholder="Search in your library..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="placeholder:text-muted-foreground grow outline-hidden"
                />
                <Button
                    variant="ghost-destructive"
                    size="icon-sm"
                    className={cn(!notEmptyOrNull(filter) && "hidden")}
                    onClick={() => setFilter("")}
                >
                    <X className="size-4" />
                </Button>
            </label>
            <div className="flex grow flex-col gap-2 overflow-y-scroll p-4">
                {isLoading && <Loader />}
                {(probes ?? []).map((probe) => (
                    <ProbeColumn
                        key={probe.id}
                        probe={probe}
                        checked={addedProbeIDs.has(probe.id)}
                        onHide={() => mutate()}
                    />
                ))}
            </div>
        </>
    );
};

const ProbeColumn = ({
    probe,
    checked,
    onHide,
}: {
    probe: Probe;
    checked: boolean;
    onHide?: () => void;
}) => {
    const api = useApi();
    const addColumn = useGridView((s) => s.addColumn);
    const deleteColumn = useGridView((s) => s.deleteColumn);
    const action = useAsyncState(
        async () => {
            if (checked) {
                await deleteColumn(probe.id);
                return false;
            } else {
                await addColumn(probe.id);
                return true;
            }
        },
        {
            onSuccess: (isChecked) =>
                toast.success(
                    `Analysis "${probe.name}" ${isChecked ? "added" : "removed"}`,
                ),
        },
    );

    const hideAction = useAsyncState(
        async (schema_id: UUID) => await api.hide_probe(schema_id),
        { onSuccess: onHide },
    );
    return (
        <label
            key={probe.id}
            className={cn(
                "group hover:bg-muted/50 flex items-center gap-4 rounded-lg border px-4 py-3 pr-2",
                action.isSubmitting && "opacity-50",
            )}
            onClick={action.getEventHandler()}
            role="button"
        >
            <Checkbox checked={checked} />
            <div className="grow space-y-1">
                <div className="flex items-center gap-2">
                    <ProbeTypeIcon type={probe.type} className="shrink-0" />
                    <p className="font-headline font-medium">{probe.name}</p>
                </div>
                <p className="text-muted-foreground line-clamp-8 text-sm">
                    {probe.prompt}
                </p>
            </div>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon-sm" className="shrink-0">
                        <EllipsisVertical className="size-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent collisionPadding={5}>
                    <DropdownMenuItem
                        className="focus:text-destructive gap-2 transition-colors focus:bg-red-50"
                        onClick={hideAction.getEventHandler(probe.schema_id)}
                    >
                        <Trash className="size-4" />
                        Remove From Library
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </label>
    );
};

const Loader = () =>
    Array(10)
        .fill(1)
        .map((_, i) => (
            <div className="flex min-h-9 items-center gap-4 px-3" key={i}>
                <Skeleton className="size-4" />
                <Skeleton className="h-2.5 w-28" />
            </div>
        ));
