import {
    CircleHelp,
    Info,
    Lightbulb,
    LucideProps,
    MessageCircle,
    MessageCircleQuestion,
    Newspaper,
    Search,
    TextQuote,
} from "lucide-react";
import { HTMLAttributes } from "react";

import { APActionType } from "@/api/types";
import { cn } from "@/lib/utils";

interface IconProps extends LucideProps {
    type: APActionType;
}

const Icon = ({ type, ...props }: IconProps) => {
    switch (type) {
        case APActionType.search:
            return <Search {...props} />;
        case APActionType.chat_message:
            return <MessageCircle {...props} />;
        case APActionType.implications:
            return <CircleHelp {...props} />;
        case APActionType.market_commentary:
            return <Newspaper {...props} />;
        case APActionType.show_evidence:
            return <TextQuote {...props} />;
        case APActionType.tell_me_more:
            return <Info {...props} />;
        case APActionType.thesis_generation:
            return <Lightbulb {...props} />;
        case APActionType.follow_up_questions:
            return <MessageCircleQuestion {...props} />;
        default:
            return type satisfies never;
    }
};

interface Props extends HTMLAttributes<HTMLSpanElement> {
    type: APActionType;
    iconProps?: LucideProps;
}

export const ActionIcon = ({ type, iconProps, className, ...props }: Props) => (
    <span
        className={cn(
            "bg-brightwave text-foreground dark:text-background rounded-full p-1",
            className,
        )}
        {...props}
    >
        <Icon
            {...iconProps}
            type={type}
            className={cn("size-4", iconProps?.className)}
        />
    </span>
);
