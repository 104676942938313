/*
 * MUST mirror the Feature Flag name on Launch Darkly
 *
 * All feature flags will be transformed to lower camelCase by LD
 */
export enum FeatureFlagBool {
    actions_without_inquiry = "actionsWithoutInquiry",
    chat_enabled = "chatEnabled",
    clear_chat = "clearChat",
    custom_synthesis_enabled = "customSynthesisEnabled",
    enable_doc_and_txt_upload = "enableDocAndTxtUpload",
    enable_spreadsheet_upload = "enableSpreadsheetUpload",
    force_boolean_finding_to_false = "forceBooleanFindingToFalse",
    fullstory_enabled = "fullstoryEnabled",
    regenerate_synthesis = "regenerateSynthesis",
    report_v0_enabled = "reportV0Enabled",
    synthesis_enabled = "synthesisEnabled",
}

export enum FeatureFlagNumber {
    report_max_documents = "reportMaxDocuments",
}
