import { ReactNode } from "react";
import useSWRImmutable from "swr/immutable";

import { FullDocumentCollection, UUID } from "@/api/types";
import { ProjectContext } from "@/context/project-context";
import { useApi } from "@/hooks/use-api";

export const ProjectContainer = (props: {
    id: UUID;
    children: ReactNode | ((project: FullDocumentCollection) => ReactNode);
}) => {
    const api = useApi();
    const { data: project, mutate } = useSWRImmutable(
        [props.id],
        async ([id]) => await api.fetch_report(id),
        { suspense: true },
    );

    return (
        <ProjectContext value={{ project, mutate }}>
            {typeof props.children === "function"
                ? props.children(project)
                : props.children}
        </ProjectContext>
    );
};
