import { Column as TSColumn } from "@tanstack/react-table";
import { Row } from "@tanstack/react-table";
import { isSameDay, isWithinInterval } from "date-fns";

import {
    AsyncFindingGroup,
    Column,
    DocumentInfo,
    FindingContentType,
    ProbeType,
} from "@/api/types";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { StaticColumn } from "@/conf/grid-view";
import { AsyncState } from "@/utils/async-value";
import { firstX, lastX, unique, uniqueBy } from "@/utils/collection";
import { getAllFindingContent } from "@/utils/finding-group";
import { isNull, nonNull } from "@/utils/fn";
import { inRange, normalizeNumber } from "@/utils/math";

export type PartialRange<T> = { from?: T; to?: T };
export type Range<T> = { from: T; to: T };

export enum FilterType {
    none = 0,
    text,
    boolean,
    numerical,
    categorical,
    temporal,
}

export const getColumnFilterType = (c: Column): FilterType => {
    switch (c.details.type) {
        case ProbeType.boolean:
        case ProbeType.strict_boolean:
            return FilterType.boolean;
        case ProbeType.finding_list:
        case ProbeType.list:
        case ProbeType.markdown:
        case ProbeType.short_text:
        case ProbeType.text:
            return FilterType.text;
        case ProbeType.number:
            return FilterType.numerical;
        default:
            return c.details.type satisfies never;
    }
};

export const getStaticColumnFilterType = (type: StaticColumn): FilterType => {
    switch (type) {
        case StaticColumn.select:
        case StaticColumn.document:
        case StaticColumn.document_status:
        case StaticColumn.add_column:
            return FilterType.none;
        case StaticColumn.content_date:
            return FilterType.temporal;
        case StaticColumn.document_title:
            return FilterType.text;
        case StaticColumn.ticker:
            return FilterType.categorical;
        case StaticColumn.document_type:
            return FilterType.categorical;
        default:
            return type satisfies never;
    }
};

export const getNumericalFilterRange = (
    columnId: string,
    column: TSColumn<DocumentWithAsyncFindings, unknown> | undefined,
): Range<number> | undefined => {
    const values = unique(
        (column?.getFacetedRowModel().flatRows ?? [])
            .flatMap((fr) =>
                fr.getUniqueValues<[DocumentInfo, AsyncFindingGroup]>(columnId),
            )
            .flatMap(([, fg]) => fg.findings)
            .filter((f) => f.state === AsyncState.success)
            .flatMap((f) => f.value)
            .filter((f) => f.content_type === FindingContentType.numerical)
            .map((f) => normalizeNumber(f.value, f.exponent ?? 0)),
    ).sort();
    if (values.length < 2) return undefined;
    return { from: firstX(values), to: lastX(values) };
};

export const getUniqueTemporalFilterValues = (
    columnId: string,
    column: TSColumn<DocumentWithAsyncFindings, unknown> | undefined,
): Date[] => {
    return uniqueBy(
        (column?.getFacetedRowModel().flatRows ?? [])
            .flatMap((fr) => fr.getUniqueValues<Date | undefined>(columnId))
            .filter(nonNull)
            .sort((a, b) => a.getTime() - b.getTime()),
        (d) => d.getTime(),
    );
};

export const getTemporalFilterRange = (
    columnId: string,
    column: TSColumn<DocumentWithAsyncFindings, unknown> | undefined,
): Range<Date> | undefined => {
    const range = getUniqueTemporalFilterValues(columnId, column);
    if (range === undefined || range.length < 2) return undefined;
    return { from: firstX(range), to: lastX(range) };
};

export type RowValue = [DocumentInfo, AsyncFindingGroup | undefined];

export type TextFilterValue = { contains: string };
export type BooleanFilterValue = boolean;
export type NumericalFilterValue = Range<number>;

export const getColumnFilterFn =
    (column: Column) =>
    (
        row: Row<DocumentWithAsyncFindings>,
        columnId: string,
        filterValue: unknown,
    ): boolean => {
        if (isNull(filterValue)) {
            return true;
        }

        const [_, findingGroup] = row.getValue<RowValue>(columnId);

        if (isNull(findingGroup)) {
            return false;
        }
        const filterType = getColumnFilterType(column);
        switch (filterType) {
            case FilterType.none:
                return true;
            case FilterType.text:
                return evaluateTextFilter(
                    findingGroup,
                    filterValue as TextFilterValue,
                );
            case FilterType.boolean:
                return evaluateBooleanFilter(
                    findingGroup,
                    filterValue as BooleanFilterValue,
                );
            case FilterType.numerical:
                return evaluateNumericalFilter(
                    findingGroup,
                    filterValue as NumericalFilterValue,
                );
            // not supported on finding group
            case FilterType.categorical:
            case FilterType.temporal:
                return false;
            default:
                return filterType satisfies never;
        }
    };

const evaluateTextFilter = (
    findingGroup: AsyncFindingGroup,
    filterValue: TextFilterValue,
): boolean => {
    if (findingGroup.findings.state !== AsyncState.success) {
        return false;
    }
    const content = findingGroup.findings.value
        .map(getAllFindingContent)
        .filter(nonNull)
        .join(" ")
        .toLowerCase();
    return content.includes(filterValue.contains.toLowerCase());
};

const evaluateBooleanFilter = (
    findingGroup: AsyncFindingGroup,
    filterValue: BooleanFilterValue,
): boolean => {
    if (findingGroup.findings.state !== AsyncState.success) {
        return false;
    }
    return findingGroup.findings.value
        .filter((f) => f.content_type === FindingContentType.boolean)
        .some((f) => f.value === filterValue);
};

const evaluateNumericalFilter = (
    findingGroup: AsyncFindingGroup,
    { from, to }: NumericalFilterValue,
): boolean => {
    if (findingGroup.findings.state !== AsyncState.success) {
        return false;
    }
    return findingGroup.findings.value
        .filter((f) => f.content_type === FindingContentType.numerical)
        .map((f) => normalizeNumber(f.value, f.exponent ?? 0))
        .every(inRange(from, to));
};

export const valueInSetFilterFn = (
    row: Row<DocumentWithAsyncFindings>,
    columnId: string,
    filterValue: string[] | undefined,
): boolean => {
    const value = row.getValue(columnId);
    if (nonNull(value) && nonNull(filterValue) && filterValue.length > 0) {
        const lookup = new Set(filterValue);
        return lookup.has(row.getValue(columnId));
    }
    return false;
};

export const stringContainsFilterFn = (
    row: Row<DocumentWithAsyncFindings>,
    columnId: string,
    filterValue: TextFilterValue | undefined,
): boolean => {
    if (isNull(filterValue)) {
        return true;
    }
    return row
        .getValue<string>(columnId)
        .toLowerCase()
        .includes(filterValue.contains.toLowerCase());
};

export const inDateRangeFilterFn = (
    row: Row<DocumentWithAsyncFindings>,
    columnId: string,
    filterValue: PartialRange<number> | undefined,
): boolean => {
    if (isNull(filterValue)) {
        return true;
    }
    const value = row.getValue<Date>(columnId);
    if (filterValue.from === undefined) {
        return true;
    }
    if (filterValue.to === undefined) {
        return isSameDay(value, filterValue.from);
    }
    return (
        isSameDay(value, filterValue.from) ||
        isSameDay(value, filterValue.to) ||
        isWithinInterval(value, {
            start: filterValue.from,
            end: filterValue.to,
        })
    );
};
