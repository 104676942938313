import { Table } from "@tanstack/react-table";
import { subMonths } from "date-fns";
import { ReactNode, useState } from "react";
import { DateRange, SelectRangeEventHandler } from "react-day-picker";

import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Calendar } from "@/components/ui/calendar";
import { firstX, lastX } from "@/utils/collection";
import { getUniqueTemporalFilterValues, PartialRange } from "@/utils/filter";
import { nonNull } from "@/utils/fn";
import { formatDate } from "@/utils/time";

const NUM_MONTHS = 2;

type Props = {
    columnId: string;
    title: string;
    icon?: ReactNode;
    table: Table<DocumentWithAsyncFindings>;
};

const parseValueToDateRange = (
    value: PartialRange<number> | undefined,
): DateRange | undefined => {
    if (value === undefined || value.from === undefined) return undefined;
    return {
        from: new Date(value.from),
        to: value.to !== undefined ? new Date(value.to) : undefined,
    };
};

export const TemporalColumnFilter = ({ table, ...props }: Props) => {
    const column = table.getColumn(props.columnId);
    const allDates = getUniqueTemporalFilterValues(props.columnId, column);
    const value = column?.getFilterValue() as PartialRange<number> | undefined;
    const [dateRange, setDateRange] = useState<DateRange | undefined>(
        parseValueToDateRange(value),
    );
    const clearFilter = () => column?.setFilterValue(undefined);
    const handleClose = () => {
        if (value !== undefined && Object.keys(value).length === 0) {
            clearFilter();
        }
    };
    const handleSelect: SelectRangeEventHandler = (value) => {
        setDateRange(value);
        // change from date to number so we can serialize in client state
        column?.setFilterValue(
            value !== undefined
                ? { from: value.from?.getTime(), to: value.to?.getTime() }
                : {},
        );
    };

    // no fitler values or only one value found -> can't filter by this
    if (allDates.length < 2) {
        return (
            <FilterPopover {...props} onClear={clearFilter}>
                <p className="p-2 text-center text-sm italic">
                    Column does not contain values to filter
                </p>
            </FilterPopover>
        );
    }
    const min = firstX(allDates);
    const max = lastX(allDates);

    const badge = value?.from ? (
        <span className="bg-muted group-hover:bg-background rounded-full px-2.5 py-1.5 text-xs transition-colors empty:hidden">
            {[
                value.from ? formatDate(new Date(value.from)) : null,
                value.to ? formatDate(new Date(value.to)) : null,
            ]
                .filter(nonNull)
                .join(" - ")}
        </span>
    ) : undefined;

    return (
        <FilterPopover
            {...props}
            onClear={clearFilter}
            onClose={handleClose}
            filterValue={badge}
            className="w-auto"
        >
            <Calendar
                initialFocus
                mode="range"
                defaultMonth={subMonths(max, NUM_MONTHS - 1)}
                selected={dateRange}
                onSelect={handleSelect}
                numberOfMonths={NUM_MONTHS}
                disabled={[{ before: min }, { after: max }]}
                className="border-t p-2"
                modifiers={{ hasDocument: allDates }}
                modifiersClassNames={{
                    hasDocument:
                        "after:content-[''] after:absolute after:h-1 after:w-4 after:block after:bg-blue-500 after:rounded-full after:bottom-1 after:left-[50%] after:-translate-x-1/2",
                }}
            />
        </FilterPopover>
    );
};
