import { Table } from "@tanstack/react-table";
import { PropsWithChildren } from "react";

import { Column } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { useFilter } from "@/components/document-table/filter/use-filter";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { StaticColumnIcon } from "@/components/document-table/sorting";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { FILTERABLE_COLUMNS, StaticColumn } from "@/conf/grid-view";
import { useBoolean } from "@/hooks/use-boolean";
import { useGridView } from "@/hooks/use-grid-view-context";
import { getColumnId, getColumnTitle, getColumnType } from "@/utils/columns";
import {
    FilterType,
    getColumnFilterType,
    getNumericalFilterRange,
    getStaticColumnFilterType,
    getTemporalFilterRange,
} from "@/utils/filter";
import { getStaticColumnLabel } from "@/utils/grid-view";

type Props = PropsWithChildren<{
    table: Table<DocumentWithAsyncFindings>;
    onFilterAdd?: () => void;
}>;

export const AddFilter = (props: Props) => {
    const [open, openActions] = useBoolean();
    const allColumns = useGridView((s) => s.columns);
    const setOpenFilter = useFilter((s) => s.setOpenFilter);
    const addColumnFilter = useGridView((s) => s.addColumnFilter);

    const getAnalysisHandler = (column: Column) => (value?: unknown) => {
        const id = getColumnId(column);
        addColumnFilter({ id, value });
        setOpenFilter(id);
        openActions.close();
        props.onFilterAdd?.();
    };
    const getStaticHandler = (columnId: StaticColumn) => () => {
        addColumnFilter({
            id: columnId,
            value: getDefaultFilterValue(
                getStaticColumnFilterType(columnId),
                props.table,
                columnId,
            ),
        });
        setOpenFilter(columnId);
        openActions.close();
        props.onFilterAdd?.();
    };
    const filters = props.table.getState().columnFilters;
    const filterIDs = new Set(filters.map((f) => f.id));
    const availableColumns = allColumns
        .filter((col) => !filterIDs.has(getColumnId(col)))
        .map((column) => ({
            title: getColumnTitle(column),
            column,
        }))
        .sort((a, b) => a.title.localeCompare(b.title));
    const availableStaticColumns = Array.from(FILTERABLE_COLUMNS)
        .filter((type) => !filterIDs.has(type))
        .filter((type) => {
            const column = props.table.getColumn(type);
            const filterType = getStaticColumnFilterType(type);
            switch (filterType) {
                case FilterType.categorical:
                    return (column?.getFacetedUniqueValues().size ?? 0) > 1;
                case FilterType.temporal:
                    return getTemporalFilterRange(type, column) !== undefined;
                case FilterType.numerical:
                    return getNumericalFilterRange(type, column) !== undefined;
                case FilterType.none:
                case FilterType.text:
                case FilterType.boolean:
                    return true;
                default:
                    return filterType satisfies never;
            }
        });

    return (
        <Popover open={open} onOpenChange={openActions.set}>
            <PopoverTrigger asChild>{props.children}</PopoverTrigger>
            <PopoverContent
                className="flex flex-col p-0"
                collisionPadding={5}
                style={{
                    maxHeight: "var(--radix-popper-available-height)",
                }}
            >
                <Command>
                    <CommandInput />
                    <CommandList className="p-1">
                        <CommandEmpty>No filters found.</CommandEmpty>
                        <CommandGroup heading="Document">
                            {availableStaticColumns.map((type) => (
                                <CommandItem
                                    className="gap-2"
                                    key={type}
                                    onSelect={getStaticHandler(type)}
                                >
                                    <StaticColumnIcon type={type} />
                                    {getStaticColumnLabel(type)}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                        <CommandGroup heading="Analysis">
                            {availableColumns.map(({ title, column }) => (
                                <ColumnRow
                                    key={getColumnId(column)}
                                    table={props.table}
                                    title={title}
                                    column={column}
                                    onSelect={getAnalysisHandler(column)}
                                />
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

const ColumnRow = (props: {
    title: string;
    column: Column;
    table: Table<DocumentWithAsyncFindings>;
    onSelect: (value?: unknown) => void;
}) => {
    const handleClick = () => {
        props.onSelect(
            getDefaultFilterValue(
                getColumnFilterType(props.column),
                props.table,
                getColumnId(props.column),
            ),
        );
    };

    return (
        <CommandItem className="gap-2" onSelect={handleClick}>
            <ProbeTypeIcon type={getColumnType(props.column)} />
            {props.title}
        </CommandItem>
    );
};

const getDefaultFilterValue = (
    filterType: FilterType,
    table: Table<DocumentWithAsyncFindings>,
    columnId: string,
) => {
    switch (filterType) {
        case FilterType.text:
            return { contains: "" };
        case FilterType.boolean:
            return true;
        case FilterType.numerical:
            return getNumericalFilterRange(columnId, table.getColumn(columnId));
        case FilterType.categorical:
            return [];
        case FilterType.none:
            return undefined;
        case FilterType.temporal:
            return getTemporalFilterRange(columnId, table.getColumn(columnId));
        default:
            return filterType satisfies never;
    }
};
