import { Headline } from "@/components/headline";
import { Separator } from "@/components/ui/separator";

export const V3ReportBuilder = () => {
    return (
        <div className="container space-y-10 py-10">
            <Headline level={3} highlighted>
                New Report
            </Headline>
            <Separator />
            <div className="bg-muted grid grid-cols-3 gap-3 rounded border p-4">
                <div className="bg-background rounded border p-4">
                    <Headline level={5}>Data</Headline>
                </div>
                <div className="bg-background rounded border p-4">
                    <Headline level={5}>Analysis</Headline>
                </div>
                <div className="bg-background rounded border p-4">
                    <Headline level={5}>Output</Headline>
                </div>
            </div>
            <Separator />
        </div>
    );
};
