import { HTMLAttributes } from "react";

import { DocumentType } from "@/api/types";
import { cn } from "@/lib/utils";
import { getDocumentTypeLabel } from "@/utils/report";
import { pluralize } from "@/utils/string-helpers";

interface DocumentTypePillProps extends HTMLAttributes<HTMLSpanElement> {
    type: DocumentType;
    count: number;
}
export const DocumentTypePill = ({
    type,
    count,
    className,
    ...props
}: DocumentTypePillProps) => (
    <span
        className={cn(
            "bg-muted inline-block rounded px-2 py-1 text-sm font-medium",
            className,
        )}
        {...props}
    >
        {count} {pluralize(count, getDocumentTypeLabel(type))}
    </span>
);
