import { UIMatch, useMatches } from "react-router-dom";

import { Headline, Props as HeadlineProps } from "@/components/headline";
import { getMatchTitle, matchHasTitle } from "@/utils/breadcrumbs";
import { nonNull } from "@/utils/fn";

const normalizeURL = (path: string): string =>
    path.endsWith("/") ? path : path + "/";

export const PageTitle = (props: HeadlineProps) => {
    const match = useMatches().reduceRight(
        (agg: UIMatch | null, match: UIMatch) => {
            if (agg === null) return match;
            if (matchHasTitle(agg)) return agg;
            if (
                normalizeURL(agg.pathname) === normalizeURL(match.pathname) &&
                matchHasTitle(match)
            ) {
                return match;
            }
            return agg;
        },
        null,
    );
    if (nonNull(match) && matchHasTitle(match)) {
        return <Headline {...props}>{getMatchTitle(match)}</Headline>;
    }
    return null;
};
