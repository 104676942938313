import { DropzoneState } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { createStore } from "zustand";

import { BrightwaveAPI } from "@/api/rest";
import {
    FileStatus,
    FileUploadResult,
    UploadFile,
    UploadResult,
    UUID,
} from "@/api/types";
import {
    mapApplyChangeset,
    mapApplyChangesets,
    mapDeleteAll,
} from "@/utils/es6-map";

type Dependencies = {
    api: BrightwaveAPI;
};

type Props = {
    dropzone: DropzoneState;
};

interface State extends Props {
    files: Map<UUID, UploadFile>;
    maxFiles: number;

    isFocused: boolean;
    isDragActive: boolean;
    isDragAccept: boolean;
    isDragReject: boolean;
    isFileDialogActive: boolean;

    reset: () => void;
    uploadFiles: (files: File[]) => Promise<void>;
    updateFile: (
        id: UUID,
        changeset: Partial<Omit<UploadFile, "type" | "upload_id">>,
    ) => void;
    removeFiles: (id: string[]) => void;
    setMaxFiles: (maxFiles: number) => void;

    setIsFocused: (s: boolean) => void;
    setIsDragActive: (s: boolean) => void;
    setIsDragAccept: (s: boolean) => void;
    setIsDragReject: (s: boolean) => void;
    setIsFileDialogActive: (s: boolean) => void;
    setDropzone: (s: DropzoneState) => void;
}

export type FileUploadStore = ReturnType<typeof createFileUploadStore>;

const getChangesetForUploadResult = (
    uploadResult: FileUploadResult,
): Partial<UploadFile> => {
    switch (uploadResult.status) {
        case UploadResult.ok:
        case UploadResult.found:
            return {
                status: FileStatus.uploaded,
                document_info: uploadResult.document_info!,
            };
        case UploadResult.too_large:
            return { status: FileStatus.failed, error: "file-too-large" };
        case UploadResult.invalid_format:
            return { status: FileStatus.failed, error: "file-invalid-type" };
    }
};

export const createFileUploadStore = ({
    api,
    dropzone,
}: Dependencies & Props) =>
    createStore<State>((set) => ({
        dropzone,
        files: new Map(),
        maxFiles: 0,

        isFocused: false,
        isDragActive: false,
        isDragAccept: false,
        isDragReject: false,
        isFileDialogActive: false,

        reset() {
            set({ files: new Map() });
        },

        setDropzone(dropzone) {
            set({ dropzone });
        },

        async uploadFiles(files) {
            if (files.length === 0) return;
            const payload = files.map((file) => ({
                id: uuidv4(),
                file,
            }));

            const newFiles = payload.map((p): [UUID, UploadFile] => [
                p.id,
                {
                    upload_id: p.id,
                    status: FileStatus.uploading,
                    name: p.file.name,
                },
            ]);

            set((s) => ({
                files: new Map([...s.files.entries(), ...newFiles]),
            }));

            try {
                const uploadResult = await api.upload_files(payload);
                const changeset = Object.entries(uploadResult).map(
                    ([id, result]): [UUID, Partial<UploadFile>] => [
                        id,
                        getChangesetForUploadResult(result),
                    ],
                );
                set((state) => ({
                    files: mapApplyChangesets(state.files, changeset),
                }));
            } catch (_) {
                // mark files as failed
                const changeset = payload.map(
                    (p): [UUID, Partial<UploadFile>] => [
                        p.id,
                        {
                            status: FileStatus.failed,
                            error: "upload-failed",
                        },
                    ],
                );
                set((state) => ({
                    files: mapApplyChangesets(state.files, changeset),
                }));
            }
        },

        updateFile(id, changeset) {
            set((s) => ({
                files: mapApplyChangeset(s.files, id, changeset),
            }));
        },

        removeFiles(ids) {
            set((s) => ({ files: mapDeleteAll(s.files, ids) }));
        },

        setMaxFiles(maxFiles) {
            set({ maxFiles });
        },

        setIsFocused(isFocused) {
            set({ isFocused });
        },

        setIsDragActive(isDragActive) {
            set({ isDragActive });
        },

        setIsDragAccept(isDragAccept) {
            set({ isDragAccept });
        },

        setIsDragReject(isDragReject) {
            set({ isDragReject });
        },

        setIsFileDialogActive(isFileDialogActive) {
            set({ isFileDialogActive });
        },
    }));
