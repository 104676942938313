import { Table } from "@tanstack/react-table";
import { Plus, X } from "lucide-react";
import { HTMLAttributes, ReactNode, useEffect } from "react";

import { Column } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { AddFilter } from "@/components/document-table/filter/add-filter";
import { BooleanColumnFilter } from "@/components/document-table/filter/boolean";
import { CategoricalColumnFilter } from "@/components/document-table/filter/categorical";
import { NumericalColumnFilter } from "@/components/document-table/filter/numerical";
import { TemporalColumnFilter } from "@/components/document-table/filter/temporal";
import { TextColumnFilter } from "@/components/document-table/filter/text";
import { useFilter } from "@/components/document-table/filter/use-filter";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { StaticColumnIcon } from "@/components/document-table/sorting";
import { Button } from "@/components/ui/button";
import { FILTERABLE_COLUMNS, StaticColumn } from "@/conf/grid-view";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";
import { getColumnId, getColumnTitle, getColumnType } from "@/utils/columns";
import {
    FilterType,
    getColumnFilterType,
    getStaticColumnFilterType,
} from "@/utils/filter";
import { getStaticColumnLabel } from "@/utils/grid-view";

type Filter =
    | { type: "static"; id: StaticColumn }
    | { type: "column"; id: string; column: Column };

interface Props extends HTMLAttributes<HTMLDivElement> {
    table: Table<DocumentWithAsyncFindings>;
}

export const FilterContent = ({ table, className, ...props }: Props) => {
    const isOpen = useFilter((s) => s.isOpen);
    const toggleFilters = useFilter((s) => s.toggle);
    const columns = useGridView((s) => s.columns);

    const availableToFilter = [
        ...Array.from(FILTERABLE_COLUMNS).map(
            (id): Filter => ({
                type: "static",
                id,
            }),
        ),
        ...columns.map(
            (column): Filter => ({
                type: "column",
                id: getColumnId(column),
                column,
            }),
        ),
    ];

    const filters = table.getState().columnFilters;
    const filterIDs = new Set(filters.map((f) => f.id));

    const applied = availableToFilter.filter(({ id }) => filterIDs.has(id));
    const hasMore = applied.length < availableToFilter.length;

    const clearAll = () => {
        table.resetColumnFilters();
        toggleFilters(false);
    };

    useEffect(() => {
        if (isOpen && filters.length === 0) {
            toggleFilters(false);
        }
    }, [isOpen, filters.length, toggleFilters]);

    if (!isOpen) {
        return null;
    }
    return (
        <div className={cn("flex items-center gap-3", className)} {...props}>
            {applied.map((filter) => {
                switch (filter.type) {
                    case "static":
                        return (
                            <ColumnFilter
                                key={filter.id}
                                columnId={filter.id}
                                title={getStaticColumnLabel(filter.id)}
                                icon={<StaticColumnIcon type={filter.id} />}
                                filterType={getStaticColumnFilterType(
                                    filter.id,
                                )}
                                table={table}
                            />
                        );
                    case "column":
                        return (
                            <ColumnFilter
                                key={getColumnId(filter.column)}
                                columnId={getColumnId(filter.column)}
                                title={getColumnTitle(filter.column)}
                                icon={
                                    <ProbeTypeIcon
                                        type={getColumnType(filter.column)}
                                    />
                                }
                                filterType={getColumnFilterType(filter.column)}
                                table={table}
                            />
                        );
                    default:
                        return filter satisfies never;
                }
            })}
            {hasMore && (
                <AddFilter table={table}>
                    <Button variant="secondary" className="gap-2 rounded-full">
                        <Plus className="size-4" />
                        Add Filter
                    </Button>
                </AddFilter>
            )}
            <span className="grow" />
            {filters.length > 0 && (
                <Button
                    variant="ghost-destructive"
                    className="gap-2 rounded-full"
                    onClick={clearAll}
                >
                    <X className="size-4" /> Clear All Filters
                </Button>
            )}
        </div>
    );
};

type ColumnFilterProps = {
    columnId: string;
    title: string;
    icon?: ReactNode;
    filterType: FilterType;
    table: Table<DocumentWithAsyncFindings>;
};

const ColumnFilter = ({ filterType, ...props }: ColumnFilterProps) => {
    switch (filterType) {
        case FilterType.boolean:
            return <BooleanColumnFilter {...props} />;
        case FilterType.text:
            return <TextColumnFilter {...props} />;
        case FilterType.numerical:
            return <NumericalColumnFilter {...props} />;
        case FilterType.categorical:
            return <CategoricalColumnFilter {...props} />;
        case FilterType.temporal:
            return <TemporalColumnFilter {...props} />;
        case FilterType.none:
            return null;
        default:
            return filterType satisfies never;
    }
};
