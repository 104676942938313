import { ArrowLeft, Check } from "lucide-react";
import { Link } from "react-router-dom";

import { ErrorAlert } from "@/components/error-alert";
import { ForgotPasswordForm } from "@/components/forms/forgot-password";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";

type Props = {
    initialError?: string;
};

export const ForgotPasswordRoute = (props: Props) => {
    usePageTitle("Forgot Password");
    const api = useApi();

    const action = useAsyncState(async (email: string) => {
        await api.forgot_password(email);
        return true;
    });

    let error = null;
    if (action.error) {
        error = <ErrorAlert error={action.error} />;
    } else if (props.initialError) {
        error = <ErrorAlert message={props.initialError} />;
    }

    return (
        <div className="w-full space-y-10 sm:min-w-96">
            <div className="space-y-4">
                <h2 className="font-headline text-center text-2xl font-semibold">
                    Forgot your password?
                </h2>
                <p className="text-center text-sm font-light">
                    Enter your email to get a reset link
                </p>
            </div>
            {action.data === true ? (
                <Alert variant="success">
                    <Check className="h-4 w-4" />
                    <AlertTitle>
                        A reset password link has been sent to your inbox
                    </AlertTitle>
                    <AlertDescription>
                        Check your email for a link to reset your password, if
                        it doesn’t appear within a few minutes, check your spam
                        folder
                    </AlertDescription>
                </Alert>
            ) : (
                <>
                    {error}
                    <ForgotPasswordForm action={action} />
                </>
            )}
            <div className="text-center">
                <Button variant="link" asChild>
                    <Link to="/login">
                        <ArrowLeft className="mr-2 h-4 w-4" />
                        Back to the login page
                    </Link>
                </Button>
            </div>
        </div>
    );
};
