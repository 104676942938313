export enum StaticColumn {
    select = "select",
    document = "document",
    document_title = "document_title",
    ticker = "ticker",
    document_type = "document_type",
    document_status = "document_status",
    content_date = "content_date",
    add_column = "add_column",
}

export const HIDDEN_COLUMNS: Set<string> = new Set([
    StaticColumn.ticker,
    StaticColumn.document_type,
    StaticColumn.document_status,
    StaticColumn.content_date,
    StaticColumn.document_title,
]);

export const SORTABLE_COLUMNS = new Set([
    StaticColumn.document_title,
    StaticColumn.content_date,
    StaticColumn.ticker,
    StaticColumn.document_type,
]);

export const FILTERABLE_COLUMNS = new Set([
    StaticColumn.document_title,
    StaticColumn.ticker,
    StaticColumn.document_type,
    StaticColumn.content_date,
]);
