import { use } from "react";

import { ProjectContext, TProjectContext } from "@/context/project-context";

export const useProject = (): TProjectContext => {
    const ctx = use(ProjectContext);
    if (!ctx) {
        throw new Error("ProjectContainer not found in component tree");
    }
    return ctx;
};
