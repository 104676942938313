import {
    HTMLAttributes,
    PropsWithChildren,
    useLayoutEffect,
    useRef,
    useState,
} from "react";

import { cn } from "@/lib/utils";

export const MaxHeightContainer = ({
    className,
    ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
    const ref = useRef<HTMLDivElement>(null);
    const [scrollable, setScrollable] = useState(false);
    useLayoutEffect(() => {
        if (ref.current) {
            setScrollable(ref.current.scrollHeight > ref.current.clientHeight);
        }
    });

    return (
        <div
            ref={ref}
            className={cn(
                scrollable ? "overflow-y-scroll" : "overflow-y-auto",
                className,
            )}
            {...props}
        />
    );
};
