import { formatDistance, isPast, parseISO } from "date-fns";

import { InviteCode } from "@/api/types";
import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { Badge } from "@/components/ui/badge";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";

const isInPast = (date: string): boolean => isPast(parseISO(date));

const relativeDate = (date: string): string =>
    formatDistance(parseISO(date), new Date(), { addSuffix: true });

export const InviteCodesTable = (props: { codes: InviteCode[] }) => (
    <Table className="border">
        <TableHeader>
            <TableRow>
                <TableHead>Code</TableHead>
                <TableHead>Link</TableHead>
                <TableHead>Created</TableHead>
                <TableHead>Expiration</TableHead>
                <TableHead>Cancelled</TableHead>
                <TableHead>Accepted</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {props.codes.map((invite) => (
                <TableRow key={invite.code}>
                    <TableCell>
                        <CopyToClipboard value={invite.code} />
                    </TableCell>
                    <TableCell>
                        <CopyToClipboard value={invite.link}>
                            Click to copy signup link
                        </CopyToClipboard>
                    </TableCell>
                    <TableCell>{relativeDate(invite.created_at)}</TableCell>
                    <TableCell>
                        {isInPast(invite.expiration) ? (
                            <Badge className="bg-red-500/15 whitespace-nowrap text-red-700 hover:bg-red-500/20 dark:text-red-300">
                                Expired
                            </Badge>
                        ) : (
                            <Badge className="bg-green-500/15 whitespace-nowrap text-green-700 hover:bg-green-500/20 dark:text-green-300">
                                {relativeDate(invite.expiration)}
                            </Badge>
                        )}
                    </TableCell>
                    <TableCell>
                        {invite.canceled_at && (
                            <Badge className="bg-red-500/15 whitespace-nowrap text-red-700 hover:bg-red-500/20 dark:text-red-300">
                                {relativeDate(invite.canceled_at)}
                            </Badge>
                        )}
                    </TableCell>
                    <TableCell>
                        {invite.accepted_at && (
                            <Badge className="bg-green-500/15 whitespace-nowrap text-green-700 hover:bg-green-500/20 dark:text-green-300">
                                {relativeDate(invite.accepted_at)}
                            </Badge>
                        )}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);
