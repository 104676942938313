import { createStore } from "zustand";

import {
    APActionCitation,
    DocumentInfo,
    GenericFindingContent,
    UUID,
} from "@/api/types";
import { Rect } from "@/utils/geometry";

export enum CitationType {
    invalid = "invalid",
    citation = "citation",
    finding = "finding",
}

interface CitationDataMap {
    [CitationType.invalid]: object;
    [CitationType.citation]: { citation_id: UUID };
    [CitationType.finding]: { finding_id: UUID };
}

export type CitationData = {
    [K in keyof typeof CitationType]: {
        type: (typeof CitationType)[K];
    } & CitationDataMap[(typeof CitationType)[K]];
}[keyof typeof CitationType];

export interface Props {
    citations: Map<UUID, APActionCitation>;
    documents: Map<UUID, DocumentInfo>;
    findings: Map<UUID, GenericFindingContent>;
}

interface State extends Props {
    citation: { unique_id: string; data: CitationData } | undefined;
    setCitation: (unique_id: string, data: CitationData) => void;
    clearCitation: () => void;

    anchor: Rect | undefined;
    setAnchor: (pos: Rect) => void;

    updateCitations: (citations: Map<UUID, APActionCitation>) => void;
    updateDocuments: (documents: Map<UUID, DocumentInfo>) => void;
    updateFindings: (findings: Map<UUID, GenericFindingContent>) => void;
}

export type CitationsStore = ReturnType<typeof createCitationsStore>;

export const createCitationsStore = (props: Props) =>
    createStore<State>((set) => ({
        ...props,

        citation: undefined,
        setCitation(unique_id, data) {
            set({ citation: { unique_id, data } });
        },

        clearCitation() {
            set({ citation: undefined });
        },

        anchor: undefined,
        setAnchor(anchor) {
            set({ anchor });
        },

        updateCitations(citations) {
            set({ citations });
        },

        updateDocuments(documents) {
            set({ documents });
        },

        updateFindings(findings) {
            set({ findings });
        },
    }));
