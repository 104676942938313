import { Skeleton } from "@/components/ui/skeleton";
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TableHeader,
} from "@/components/ui/table";
import { AppLayout } from "@/layouts/app-layout";

export const GridViewSkeleton = () => (
    <AppLayout
        header={
            <Skeleton className="bg-muted-foreground/40 dark:bg-muted-foreground/20 h-3.5 flex-1" />
        }
        className="bg-page-background pointer-events-none flex h-screen grow flex-col"
    >
        <div className="border-foreground/5 bg-foreground flex min-h-14 items-center justify-between gap-8 border-b px-4 py-2 dark:bg-neutral-900">
            <Skeleton className="bg-muted-foreground/40 dark:bg-muted-foreground/20 h-10 w-24" />
            <div className="flex grow items-center justify-center gap-4">
                <Skeleton className="bg-muted-foreground/40 dark:bg-muted-foreground/20 h-9 w-32" />
                <Skeleton className="bg-muted-foreground/40 dark:bg-muted-foreground/20 h-9 w-96" />
            </div>
            <Skeleton className="bg-muted-foreground/40 dark:bg-muted-foreground/20 h-10 w-24" />
        </div>
        <GridViewContentSkeleton />
    </AppLayout>
);

export const GridViewContentSkeleton = ({
    rowCount = 20,
}: {
    rowCount?: number;
}) => (
    <>
        <div className="border-foreground/10 bg-background flex gap-3 border-b p-2">
            <Skeleton className="h-9 w-24 rounded-full" />
            <Skeleton className="h-9 w-24 rounded-full" />
        </div>
        <Table className="bg-background h-px">
            <TableHeader>
                <TableRow>
                    <TableHead className="min-w-96 border-r">
                        <div className="flex items-center gap-4">
                            <Skeleton className="size-4 rounded-sm" />
                            <Skeleton className="h-4 w-full" />
                        </div>
                    </TableHead>
                    <TableHead className="w-1/3 border-r">
                        <Skeleton className="h-4 w-1/2" />
                    </TableHead>
                    <TableHead className="w-1/3 border-r">
                        <Skeleton className="h-4 w-1/2" />
                    </TableHead>
                    <TableHead className="w-1/3 border-r">
                        <Skeleton className="h-4 w-1/2" />
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {Array.from(Array(rowCount)).map((_, i) => (
                    <TableRow key={i}>
                        <TableCell className="border-r">
                            <div className="flex h-full items-center gap-4">
                                <Skeleton className="size-4 rounded-sm" />
                                <Skeleton className="size-12" />
                                <div className="grow space-y-3">
                                    <Skeleton className="h-4 w-full" />
                                    <Skeleton className="h-3 w-1/2" />
                                </div>
                            </div>
                        </TableCell>
                        <TableCell className="space-y-3">
                            <Skeleton className="h-3 w-full" />
                            <Skeleton className="h-3 w-full" />
                            <Skeleton className="h-3 w-1/2" />
                        </TableCell>
                        <TableCell className="space-y-3">
                            <Skeleton className="h-3 w-1/3" />
                        </TableCell>
                        <TableCell>
                            <div className="flex flex-wrap gap-2">
                                <Skeleton className="h-5 w-40" />
                                <Skeleton className="h-5 w-52" />
                                <Skeleton className="h-5 w-52" />
                                <Skeleton className="h-5 w-44" />
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </>
);
