import { Plus } from "lucide-react";

import { CreateColumn } from "@/components/document-table/columns/create-column";
import { EditColumn } from "@/components/document-table/columns/edit-column";
import { ExistingColumns } from "@/components/document-table/columns/existing-columns";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Page, PageType, useColumnSheet } from "@/hooks/use-column-sheet";

const getMinWidth = (type: PageType): string => {
    switch (type) {
        case PageType.editColumn:
        case PageType.newColumn:
            return "800px";
        case PageType.systemColumns:
        case PageType.customColumns:
            return "550px";
        default:
            return type satisfies never;
    }
};

type PointerDownOutsideEvent = CustomEvent<{
    originalEvent: PointerEvent;
}>;

export const ColumnSheet = () => {
    const isOpen = useColumnSheet((s) => s.isOpen);
    const setOpen = useColumnSheet((s) => s.setOpen);
    const page = useColumnSheet((s) => s.page);
    const close = useColumnSheet((s) => s.close);
    const setPage = useColumnSheet((s) => s.setPage);

    const handlePointerDownOutside = (e: PointerDownOutsideEvent) => {
        if (page.type === PageType.newColumn) {
            e.preventDefault();
        }
    };

    return (
        <Sheet open={isOpen} onOpenChange={setOpen}>
            <SheetContent
                side="right"
                className="focus:ring-none flex flex-col border-none bg-transparent p-0 shadow-none transition-[min-width]"
                style={{ minWidth: getMinWidth(page.type) }}
                withCloseButton={false}
                onPointerDownOutside={handlePointerDownOutside}
            >
                <div className="bg-background m-2 flex grow flex-col overflow-hidden rounded-lg border shadow-lg">
                    <Content page={page} setPage={setPage} close={close} />
                </div>
            </SheetContent>
        </Sheet>
    );
};

const Content = ({
    page,
    setPage,
    close,
}: {
    page: Page;
    setPage: (type: Exclude<PageType, PageType.editColumn>) => void;
    close: () => void;
}) => {
    switch (page.type) {
        case PageType.newColumn:
            return (
                <CreateColumn
                    onSuccess={close}
                    onCancel={() => setPage(PageType.systemColumns)}
                />
            );
        case PageType.systemColumns:
        case PageType.customColumns:
            return (
                <>
                    <ExistingColumns />
                    <div className="border-t p-2">
                        <Button
                            variant="primary"
                            className="w-full gap-2"
                            onClick={() => setPage(PageType.newColumn)}
                        >
                            <Plus className="size-4" />
                            Create New Analysis
                        </Button>
                    </div>
                </>
            );
        case PageType.editColumn:
            return <EditColumn probe={page.probe} onSuccess={close} />;
        default:
            return page satisfies never;
    }
};
