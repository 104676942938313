import { useEffect, useReducer, useState } from "react";
import useSWRImmutable from "swr/immutable";

import { NuxType } from "@/conf/nux";
import { useApi } from "@/hooks/use-api";
import { mapSet } from "@/utils/es6-map";

type UseNux = (nux_id: NuxType) => [boolean, () => Promise<void>];

export const useNux: UseNux = (nux_id) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [showNux, setShowNux] = useState(false);
    const api = useApi();
    const { data, mutate } = useSWRImmutable(
        "nux_state",
        async () => await api.fetch_nux_state(),
        { onSuccess: forceUpdate },
    );

    useEffect(() => {
        if (data) {
            setShowNux(!data.has(nux_id));
        }
    }, [data, nux_id]);

    const dismiss = async () => {
        await mutate(api.mark_nux_seen(nux_id), {
            rollbackOnError: true,
            optimisticData: (prev) =>
                mapSet(prev ?? new Map(), nux_id, new Date().toISOString()),
        });
    };

    return [showNux, dismiss];
};
