import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Copyright } from "@/components/copyright";

export const AuthLayout = (props: PropsWithChildren) => {
    return (
        <div className="bg-logo-clipped-black bg-55 dark:bg-logo-clipped-white flex min-h-screen flex-col items-center justify-between bg-left-top bg-no-repeat pt-40 pb-4">
            <BrightwaveLogo className="h-auto w-52" />
            <div className="grow py-20">{props.children ?? <Outlet />}</div>
            <Copyright />
        </div>
    );
};
