import { Trash, X } from "lucide-react";

import { ContextStatus, DiscoverPanelStatus } from "@/api/types";
import { AsyncButtonWithTooltip } from "@/components/async-button-with-tooltip";
import { DebugID } from "@/components/debug/debug-id";
import { Debugger } from "@/components/debug/debugger";
import { Button } from "@/components/ui/button";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useAsyncState } from "@/hooks/use-async-state";
import { useContextContext } from "@/hooks/use-context-context";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";

type Props = {
    title?: string;
    onClose?: () => void;
};

export const ActionPanelHeader = (props: Props) => {
    const canClearChat = useFeatureFlagBool(FeatureFlagBool.clear_chat, true);
    const ctxStatus = useContextContext((s) => s.status);
    const actionStatus = useActionsContext((s) => s.status);
    const clearActionPanel = useAsyncState(
        useActionsContext((s) => s.refreshConversationID),
    );
    const conversationID = useActionsContext((s) => s.conversationID);
    return (
        <div className="bg-background flex min-h-12 items-center gap-2 border-b pr-1 pl-4">
            <p className="font-headline text-xl font-bold">
                {props.title ?? "Chat"}
            </p>
            <Debugger>
                <div className="flex flex-col">
                    <DebugID
                        label="Context"
                        id={ContextStatus[ctxStatus]}
                        className="text-[9px]"
                    />
                    <DebugID
                        label="Status"
                        id={DiscoverPanelStatus[actionStatus]}
                        className="text-[9px]"
                    />
                    <DebugID
                        label="ConversationID"
                        id={conversationID}
                        className="text-[9px]"
                    />
                </div>
            </Debugger>
            <span className="grow" />
            {canClearChat && (
                <AsyncButtonWithTooltip
                    tooltip="Clear History"
                    variant="ghost-destructive"
                    size="icon"
                    action={clearActionPanel}
                >
                    <Trash className="size-4" />
                </AsyncButtonWithTooltip>
            )}
            <Button onClick={props.onClose} size="icon" variant="ghost">
                <X className="size-4" />
            </Button>
        </div>
    );
};
