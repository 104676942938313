import { AsyncValue } from "@/utils/async-value";

export type UUID = string;

export enum AuthLevel {
    logged_out = 0,
    authenticated,
    viewer,
    creator,
    admin,
    superadmin,
}

export enum MessageRole {
    assistant = "assistant",
    user = "user",
    system = "system",
    rool = "tool",
}

export enum AccountType {
    individual = "individual",
    organization = "organization",
    admin = "admin",
}

export enum UserType {
    admin = "admin",
    creator = "creator",
    viewer = "viewer",
}

export enum OnboardingStatusEnum {
    created = 0,
    onboarded,
    deactivated,
}

export enum CustomerStatus {
    // NOTE: this is an initial state. Self-serve customer needs to select subscription; i.e. admin did not make them
    subscription_pending = "subscription_pending",
    //  NOTE: this is an initial state. Admin created customer needs to acknowledge their subscription before starting trial
    trial_pending = "trial_pending",
    //  NOTE: this is an initial state. Admin created customer needs to acknowledge their subscription (this requires billing details for orgs)
    subscription_confirmation_needed = "subscription_confirmation_needed",
    // customer is trialing
    trialing = "trialing",
    // customer is not trialing and must add billing email/address -> this state should only be valid for orgs
    billing_details_required = "billing_details_required",
    // customer is not trialing and must setup payment before they can access the product
    payment_required = "payment_required",
    // customer is active and has normal product access
    active = "active",
    // customer's subscription has ended
    lapsed = "lapsed",
}

export enum FileStatus {
    initial = 0,
    fetching_info,
    uploading,
    uploaded,
    processing,
    ready,
    failed,
    rejected,
}

export enum SortingDirection {
    ASC = "asc",
    DESC = "desc",
}

export enum ContractInterval {
    month = "month",
    year = "year",
}

export enum Currency {
    usd = "usd",
}

export enum FilingType {
    SEC_S1 = "S1",
    SEC_10K = "10-K",
    SEC_10Q = "10-Q",
    SEC_8K = "8-K",
}

export enum Exchange {
    NYSE = "NYSE",
    NASDAQ = "NASDAQ",
    NYSEMKT = "NYSEMKT",
    NYSEARCA = "NYSEARCA",
    OTC = "OTC",
    BATS = "BATS",
    INDEX = "INDEX",
}

export enum ContextStatus {
    UNINITIALIZED = 0,
    LOADING,
    FAILED,
    LOADED,
    UPDATING,
}

export enum APActionStatus {
    pending = "pending",
    stream_complete = "stream_complete",
    complete = "complete",
    failed = "failed",
    error = "error",
}

export enum APActionType {
    chat_message = "chat_message",
    implications = "implications",
    market_commentary = "market_commentary",
    show_evidence = "show_evidence",
    tell_me_more = "tell_me_more",
    thesis_generation = "thesis_generation",
    follow_up_questions = "follow_up_questions",
    search = "search",
}

export enum APActionStatusReason {
    analyzed_query = "analyzed_query",
    analyzing_documents = "analyzing_documents",
    analyzing_query = "analyzing_query",
    api_error = "api_error",
    bad_action_request = "bad_action_request",
    context_not_loaded = "context_not_loaded",
    formulating_answer = "formulating_answer",
    gathering_evidence = "gathering_evidence",
    generating_response = "generating_response",
    invalid_selection = "invalid_selection",
    message_id_exists = "message_id_exists",
    no_companies_found = "no_companies_found",
    no_documents_found = "no_documents_found",
    no_evidence_found = "no_evidence_found",
    permission_denied = "permission_denied",
    processing_documents = "processing_documents",
    retrieving_documents = "retrieving_documents",
    searched_companies = "searched_companies",
    searched_documents = "searched_documents",
    searching_companies = "searching_companies",
    searching_documents = "searching_documents",
    timeout = "timeout",
    tool_error = "tool_error",
    unexpected_error = "unexpected_error",
}

export enum InviteCodeStatus {
    valid = "valid",
    invalid = "invalid",
    accepted = "accepted",
    expired = "expired",
    canceled = "canceled",
    new_code_created = "new_code_created",
    user_already_onboarded = "user_already_onboarded",
    account_already_onboarded = "account_already_onboarded",
}

export enum DiscoverPanelStatus {
    IDLE = 0,
    ACTION_SENT,
    RESPONSE_PENDING,
    RESPONDING,
}

export enum ActionLaunchStatus {
    LAUNCHED = 0,
    BETA,
    SUPERADMIN,
}

export enum FindingGroupLaunchStatus {
    deprecated = "deprecated",
    launched = "launched",
    beta = "beta",
    internal = "internal",
}

export enum UploadResult {
    ok = "ok",
    found = "found",
    too_large = "too_large",
    invalid_format = "invalid_format",
}

export type FileUploadResult = {
    status: UploadResult;
    document_info: DocumentInfo | null;
};

export enum FindingGroupType {
    adversarial_questions = "adversarial_questions",
    compliance_events = "compliance_events",
    executive_summary = "executive_summary",
    followup_instructions = "followup_instructions",
    governance_changes = "governance_changes",
    insights = "insights",
    key_insights_and_stats = "key_insights_and_stats",
    litigation = "litigation",
    merger_acquisition = "merger_acquisition",
    opportunities = "opportunities",
    risks = "risks",
    stats = "stats",
    user_defined_text = "user_defined_text",
}

enum FindingGroupScope {
    system = "system",
    user = "user",
}

export enum FindingGroupStatus {
    processing = "processing",
    completed = "completed",
    failed = "failed",
    cancelled = "cancelled",
}

export enum DocumentType {
    website = "website",
    pdf = "pdf",
    text = "text",
    spreadsheet = "spreadsheet",
    sec_filing = "sec_filing",
    earnings_transcript = "earnings_transcript",
    quartr_transcript = "quartr_transcript",
}

export enum DocumentStatus {
    initial = "initial",
    processing = "processing",
    processing_completed = "processing_completed",
    post_processing = "post_processing",
    ready = "ready",
    failed = "failed",
    skipped = "skipped",
}

export enum TimeframeType {
    fiscal = "fiscal",
    calendar = "calendar",
}

export enum ProbeType {
    boolean = "boolean",
    finding_list = "finding_list",
    list = "list",
    markdown = "markdown",
    number = "number",
    short_text = "short_text",
    strict_boolean = "strict_boolean",
    text = "text",
}

/* ********* */

export interface Entity {
    id: UUID;
}

export interface DocumentInfoBase<T = DocumentType> extends Entity {
    title: string;
    doc_type: T;
    status: DocumentStatus;
    source: string;
    content_date: string | null;
}
export interface DocumentInfoBaseWithExternalRef<T = DocumentType>
    extends DocumentInfoBase<T> {
    external_ref: string | null;
}

export type GenericDocumentInfo =
    | DocumentInfoBaseWithExternalRef<DocumentType.website>
    | DocumentInfoBaseWithExternalRef<DocumentType.pdf>
    | DocumentInfoBaseWithExternalRef<DocumentType.text>
    | DocumentInfoBaseWithExternalRef<DocumentType.spreadsheet>;

export interface SECFilingDocumentInfo
    extends DocumentInfoBase<DocumentType.sec_filing> {
    unique_id: string;
    accession_number: string;
    cik: number;
    ticker: string;
    form_type: FilingType;
    sub_form_type: string | null;
    filed_at: string;
    url: string;
}

export interface EarningsTranscriptDocumentInfo<
    DT = DocumentType.earnings_transcript,
> extends DocumentInfoBase<DT> {
    unique_id: string;
    ticker: string;
    quarter: number;
    year: number;
    filed_at: string;
}

export interface QuartrTranscriptDocumentInfo
    extends EarningsTranscriptDocumentInfo<DocumentType.quartr_transcript> {
    external_ref: string | null;
}

export type DocumentInfo =
    | GenericDocumentInfo
    | SECFilingDocumentInfo
    | EarningsTranscriptDocumentInfo
    | QuartrTranscriptDocumentInfo;

export interface OnboardableEntity extends Entity {
    created_at: string;
    deactivated_at: string | null;
    onboarded_at: string | null;
}

export interface Account extends OnboardableEntity {
    id: UUID;
    paid: boolean;
    account_type: AccountType;
    name: string | null;
}

export interface User extends OnboardableEntity {
    account_id: UUID;
    account_deactivated_at: string | null;
    account_type: AccountType;
    account_name: string | null;
    beta_access: boolean;
    user_type: UserType;
    username: string;
    first_name: string | null;
    last_name: string | null;
    paid: boolean;
    status: CustomerStatus;
}

export interface ThreadConfig {
    model_name: string;
    max_samples: number; // 0 - 100
    num_search_queries: number; // 0 - 20
    documents_per_query: number; // 0 - 100
    generation_seed: number; // 0 - 1,000,000;
}

export interface Attribution {
    analysis_finding_id: UUID;
    analysis_id: UUID;
    document_info: DocumentInfo;
}

export interface PageInfo {
    has_next_page: boolean;
    end_cursor: string | null;
}

export interface CursorPage<T> {
    items: T[];
    count: number;
    page_info: PageInfo;
}

export interface DefaultPage<T> {
    items: T[];
    page: number;
    pages: number;
    size: number;
    total: number;
}
export type Page<T> = CursorPage<T> | DefaultPage<T>;

export type InviteCode = {
    code: string;
    email: string;
    account_id: UUID;
    user_id: UUID;
    invite_type: "user" | "account";
    created_at: string; // ISO 8601 date string
    expiration: string; // ISO 8601 date string
    canceled_at: string | null; // ISO 8601 date string
    accepted_at: string | null; // ISO 8601 date string
    link: string;
};

export type UserInvite = {
    user_id: UUID;
    account_id: UUID;
    invite_id: UUID;
    codes: InviteCode[];
};

export type Metric = {
    value: number;
    percentile: number;
};

export type DashboardMetric = {
    value: number | null;
    time_series: [string, number][] | null;
};

export type DashboardMetricsV2 = {
    values: [string, number][]; // [metric_name, value][]
    ts_keys: string[]; // metric_name[]
    ts: [string, [string, number][]][]; // [metric_name, [timestamp, value][]][]
};

export type PipelineStepMetrics = {
    complete_ms: number;
    failed_ms: number;
    ms: Metric;
};

export type PipelineMetrics = {
    queue: Metric;
    pipeline: PipelineStepMetrics;
    total: PipelineStepMetrics;
};

export type ReferenceInfoWithCitations = {
    info: DocumentInfo;
    citations?: number[];
};

export interface Citation {
    evidence_id: UUID;
    start: number;
    end: number;
}

export type UploadFileOk = {
    upload_id: UUID;
    status: Extract<
        FileStatus,
        FileStatus.uploaded | FileStatus.processing | FileStatus.ready
    >;
    name: string;
    document_info: DocumentInfo;
};

export type UploadFile =
    | {
          upload_id: UUID;
          status: Extract<
              FileStatus,
              | FileStatus.initial
              | FileStatus.fetching_info
              | FileStatus.uploading
          >;
          name: string;
      }
    | {
          upload_id: UUID;
          status: Extract<FileStatus, FileStatus.failed | FileStatus.rejected>;
          name: string;
          error?: string;
      }
    | UploadFileOk;

export type AccountSettings = {
    seats: {
        free: number;
        paid: number;
        total: number;
    };
    beta: boolean;
};

export type Sorting = {
    sorted_by: string;
    direction: SortingDirection;
};

export interface Price extends Entity {
    product_id: UUID;
    currency: Currency;
    amount: number;
    recurring_interval: ContractInterval;
    recurring_interval_count: number;
}

export interface SubscriptionItem extends Price {
    quantity: number;
}

export interface Subscription extends Entity {
    customer_id: UUID;
    invoice: boolean;
    invoice_days_until_due: number;
    status: string | null;
    trial_days: number | null;
    trial_end: string | null;
    confirmed_at: string | null;
    current_period_start: string | null;
    current_period_end: string | null;
    canceled_at: string | null;
    cancel_at_period_end: boolean;
    items: SubscriptionItem[];
    billing_details_required: boolean;
    payment_info_required: boolean;
    default_payment_method_id: string | null;
}

export type BillingAddress = {
    city: string;
    country: string;
    line1: string;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
};

export type BillingDetails = {
    billing_name: string;
    billing_email: string;
    billing_address: BillingAddress | null;
};

export type CardDetails = {
    id: string;
    brand: string;
    display_brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
    wallet_type: string | null;
};

export type BankDetails = {
    id: string;
    account_type: string;
    bank_name: string;
    last4: string;
};

export type PaymentMethod =
    | { default: boolean; payment_type: "card"; details: CardDetails }
    | {
          default: boolean;
          payment_type: "us_bank_account";
          details: BankDetails;
      };

// https://docs.stripe.com/invoicing/overview#invoice-statuses
export enum InvoiceStatus {
    draft = "draft",
    open = "open",
    paid = "paid",
    uncollectible = "uncollectible",
    void = "void",
}

export type Invoice = {
    id: string;
    number: string;
    hosted_invoice_url: string;
    status: InvoiceStatus;
    total: number;
    created_at: string;
};

export interface Product extends Entity {
    account_type: AccountType;
    name: string;
    default_prices: Price[];
}

export type Company = {
    symbol: string;
    name: string;
    exchange?: Exchange;
};

type CompanyInfo = {
    name: string;
    description: string;
    mkt_cap_billions?: number | null;
};

interface Fingerprinted {
    fingerprint: string;
    fingerprint_version: string;
}

export interface SECFiling extends Fingerprinted {
    unique_id: string;
    accession_number: string;
    cik: number;
    ticker: string;
    form_type: FilingType;
    sub_form_type: string | null;
    filed_at: string;
    url: string;
    company_name: string;
    title: string;
    usable: boolean;
}

export interface EarningsTranscript extends Fingerprinted {
    unique_id: string;
    ticker: string;
    quarter: number;
    year: number;
    filed_at: string;
    title: string;
}

export interface QuartrTranscript extends Fingerprinted {
    unique_id: string;
    ticker: string;
    quarter: number;
    year: number;
    filed_at: string;
    title: string;
    company_id: number;
    event_date: string; // ISO 8601 date string
    event_id: number;
}

export enum ContextType {
    QUARTR_TRANSCRIPT = "quartr_transcript",
    USER_UPLOAD = "user_upload",
    EXTERNAL_SEC_FILING = "external_sec_filing",
    EXTERNAL_EARNINGS_TRANSCRIPT = "external_earnings_transcript",
    EXISTING_DOCUMENT = "existing_document",
}

export interface Encoded<T> {
    encoded: string;
    data: T;
}

type ContextItemBase<Tk extends ContextType, T> = T & { type: Tk };

export type QuartrTranscriptContextItem = ContextItemBase<
    ContextType.QUARTR_TRANSCRIPT,
    QuartrTranscript
>;
export type SECFilingContextItem = ContextItemBase<
    ContextType.EXTERNAL_SEC_FILING,
    SECFiling
>;
export type EarningsTranscriptContextItem = ContextItemBase<
    ContextType.EXTERNAL_EARNINGS_TRANSCRIPT,
    EarningsTranscript
>;
export type UserUploadContextItem = ContextItemBase<
    ContextType.USER_UPLOAD,
    { upload_id: UUID }
>;

export type ExistingDocumentContextItem = ContextItemBase<
    ContextType.EXISTING_DOCUMENT,
    { document_id: UUID }
>;

export interface ExistingDocumentContextItemWithInfo
    extends ExistingDocumentContextItem {
    ticker: string;
    content_date?: string | null;
    title: string;
}

export type EncodedContextItem = {
    type: "encoded_context_item";
    encoded: string;
};

export type APIContextItem =
    | Encoded<QuartrTranscriptContextItem>
    | Encoded<SECFilingContextItem>
    | Encoded<EarningsTranscriptContextItem>;

export type ContextItem =
    | UserUploadContextItem
    | APIContextItem
    | ExistingDocumentContextItem;

export interface APActionEvidence extends Entity {
    score: number;
    document_info: DocumentInfo;
}

export interface APActionResponse extends Entity {
    content: string;
    citations: Citation[];
    searched_for_evidence: boolean;
    evidence_citations?: APActionCitation[];
}

export interface APActionPreview extends Entity {
    type: APActionType;
    content: string;
}

export interface APActionBase extends Entity {
    conversation_id: UUID;
    message_id: UUID;
    type: APActionType;
    role: MessageRole;
    content: string;
    status: APActionStatus;
    status_reason?: APActionStatusReason | null;
    response_to?: UUID | APActionPreview | null;
    created_at: string;
}

export interface APActionCitation extends Entity {
    display_index: number;
    document_id: UUID;
}

export interface APAction extends APActionBase {
    response: APActionResponse;
    evidence: APActionEvidence[];
}

export interface APActionChunk extends APAction {
    index: number;
}

export type APActionQueryAnalysis = {
    title: string;
    intent: string;
    query: string;
    timeframe_analysis: {
        type: TimeframeType;
        start: string;
        end: string;
    };
};

export interface APActionStatusUpdate extends APAction {
    state: Partial<{
        company_search_result: {
            companies: ScoredCompanyResult[];
            query_analysis: APActionQueryAnalysis;
        };
        query_analysis: APActionQueryAnalysis;
    }>;
}

export enum InviteType {
    user = "user",
    account = "account",
}

export interface InviteInfo {
    username: string;
    code: string;
    invite_type: InviteType;
}

export interface ActionConfigBase {
    launchStatus: ActionLaunchStatus;
    label: string;
    description: string | undefined;
}

export interface ActionConfig extends ActionConfigBase {
    type: APActionType;
}

// NEW DEFINITIONS

// todo make dynamic mapping to content
export enum FindingType {
    deprecated = "deprecated",
    executive_summary = "executive_summary",
    executive_summary_key_takeaway = "executive_summary_key_takeaway",
    executive_summary_long = "executive_summary_long",
    executive_summary_short = "executive_summary_short",
    followup_instruction = "followup_instruction",
    followup_question = "followup_question",
    insight = "insight",
    long_text = "long_text",
    titled_list = "titled_list",
    opportunity = "opportunity",
    risk = "risk",
    statistic = "statistic",
    user_defined_text = "user_defined_text",
    unknown = "unknown",
}

export interface FindingBase<C = FindingContentType, F = FindingType>
    extends Entity,
        MaybeLabeled {
    content_type: C;
    finding_type: F;
    valid: boolean;
}

export interface TextFinding extends FindingBase {
    text: string;
}

export interface TitledTextFinding extends TextFinding {
    title: string;
}

export interface TitledListFinding extends FindingBase {
    title: string;
    key_points: string[];
}

interface FullTextFinding extends TitledTextFinding {
    headline: string;
}

interface NumericalFullTextFinding extends FullTextFinding {
    value: string;
    unit: string;
}

export interface BooleanFinding extends FindingBase {
    value: boolean;
    text: string | null;
}

export enum NumericalValueType {
    point = "point",
    change = "change",
}

export enum NumericalUnitType {
    currency = "currency",
    other = "other",
}

export interface NumericalFinding extends FindingBase {
    value: number;
    value_type: NumericalValueType;
    unit: string;
    unit_type: NumericalUnitType;
    text: string;
    exponent: number | null;
}

export enum FindingContentType {
    boolean = "boolean",
    full_text = "full_text",
    long_text = "long_text",
    numerical = "numerical",
    numerical_full_text = "numerical_full_text",
    short_text = "short_text",
    titled_long_text = "titled_long_text",
    titled_list = "titled_list",
    list_item = "list_item",
    unstructured = "unstructured",
}

interface ContentMap {
    [FindingContentType.boolean]: BooleanFinding;
    [FindingContentType.list_item]: TextFinding;
    [FindingContentType.short_text]: TextFinding;
    [FindingContentType.long_text]: TextFinding;
    [FindingContentType.full_text]: FullTextFinding;
    [FindingContentType.numerical_full_text]: NumericalFullTextFinding;
    [FindingContentType.titled_long_text]: TitledTextFinding;
    [FindingContentType.titled_list]: TitledListFinding;
    [FindingContentType.numerical]: NumericalFinding;
    [FindingContentType.unstructured]: FindingBase;
}

export type GenericFindingContent = {
    [K in keyof typeof FindingContentType]: FindingBase<
        (typeof FindingContentType)[K]
    > &
        ContentMap[(typeof FindingContentType)[K]];
}[keyof typeof FindingContentType];

enum FindingLabelCategory {
    compliance = "compliance",
    contracts = "contracts",
    environment = "environment",
    finance = "finance",
    innovation = "innovation",
    legal = "legal",
    operations = "operations",
    security = "security",
    strategy = "strategy",
    technology = "technology",
    unknown = "unknown",
}

export interface MaybeLabeled {
    trend: string | null;
    category: FindingLabelCategory | null;
    tag?: string | null;
}

export interface Probe extends Entity {
    schema_id: UUID;
    message_id: UUID;
    owner_id: UUID;
    name: string;
    prompt: string | null;
    type: ProbeType;
    analysis_type: FindingGroupType;
    launch_status: FindingGroupLaunchStatus;
}

export interface DocumentFindingGroupBase<T extends FindingGroupType>
    extends FindingGroupInfo<T> {
    findings: GenericFindingContent[];
}

export type DocumentFindingGroup = {
    [K in keyof typeof FindingGroupType]: DocumentFindingGroupBase<
        (typeof FindingGroupType)[K]
    >;
}[keyof typeof FindingGroupType];

export interface FindingGroupInfo<Tk = FindingGroupType> extends Entity {
    document_id: UUID;
    type: Tk;
    status: FindingGroupStatus;
    scope: FindingGroupScope;
    probe: Probe;
}

export interface AsyncFindingGroupBase<Tk extends FindingGroupType>
    extends FindingGroupInfo<Tk> {
    findings: AsyncValue<GenericFindingContent[]>;
}

export type AsyncFindingGroup = {
    [K in keyof typeof FindingGroupType]: AsyncFindingGroupBase<
        (typeof FindingGroupType)[K]
    >;
}[keyof typeof FindingGroupType];

export interface WithDocumentInfo {
    info: DocumentInfo;
}

export interface DecoratedDocument extends WithDocumentInfo {
    finding_groups: FindingGroupInfo[];
}

export interface FullDecoratedDocument extends WithDocumentInfo {
    finding_groups: DocumentFindingGroup[];
}

export interface BaseDocumentCollection {
    id: UUID;
    thread_id: UUID;
    title: string | null;
    created_at: string;
}

export interface DocumentCollection extends BaseDocumentCollection {
    documents: WithDocumentInfo[];
}

export enum ColumnType {
    system = "system",
    user_defined = "user_defined",
}

export type Column = {
    column_type: ColumnType;
    details: Probe;
};

export interface FullDocumentCollection extends DocumentCollection {
    documents: DecoratedDocument[];
    columns: Column[];
}

interface BaseScoredResult {
    rank: number;
    score?: number | null;
}

interface ScoredFindingResult extends BaseScoredResult {
    document_id: UUID;
    doc_type: DocumentType;
    title: string;
    ticker: string;
    finding_type: FindingType;
    finding_text: string;
}

interface AggregatedSearchResultItem extends BaseScoredResult {
    normalized_score: number;
    findings: ScoredFindingResult[];
}

export interface ScoredDocumentResult extends AggregatedSearchResultItem {
    document_id: UUID;
    doc_type: DocumentType;
    content_date?: string | null;
    title: string;
    ticker: string;
    relevance_score: number;
}

export interface ScoredCompanyResult extends AggregatedSearchResultItem {
    ticker: string;
    info: CompanyInfo;
    relevance_score: number;
    documents: ScoredDocumentResult[];
    timeframe_analysis: TimeframeAnalysisResult | null;
}

interface FiscalYearEndInfo {
    month: number;
    day: number;
    is_lagging: boolean;
}

interface TickerTimeframeInfo {
    start: string;
    end: string;
    fiscal_year_end: FiscalYearEndInfo;
}

export interface TimeframeAnalysisResult {
    ticker_timeframe_info: TickerTimeframeInfo;
    timeframes_align: boolean;
    start: string;
    end: string;
    type: TimeframeType;
}

interface QueryAnalysisResponse {
    title: string;
    intent: string;
}

export interface CompanySearchResult {
    query: string;
    companies: ScoredCompanyResult[];
}

export interface DocumentSearchResult {
    query: string;
    query_analysis: QueryAnalysisResponse;
    companies: ScoredCompanyResult[];
}

export type VersionDetails = {
    deployed_sha: string | null;
    deployed_at: string | null;
    server_sha: string | null;
    built_at: string | null;
};
