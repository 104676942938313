import { AgnosticRouteMatch } from "node_modules/@sentry/react/build/types/types";
import { ReactNode } from "react";
import { UIMatch } from "react-router-dom";

export const matchHasTitle = <Data = unknown, Handle = unknown>(
    m: UIMatch<Data, Handle>,
): m is UIMatch<
    Data,
    Handle & {
        title: ((params: AgnosticRouteMatch["params"]) => ReactNode) | string;
    }
> =>
    typeof m.handle === "object" &&
    m.handle !== null &&
    "title" in m.handle &&
    (typeof m.handle.title === "function" ||
        typeof m.handle.title === "string");

export const getMatchTitle = <Data = unknown, Handle = unknown>(
    m: UIMatch<
        Data,
        Handle & {
            title:
                | ((params: AgnosticRouteMatch["params"]) => ReactNode)
                | string;
        }
    >,
): ReactNode =>
    typeof m.handle.title === "function"
        ? m.handle.title(m.params)
        : m.handle.title;
