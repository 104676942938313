import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { ComponentPropsWithRef, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

const headlineVariants = cva("font-headline font-bold text-balance", {
    variants: {
        level: {
            1: "text-7xl @container @5xl:text-9xl",
            2: "text-5xl",
            3: "text-2xl",
            4: "uppercase",
            5: "",
        },
        separator: {
            true: "border-t border-foreground pt-2 ",
            false: "",
        },
    },
    defaultVariants: {
        level: 1,
        separator: false,
    },
});

export interface Props
    extends HTMLAttributes<HTMLParagraphElement>,
        ComponentPropsWithRef<"h1" | "h2" | "h3" | "h4" | "h5" | "h6">,
        VariantProps<typeof headlineVariants> {
    highlighted?: boolean;
    asChild?: boolean;
}

export const Headline = ({
    ref,
    className,
    level,
    separator,
    highlighted = false,
    asChild = false,
    children,
    ...props
}: Props) => {
    const Comp = asChild ? Slot : "p";
    return (
        <Comp
            className={cn(headlineVariants({ level, separator, className }))}
            ref={ref}
            {...props}
        >
            {highlighted ? (
                <span className="to-brightwave/50 dark:to-brightwave-800 bg-linear-to-b from-transparent from-65% to-65% group-hover:bg-linear-to-b">
                    {children}
                </span>
            ) : (
                children
            )}
        </Comp>
    );
};
