import { X } from "lucide-react";
import { toast } from "sonner";

import { ProbeBase } from "@/api/rest";
import { Probe } from "@/api/types";
import { ColumnForm } from "@/components/document-table/column-form";
import { Button } from "@/components/ui/button";
import {
    SheetClose,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";

type Props = {
    probe: Probe;
    onSuccess?: () => void;
};

export const EditColumn = (props: Props) => {
    const updateColumn = useGridView((s) => s.updateColumn);
    const updateAction = useAsyncState(
        async (data: ProbeBase) => await updateColumn(props.probe.id, data),
        {
            onSuccess: () => {
                toast.success("Analysis updated.");
                props.onSuccess?.();
            },
            onError: () => toast.error("Something went wrong"),
        },
    );
    return (
        <>
            <SheetHeader className="min-h-12 flex-row items-center justify-between space-y-0 border-b px-2">
                <SheetTitle className="px-2">Edit Analysis</SheetTitle>
                <SheetDescription className="sr-only">
                    Update the the analysis definition
                </SheetDescription>
                <SheetClose asChild>
                    <Button variant="ghost" size="icon-sm">
                        <X className="size-4" />
                    </Button>
                </SheetClose>
            </SheetHeader>
            <ColumnForm probe={props.probe} action={updateAction} isUpdate />
        </>
    );
};
