import { Reply } from "lucide-react";
import { MouseEvent, PropsWithChildren, useRef, useState } from "react";

import { APActionType } from "@/api/types";
import { Button } from "@/components/ui/button";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useBoolean } from "@/hooks/use-boolean";
import { cn } from "@/lib/utils";
import { getLineHeight } from "@/utils/css";

const coerceActionType = (s: string): APActionType | null => {
    if (s in APActionType) {
        return APActionType[s as keyof typeof APActionType];
    }
    return null;
};

type Props = {
    node: unknown; // mdast node
    type: string;
    message?: string;
    selected_text?: string;
    response_to?: string;
};

export const ActionDirective = ({
    children,
    node,
    ...props
}: PropsWithChildren<Props>) => {
    const [hover, hoverActions] = useBoolean(false);
    const [lineClamp, setLineClamp] = useState<string>("line-clamp-none");
    const actionType = coerceActionType(props.type);
    const sendAction = useActionsContext((s) => s.send);
    const onSend = (e: MouseEvent) => {
        e.preventDefault();
        if (actionType != null) {
            sendAction(actionType, props);
        }
    };

    const resizeObserver = useRef(
        new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
            if (!entry || hover) {
                return;
            }
            const lines = Math.ceil(
                entry.contentRect.height / getLineHeight(entry.target),
            );
            switch (lines) {
                case 1:
                    return setLineClamp("line-clamp-1");
                case 2:
                    return setLineClamp("line-clamp-2");
                case 3:
                    return setLineClamp("line-clamp-3");
                case 4:
                    return setLineClamp("line-clamp-4");
                case 5:
                    return setLineClamp("line-clamp-5");
                case 6:
                    return setLineClamp("line-clamp-6");
                case 7:
                    return setLineClamp("line-clamp-7");
                case 8:
                    return setLineClamp("line-clamp-8");
                case 9:
                    return setLineClamp("line-clamp-9");
                default:
                    return setLineClamp("line-clamp-10");
            }
        }),
    );

    if (!actionType) return null;

    return (
        <a
            ref={(ref) => {
                if (ref) {
                    resizeObserver.current.observe(ref);
                    return () => resizeObserver.current.unobserve(ref);
                }
            }}
            href="#"
            onClick={onSend}
            className="bg-muted font-headline flex items-center gap-2 rounded p-2 no-underline"
            onMouseOver={hoverActions.open}
            onMouseOut={hoverActions.close}
        >
            <span className={cn(hover ? lineClamp : "line-clamp-none")}>
                {children}
            </span>
            <Button size="sm" asChild className={cn(!hover && "hidden")}>
                <span>
                    Ask question
                    <Reply className="ml-1.5 size-4" />
                </span>
            </Button>
        </a>
    );
};
