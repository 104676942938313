import { useParams } from "react-router-dom";
import { validate as validateUUID } from "uuid";

import { UUID } from "@/api/types";
import { ActionPanel } from "@/components/action-panel/action-panel";
import { ActionsContextProvider } from "@/context/actions-context-provider";
import { ContextContextProvider } from "@/context/context-context-provider";
import { AppLayout } from "@/layouts/app-layout";
import { NotFoundRoute } from "@/routes/not-found";

export const ExploreRoute = () => {
    const { id } = useParams<{ id: UUID }>();

    if (!id || !validateUUID(id)) {
        return <NotFoundRoute />;
    }

    if (!id) return <NotFoundRoute />;

    return (
        <AppLayout className="bg-muted flex h-dvh flex-col">
            <ContextContextProvider key={id} messageID={id}>
                <ActionsContextProvider messageID={id} actionPanelOpen>
                    <ActionPanel className="flex h-full w-1/2 max-w-(--breakpoint-md) min-w-[500px] flex-1 grow flex-col self-center" />
                </ActionsContextProvider>
            </ContextContextProvider>
        </AppLayout>
    );
};
