import { X } from "lucide-react";
import { toast } from "sonner";

import { ProbeBase } from "@/api/rest";
import { ColumnForm } from "@/components/document-table/column-form";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
    SheetClose,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { wrapPreventDefault } from "@/utils/dom";

type Props = {
    onSuccess?: () => void;
    onCancel?: () => void;
};

export const CreateColumn = (props: Props) => {
    const createColumn = useGridView((s) => s.createColumn);
    const createAction = useAsyncState(
        async (probe: ProbeBase) => await createColumn(probe),
        {
            onSuccess: () => {
                toast.success("Analysis created and added.");
                props.onSuccess?.();
            },
            onError: () => toast.error("Something went wrong"),
        },
    );
    return (
        <>
            <SheetHeader className="min-h-12 flex-row items-center justify-between space-y-0 border-b px-2">
                <SheetTitle className="sr-only">Create Analysis</SheetTitle>
                <SheetDescription className="sr-only">
                    Create a custom analysis
                </SheetDescription>
                <Breadcrumb className="px-2">
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href="#"
                                onClick={wrapPreventDefault(props.onCancel)}
                            >
                                Add Analysis
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem className="text-foreground font-medium">
                            New Analysis
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                <SheetClose asChild>
                    <Button variant="ghost" size="icon-sm">
                        <X className="size-4" />
                    </Button>
                </SheetClose>
            </SheetHeader>
            <ColumnForm action={createAction} />
        </>
    );
};
