import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { parseISO } from "date-fns";
import { Info, Plus } from "lucide-react";
import { useShallow } from "zustand/react/shallow";

import { AsyncFindingGroup, DocumentInfo as TDocumentInfo } from "@/api/types";
import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { CellState } from "@/components/analyze/cell-state";
import { DocumentStatusGate } from "@/components/analyze/document-status-gate";
import { Debugger } from "@/components/debug/debugger";
import { ColumnHeader } from "@/components/document-table/column-header";
import { DocumentInfo } from "@/components/document-table/document-info";
import { FindingGroupContent } from "@/components/document-table/finding-group-content";
import {
    DocumentWithAsyncFindings,
    PowerTable,
} from "@/components/document-table/power-table";
import { Button } from "@/components/ui/button";
import { StaticColumn } from "@/conf/grid-view";
import { useColumnSheet } from "@/hooks/use-column-sheet";
import { useGridView } from "@/hooks/use-grid-view-context";
import { getColumnId, getColumnIdFromFindingGroup } from "@/utils/columns";
import {
    getColumnFilterFn,
    inDateRangeFilterFn,
    stringContainsFilterFn,
    valueInSetFilterFn,
} from "@/utils/filter";
import { maybeGetFindingGroup } from "@/utils/finding-group";
import { nonNull } from "@/utils/fn";
import { plural } from "@/utils/string-helpers";

const columnHelper = createColumnHelper<DocumentWithAsyncFindings>();

const staticColumns = [
    columnHelper.accessor((row) => row.info, {
        id: StaticColumn.document,
        header({ table }) {
            const filteredRowCount =
                table.getFilteredRowModel().flatRows.length;
            const totalCount = table.getRowCount();
            if (filteredRowCount != totalCount) {
                return `${filteredRowCount} / ${plural(totalCount, "Document")}`;
            }
            return plural(totalCount, "Document");
        },
        cell({ getValue, row }) {
            return (
                <DocumentInfo
                    info={getValue()}
                    className="relative h-full p-4"
                    onClick={row.getToggleSelectedHandler()}
                />
            );
        },
        enableColumnFilter: false,
        size: 300,
        enableResizing: false,
        meta: {
            removePadding: true,
        },
    }),
    columnHelper.accessor(
        (row) =>
            row.info.content_date ? parseISO(row.info.content_date) : undefined,
        {
            id: StaticColumn.content_date,
            filterFn: inDateRangeFilterFn,
        },
    ),
    columnHelper.accessor(
        (row) => ("ticker" in row.info ? row.info.ticker : ""),
        {
            id: StaticColumn.ticker,
            filterFn: valueInSetFilterFn,
        },
    ),
    columnHelper.accessor("info.doc_type", {
        id: StaticColumn.document_type,
        filterFn: valueInSetFilterFn,
    }),
    columnHelper.accessor("info.status", {
        id: StaticColumn.document_status,
        header: "Status",
        cell: ({ getValue }) => (
            <DocumentStatusGate status={getValue()}>
                <CellState>Document Ready</CellState>
            </DocumentStatusGate>
        ),
        enableResizing: false,
    }),
    columnHelper.accessor((row) => row.info.title, {
        id: StaticColumn.document_title,
        filterFn: stringContainsFilterFn,
    }),
];

export const GridView = ({
    disableRowSelection = false,
}: {
    disableRowSelection?: boolean;
}) => {
    const deleteItems = useGridView((s) => s.deleteItems);
    const columnSizing = useGridView(useShallow((s) => s.columnSizing));
    const setColumnSizing = useGridView(useShallow((s) => s.setColumnSizing));
    const columnOrder = useGridView(useShallow((s) => s.columnOrder));
    const setColumnOrder = useGridView(useShallow((s) => s.setColumnOrder));
    const columnVisibility = useGridView(useShallow((s) => s.columnVisibility));
    const sorting = useGridView(useShallow((s) => s.sorting));
    const setSorting = useGridView((s) => s.setSorting);
    const columnFilters = useGridView(useShallow((s) => s.columnFilters));
    const setColumnFilters = useGridView((s) => s.setColumnFilters);
    const columns = useGridView((s) => s.columns);

    const addColumn = useColumnSheet((s) => s.open);

    const tableCols = [
        ...staticColumns,
        ...columns.map((col) =>
            columnHelper.accessor(
                (row): [TDocumentInfo, AsyncFindingGroup | undefined] => [
                    row.info,
                    row.finding_groups.get(getColumnId(col)),
                ],
                {
                    id: getColumnId(col),
                    header: () => <ColumnHeader column={col} />,
                    cell: ({ getValue }) => {
                        const [info, findingGroup] = getValue();
                        return (
                            <DocumentStatusGate status={info.status}>
                                <Debugger>
                                    <CopyToClipboard
                                        value={JSON.stringify(
                                            maybeGetFindingGroup(findingGroup),
                                            null,
                                            2,
                                        )}
                                        asChild
                                    >
                                        <Button
                                            size="xs"
                                            variant="secondary"
                                            className="absolute top-1 right-1 z-10"
                                        >
                                            <Info className="size-4" />
                                        </Button>
                                    </CopyToClipboard>
                                </Debugger>
                                {findingGroup !== undefined ? (
                                    <FindingGroupContent
                                        documentInfo={info}
                                        findingGroup={findingGroup}
                                    />
                                ) : (
                                    <CellState variant="loading">
                                        Analyzing...
                                    </CellState>
                                )}
                            </DocumentStatusGate>
                        );
                    },
                    size: 400,
                    filterFn: getColumnFilterFn(col),
                },
            ),
        ),
        columnHelper.display({
            id: StaticColumn.add_column,
            header: () => (
                <Button
                    variant="primary"
                    className="text-foreground w-full gap-2"
                    onClick={addColumn}
                >
                    <Plus className="size-4" />
                    Add Analysis
                </Button>
            ),
            cell: () => <div className="bg-page-background h-full w-full" />,
            enableResizing: false,
            size: 200,
            meta: {
                removePadding: true,
                disableOrdering: true,
            },
        }),
    ] as ColumnDef<DocumentWithAsyncFindings>[];

    const docList = useGridView(
        useShallow((s) => Array.from(s.documents.values())),
    );
    const mapping = useGridView((s) => s.documents_to_finding_groups);
    const finding_groups = useGridView((s) => s.finding_groups);
    const rows = docList.map(
        (info): DocumentWithAsyncFindings => ({
            info,
            finding_groups: new Map(
                (mapping.get(info.id) ?? [])
                    .map((id) => finding_groups.get(id))
                    .filter(nonNull)
                    .map((fg): [string, AsyncFindingGroup] | null => {
                        const key = getColumnIdFromFindingGroup(fg);
                        return key ? [key, fg] : null;
                    })
                    .filter(nonNull),
            ),
        }),
    );

    return (
        <PowerTable
            data={rows}
            columns={tableCols}
            onDeleteRow={deleteItems}
            columnPinning={{ left: [StaticColumn.document] }}
            columnVisibility={{
                ...columnVisibility,
                document_status: rows.length > 0 && columns.length === 0,
            }}
            initialColumnSizing={columnSizing}
            onColumnSizingChange={setColumnSizing}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            sorting={sorting}
            setSorting={setSorting}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            disableRowSelection={disableRowSelection}
        >
            <p className="text-muted-foreground grow text-center text-sm italic">
                No documents.
            </p>
        </PowerTable>
    );
};
