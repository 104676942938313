import { HTMLAttributes, PropsWithChildren, RefCallback } from "react";

import { cn } from "@/lib/utils";

const getStorageKey = (key: string): string => `scroll_pos::${key}`;
const getInitialScrollPosition = (key: string): number => {
    try {
        return Number(sessionStorage.getItem(getStorageKey(key)));
    } catch (_) {
        return 0;
    }
};

interface Props extends HTMLAttributes<HTMLDivElement> {
    storageKey: string;
}

export const RestoreScrollPosition = ({
    storageKey,
    className,
    ...props
}: PropsWithChildren<Props>) => {
    const scrollPos = getInitialScrollPosition(storageKey);
    const handleRef: RefCallback<HTMLDivElement> = (ref) => {
        if (ref) {
            if (scrollPos > 0) {
                ref.scrollTo({ top: scrollPos });
            }
            return () =>
                sessionStorage.setItem(
                    getStorageKey(storageKey),
                    ref.scrollTop.toString(),
                );
        }
    };
    return (
        <div
            ref={handleRef}
            className={cn("overflow-y-scroll", className)}
            {...props}
        />
    );
};
