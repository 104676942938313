/* eslint react-compiler/react-compiler: 0 */
// react compiler currently does not like setting a ref `ref.current = el;`
import { zodResolver } from "@hookform/resolvers/zod";
import { AlertTriangle, SendHorizonal } from "lucide-react";
import {
    ClipboardEvent,
    ComponentPropsWithRef,
    KeyboardEvent,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { TextareaAutoSize } from "@/components/ui/textarea";
import { useBoolean } from "@/hooks/use-boolean";
import { useForwardRef } from "@/hooks/use-forward-ref";
import { cn } from "@/lib/utils";
import { getWordCount, isEmptyOrNull } from "@/utils/string-helpers";

const WARNING_WORD_COUNT = 120;

const formSchema = z.object({
    message: z.string().trim().min(1),
});

interface Props extends ComponentPropsWithRef<"textarea"> {
    placeholder?: string;
    disabled?: boolean;
    onMessage: (message: string) => void;
    autoFocus?: boolean;
    leftContent?: ReactNode;
}

export const MessageForm = ({
    ref: forwardedRef,
    disabled,
    placeholder,
    onMessage,
    autoFocus = false,
    leftContent,
    className,
}: Props) => {
    const ref = useForwardRef<HTMLTextAreaElement>(forwardedRef);
    const [showWarning, setShowWarning] = useState(false);
    const [isFocused, focusActions] = useBoolean();
    const shouldFocus = useRef<boolean>(false);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: { message: "" },
    });

    const submitForm = form.handleSubmit(
        (values: z.infer<typeof formSchema>) => {
            shouldFocus.current = true;
            onMessage(values.message);
            form.reset();
        },
    );

    useEffect(() => {
        if (!disabled && shouldFocus.current === true) {
            shouldFocus.current = false;
            ref.current?.focus();
        }
    }, [disabled]);

    const message = form.watch("message");
    useEffect(() => {
        setShowWarning(getWordCount(message) > WARNING_WORD_COUNT);
    }, [message]);

    const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData("Text").trim();
        const input = e.currentTarget;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const currentValue = input.value;
        form.setValue(
            "message",
            currentValue.slice(0, start) + pastedText + currentValue.slice(end),
        );
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (!e.shiftKey && e.key === "Enter") {
            e.preventDefault();
            submitForm();
        }
    };
    const is_iPad = /iPad/.test(navigator.userAgent);

    return (
        <>
            <Form {...form}>
                <form onSubmit={submitForm}>
                    <FormField
                        control={form.control}
                        name="message"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <div
                                        className={cn(
                                            "border-input bg-background ring-page-background focus-within:ring-ring relative m-4 rounded-[1.75rem] border px-4 py-1.5 pr-14 ring-4 ring-offset-0 focus-within:ring-2 focus-within:ring-offset-2",
                                            showWarning &&
                                                "ring-2 ring-amber-500 ring-offset-2 focus-within:ring-amber-500",
                                            leftContent && "pl-14",
                                            className,
                                        )}
                                    >
                                        {showWarning && (
                                            <p className="flex items-center gap-1.5 pb-2 text-sm font-medium text-amber-500">
                                                <AlertTriangle className="size-4 -translate-y-px" />
                                                Chat works best when you break
                                                tasks down into smaller pieces.
                                            </p>
                                        )}
                                        {leftContent && (
                                            <div className="absolute bottom-1.5 left-1.5">
                                                {leftContent}
                                            </div>
                                        )}
                                        <TextareaAutoSize
                                            autoFocus={autoFocus}
                                            placeholder={
                                                placeholder ??
                                                "Deepen your understanding of anything covered by this report..."
                                            }
                                            className="min-h-10 resize-none overflow-hidden rounded-none border-none pr-1 pl-0 text-base focus-visible:ring-0 focus-visible:ring-offset-0"
                                            minRows={1}
                                            maxRows={5}
                                            onFocus={focusActions.open}
                                            {...field}
                                            onKeyDown={handleKeyDown}
                                            onBlur={() => {
                                                field.onBlur();
                                                focusActions.close();
                                            }}
                                            onPaste={handlePaste}
                                            ref={(el) => {
                                                ref.current = el;
                                                field.ref(el);
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            size="icon"
                                            variant={
                                                disabled ||
                                                isEmptyOrNull(field.value)
                                                    ? "secondary"
                                                    : "primary"
                                            }
                                            disabled={
                                                disabled ||
                                                isEmptyOrNull(field.value)
                                            }
                                            className="absolute right-1.5 bottom-1.5 rounded-full"
                                        >
                                            <SendHorizonal className="size-5" />
                                            <span className="sr-only">
                                                Send
                                            </span>
                                        </Button>
                                    </div>
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
            {is_iPad && isFocused && <div className="shrink-0 basis-16" />}
        </>
    );
};
