import { useReducer } from "react";
import useSWRImmutable from "swr/immutable";

import { UUID } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { DebugID } from "@/components/debug/debug-id";
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { getNuxLabel } from "@/conf/nux";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";
import { formatDateTime } from "@/utils/time";

type Props = {
    id: UUID;
    seen_at: string;
    onDelete: (id: UUID) => void;
};
const NuxRow = ({ id, seen_at, onDelete }: Props) => {
    const api = useApi();
    const action = useAsyncState(async () => await api.reset_nux(id), {
        onSuccess: () => onDelete(id),
    });
    return (
        <TableRow>
            <TableCell>
                <p>{getNuxLabel(id)}</p>
                <DebugID label="NUX ID" id={id} forceShow />
            </TableCell>
            <TableCell>{formatDateTime(seen_at)}</TableCell>
            <TableCell align="right">
                <AsyncButton variant="outline" action={action}>
                    Reset
                </AsyncButton>
            </TableCell>
        </TableRow>
    );
};

export const NuxSettings = () => {
    usePageTitle(["NUX", "Settings"]);

    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const api = useApi();
    const { data: nux_state = new Map(), mutate: mutate_nux } = useSWRImmutable(
        "nux_state",
        async () => await api.fetch_nux_state(),
        { onSuccess: forceUpdate },
    );

    const resetAll = useAsyncState(async () => await api.reset_nux(null), {
        onSuccess: () => mutate_nux(new Map()),
    });

    const onDelete = (id: UUID) => {
        mutate_nux((prev) => {
            const next = new Map(prev);
            next.delete(id);
            return next;
        });
    };

    return (
        <div className="col-span-2 space-y-8 lg:col-span-3">
            <h1 className="text-headline text-2xl font-medium">
                NUX
                <span className="block text-sm font-normal text-gray-500">
                    New User Experience
                </span>
            </h1>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>NUX Name</TableHead>
                        <TableHead>Seen At</TableHead>
                        <TableHead></TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {Array.from(nux_state.entries()).map(([id, seen_at]) => (
                        <NuxRow
                            key={id}
                            id={id}
                            seen_at={seen_at}
                            onDelete={onDelete}
                        />
                    ))}
                    {nux_state.size === 0 && (
                        <TableRow>
                            <TableCell colSpan={3} className="text-sm italic">
                                No NUX exposures
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {nux_state.size > 0 && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3} className="py-2 text-right">
                                <AsyncButton
                                    variant="destructive"
                                    action={resetAll}
                                >
                                    Reset all
                                </AsyncButton>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
};
