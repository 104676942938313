import { Check } from "lucide-react";
import { toast } from "sonner";

import { AsyncButton } from "@/components/async-button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";

export const PasswordSettings = () => {
    usePageTitle(["Password", "Settings"]);
    const api = useApi();

    const updatePasswordAction = useAsyncState(
        async () => await api.update_password(),
        { onSuccess: () => toast.success("Password change requested.") },
    );

    return (
        <div className="col-span-2 space-y-8">
            <h1 className="text-headline text-2xl font-medium">
                Change Password
            </h1>
            {updatePasswordAction.data === true && (
                <Alert variant="success">
                    <Check className="h-4 w-4" />
                    <AlertTitle>Success</AlertTitle>
                    <AlertDescription>
                        Password change initiated. Please check your email.
                    </AlertDescription>
                </Alert>
            )}
            {updatePasswordAction.data !== true && (
                <AsyncButton action={updatePasswordAction}>
                    Update Password
                </AsyncButton>
            )}
        </div>
    );
};
