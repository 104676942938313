import { Files, FileText, Plus, Table2 } from "lucide-react";
import { Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import { validate as validUUID } from "uuid";

import {
    DocumentInfo,
    DocumentType,
    EarningsTranscriptDocumentInfo,
    SECFilingDocumentInfo,
    UUID,
} from "@/api/types";
import { CompanyLogo } from "@/components/company-logo";
import { Headline } from "@/components/headline";
import { HoverTooltip } from "@/components/hover-tooltip";
import { NotFound } from "@/components/not-found";
import { PageTitle } from "@/components/page-title";
import { Button } from "@/components/ui/button";
import { ProjectContainer } from "@/container/project";
import { useProject } from "@/hooks/use-project";
import { cn } from "@/lib/utils";
import { arr_partition, unique } from "@/utils/collection";
import { pluralize } from "@/utils/string-helpers";

const ProjectDetails = () => {
    const { project } = useProject();
    const hasDocuments = project.documents.length > 0;

    const ticker = unique(
        project.documents
            .map((d) => d.info)
            .filter(
                (
                    d: DocumentInfo,
                ): d is
                    | SECFilingDocumentInfo
                    | EarningsTranscriptDocumentInfo =>
                    d.doc_type === DocumentType.sec_filing ||
                    d.doc_type === DocumentType.earnings_transcript,
            )
            .map((d) => d.ticker),
    ).sort();

    return (
        <div className="container space-y-10 py-10">
            <div>
                <p className="font-headline text-muted-foreground text-xs font-medium">
                    Project
                </p>
                <PageTitle level={3} highlighted />
            </div>
            <div className="grid grid-cols-3 gap-10">
                <div className="space-y-8 rounded-lg border p-4 shadow-sm">
                    <Headline level={4} className="flex items-center gap-2">
                        <span className="size-3.5 rounded-sm bg-orange-500" />
                        Data Room
                    </Headline>
                    <div className="flex items-end justify-between">
                        <div>
                            <p className="font-headline text-2xl">
                                {project.documents.length}
                            </p>
                            <p className="text-sm">
                                {pluralize(
                                    project.documents.length,
                                    "Document",
                                )}
                            </p>
                        </div>
                        <TickerList ticker={ticker} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Button variant="secondary" className="gap-2" asChild>
                            <Link to={`/v3/project/${project.id}/dataroom`}>
                                <Files className="size-4" />
                                Manage Files
                            </Link>
                        </Button>
                        {hasDocuments && (
                            <Button variant="primary" className="gap-2" asChild>
                                <Link to={`/v3/project/${project.id}/grid`}>
                                    <Table2 className="size-4" />
                                    Grid View
                                </Link>
                            </Button>
                        )}
                    </div>
                </div>
                {hasDocuments && (
                    <Button asChild variant="ghost" className="group">
                        <Link
                            to={`/v3/project/${project.id}/report`}
                            className="flex h-auto flex-col items-center gap-2 space-y-4 rounded-lg border p-4 shadow-sm"
                        >
                            <FileText className="size-5" />
                            Report (beta)
                        </Link>
                    </Button>
                )}
                {hasDocuments && (
                    <Button asChild variant="ghost" className="group">
                        <Link
                            to={`/v3/project/${project.id}/report/new`}
                            className="flex h-auto flex-col items-center gap-2 space-y-4 rounded-lg border p-4 shadow-sm"
                        >
                            <span className="bg-muted group-hover:bg-brightwave rounded-full p-4 transition-colors">
                                <Plus className="size-5" />
                            </span>
                            Create Report
                        </Link>
                    </Button>
                )}
            </div>
        </div>
    );
};

export const V3ProjectRoute = () => {
    const { id } = useParams<{ id: UUID }>();
    if (!id || !validUUID(id)) {
        return <NotFound />;
    }

    return (
        <Suspense fallback="LOADING">
            <ProjectContainer id={id}>
                <ProjectDetails />
            </ProjectContainer>
        </Suspense>
    );
};

const TickerList = ({
    ticker,
    maxPills = 4,
}: {
    ticker: string[];
    maxPills?: number;
}) => {
    const [display, overflow] =
        ticker.length > maxPills
            ? arr_partition(ticker, (_, i) => i < maxPills - 1)
            : [ticker, []];
    if (ticker.length === 0) return null;
    return (
        <div className="flex items-center">
            {display.map((ticker, i) => (
                <HoverTooltip
                    key={ticker}
                    tooltip={<span className="font-bold">{ticker}</span>}
                >
                    <div
                        className={cn(
                            "border-background bg-muted rounded-full border-2 p-2",
                            i > 0 && "-ml-2.5",
                        )}
                        style={{ zIndex: i }}
                    >
                        <CompanyLogo key={ticker} ticker={ticker} />
                    </div>
                </HoverTooltip>
            ))}
            {overflow.length > 0 && (
                <HoverTooltip
                    tooltip={
                        <ul className="space-y-1 py-1.5">
                            {overflow.map((t) => (
                                <li
                                    key={t}
                                    className="flex items-center gap-2 font-bold"
                                >
                                    <CompanyLogo ticker={t} />
                                    <span>{t}</span>
                                </li>
                            ))}
                        </ul>
                    }
                >
                    <p
                        className="border-background bg-muted -ml-2.5 flex size-9 items-center justify-center rounded-full border-2 text-sm font-medium"
                        style={{ zIndex: maxPills }}
                    >
                        +{overflow.length}
                    </p>
                </HoverTooltip>
            )}
        </div>
    );
};
